import React, { useEffect, useState } from 'react'

import {
  faClipboardListCheck,
  faExchange,
  faFileChartLine,
  faUsers
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ProgressBar from '@ramonak/react-progress-bar'
import { Line } from 'react-chartjs-2'
import { connect, useDispatch } from 'react-redux'

import { getHome } from '../../../asyncActions'
import BarGraphControl from '../../../controls/bargraph/bargraph'
import TitleCardControl from '../../../controls/cards/titlecard'
import FolderList from '../../UIComponents/components/lists/folderList'
import { List } from '@mui/material/List'
import FolderListComponent from '../../UIComponents/components/lists/folderList'

const HomeComponent = ({ mainState, userState, programsState }) => {
  const [graphLabels, setGraphLabels] = useState([])
  const [graphData, setGraphData] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [numOfPlatingDays, setNumOfPlatingDays] = useState(0)
  const [init, setInit] = useState(true)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!mainState.homeLoaded) {
      dispatch(getHome())
    }
    getLoanPayments()
  }, [mainState])

  const getLoanPayments = () => {
    let data = []
    let labels = []
    if (mainState.home?.loan_repayments) {
      for (let month in mainState.home?.loan_repayments) {
        data.push(mainState.home?.loan_repayments[month])
        labels.push(month)
      }
      setGraphData(data)
      setGraphLabels(labels)
    }
  }

  const labels = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  const lineChartData = {
    labels: labels,
    datasets: [
      {
        label: 'Recruitments',
        data: [10, 25, 14, 32, 20, 2, 13, 5, 11, 12, 1, 9],
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 2,
        labels: labels,
        fill: false,
        tension: 0.1
      }
    ]
  }
  const options = {
    scales: {
      y: {
        beginAtZero: true
      }
    }
  }

  return (
    <div className="flex flex-col gap-4 p-4 bg-white">
      <div className="flex flex-row">
        <div className="flex-auto text-3xl font-bold">Welcome</div>
      </div>

      <div className="flex flex-col gap-4 h-full">
        <div className="grid grid-cols-2 space-x-2">
          <TitleCardControl backgroundColor="bg-card1" titleColor="text-white">
            <div className="pl-4 text-black font-bold text-24px">
              <span className="text-black text-12px font-normal">Human OI Percentage</span>
              <br />
              <ProgressBar bgColor="#009fde" completed={mainState.home?.human_oi_percentage || 0} />
            </div>
          </TitleCardControl>
          <TitleCardControl backgroundColor="bg-card2" titleColor="text-white">
            <div className="pl-4 text-black font-bold text-24px">
              <span className="text-black text-12px font-normal">Technical OI Percentage</span>
              <br />
              <ProgressBar
                bgColor="#fc5f39"
                completed={mainState.home?.technical_oi_percentage || 0}
              />
            </div>
          </TitleCardControl>
        </div>
        <div className="grid lg:grid-cols-8 md:grid-rows-8 h-full space-x-2">
          <div className="lg:col-span-6 md:row-span-6">
            <TitleCardControl
              style="rounded-2xl shadow-xs border border-homeBorder "
              title="Recruitments"
            >
              <Line data={lineChartData} options={options} />
            </TitleCardControl>
          </div>
          <div className="lg:col-span-2 md:row-span-2 grid grid-rows-4 space-y-2">
            <div className="flex-grow">
              <FolderListComponent
                data={[
                  {
                    primary: 'Active Students',
                    secondary: mainState.home?.active_learners,
                    icon: faUsers,
                    color: '#009fde'
                  },
                  {
                    primary: 'Events',
                    secondary: mainState.home?.events,
                    icon: faExchange,
                    color: '#fc5f39'
                  },
                  {
                    primary: 'Programmes',
                    secondary: mainState.home?.programs,
                    icon: faFileChartLine,
                    color: '#009fde'
                  },
                  {
                    primary: 'New Applications',
                    secondary: mainState.home?.new_applications,
                    icon: faClipboardListCheck,
                    color: '#fc5f39'
                  }
                ]}
              />
            </div>

            {/* Attendance Section */}
            <div className="flex-grow">
              <TitleCardControl
                style="rounded-2xl shadow-xs  border border-homeBorder "
                title={'Student Attendance'}
              >
                <div className="items-center w-full h-full" style={{ paddingLeft: '22%' }}>
                  <BarGraphControl
                    items={[
                      { title: 'Mon', percentage: 0.1 },
                      { title: 'Tue', percentage: 0.2 },
                      { title: 'Wed', percentage: 0.3 },
                      { title: 'Thu', percentage: 1.0 },
                      { title: 'Fri', percentage: 0.8 },
                      { title: 'Sat', percentage: 0.9 },
                      { title: 'Sun', percentage: 0.5 }
                    ]}
                  />
                </div>
              </TitleCardControl>
            </div>

            {/* Volunteer Days and Catch-Up Days */}
            <div className="flex-grow">
              <TitleCardControl style="rounded-2xl shadow-xs  border border-homeBorder ">
                <div className="h-1/2 pt-4">
                  <p className="font-bold text-indigo text-center">Total Volunteer Days</p>
                  <div className="flex mt-2">
                    <div className="flex-grow text-center">
                      <span className="text-indigo font-bold text-2xl">
                        {mainState.home?.volunteer_days}
                      </span>
                    </div>
                    <div className="flex-grow text-center">
                      <span className="text-green font-bold relative top-2">
                        {mainState.home?.volunteer_days_change ?? '--'}%
                      </span>
                    </div>
                  </div>
                </div>
                <div className="h-1/2 pt-2" style={{ borderTop: 'thin solid #efefef' }}>
                  <p className="font-bold text-indigo text-center">Total Catch-Up Days</p>
                  <div className="flex mt-4">
                    <div className="flex-grow text-center">
                      <span className="text-indigo font-bold text-2xl">
                        {mainState.home?.catch_up_days}
                      </span>
                    </div>
                    <div className="flex-grow text-center">
                      <span className="text-green font-bold relative top-2">
                        {mainState.home?.catch_up_days_change ?? '--'}%
                      </span>
                    </div>
                  </div>
                </div>
              </TitleCardControl>
            </div>

            {/* Total Sales and Purchases */}
            <div className="flex-grow">
              <TitleCardControl style="rounded-2xl shadow-xs  border border-homeBorder ">
                <div className="h-1/2 pt-4">
                  <p className="font-bold text-indigo text-center">Total Sales</p>
                  <div className="flex mt-2">
                    <div className="flex-grow text-center">
                      <span className="text-indigo font-bold text-2xl">
                        {mainState.home?.total_sales}
                      </span>
                    </div>
                    <div className="flex-grow text-center">
                      <span className="text-green font-bold relative top-2">
                        {mainState.home?.total_sales_change ?? '--'}%
                      </span>
                    </div>
                  </div>
                </div>
                <div className="h-1/2 pt-2" style={{ borderTop: 'thin solid #efefef' }}>
                  <p className="font-bold text-indigo text-center">Total Purchases</p>
                  <div className="flex mt-4">
                    <div className="flex-grow text-center">
                      <span className="text-indigo font-bold text-2xl">
                        {mainState.home?.total_purchases}
                      </span>
                    </div>
                    <div className="flex-grow text-center">
                      <span className="text-green font-bold relative top-2">
                        {mainState.home?.total_purchases_change ?? '--'}%
                      </span>
                    </div>
                  </div>
                </div>
              </TitleCardControl>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    mainState: state.mainState,
    userState: state.userState,
    programsState: state.programsState
  }
}

export default connect(mapStateToProps)(HomeComponent)
