import React, { useEffect, useMemo, useState } from 'react'

import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import {
  getAllCurriculums,
  getCategories,
  getFacilitators,
  getPrograms,
  getVenues,
  saveCurriculum
} from '../../asyncActions'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import { selectNotificationState } from '../notifications/selectors'
import { curriculumTableColumns, eventsTableColumns, initialCurriculumValues } from './constants'

import UploadCurriculum from './modals/uploadCurriculum'
import { selectCurriculumById, selectCurriculumsLoaded } from './selectors'
import moment from 'moment'
import { DATE_FORMAT } from '../../constants/constants'
import { faDownload, faSave, faTimes, faUpload } from '@fortawesome/pro-light-svg-icons'
import { get_program_curriculums } from '../../api/calls/curriculums/get_program_curriculums'
import DownloadCSV from '../customComponents/downloadCSV'
import TableComponent from '../UIComponents/components/tables/table'
import ToggleComponent from '../UIComponents/components/toggle/toggle'
import ButtonComponentContained from '../UIComponents/components/buttons/buttonContained'
import ButtonComponentOutlined from '../UIComponents/components/buttons/buttonOutlined'

const sanitiseEventsAndPayload = (interactions, categories, venues, program) => {
  console.log('interactions:', interactions)
  console.log('program:', program)
  console.log('categories:', categories)
  console.log('venues:', venues)
  // Implement your sanitization logic here
  const sanitisedEvents = []
  const sanitisedInteractions = []
  let sanitisedPayload = {}
  interactions.forEach(interaction => {
    console.log('interaction', interaction)

    const category = categories?.find(
      e =>
        e?.category_name?.toString().toLowerCase() ===
        interaction?.category?.toString().toLowerCase()
    )

    const venue = venues?.find(
      e => e?.name?.toString().toLowerCase() === interaction?.venue?.toString().toLowerCase()
    )

    sanitisedEvents.push({
      type: category?.category_name?.toString().toUpperCase() ?? 'CUSTOM',
      program_ids: [program._id],
      event_name: interaction?.interaction_name,
      event_description: interaction?.description,
      event_category_id: category?._id,
      venue_id: venue?._id,
      owner_ids:
        program?.facilitator_ids.length > 0
          ? program?.facilitator_ids?.toString().split(',') ?? []
          : [program.facilitator_id],
      start_date: moment(interaction?.start_date).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      end_date: moment(interaction?.end_date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    })

    sanitisedInteractions.push({
      banner: interaction?.banner,
      stream: interaction?.stream,
      num_interactions: interaction?.num_interactions,
      cost: interaction?.cost,
      codes: interaction?.codes,
      interaction_name: interaction?.interaction_name,
      start_date: moment(interaction?.start_date).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      end_date: moment(interaction?.end_date).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      program_id: interaction?.program_id
    })
  })

  sanitisedPayload = { curriculum: { interactions }, events: sanitisedEvents }
  return { sanitisedEvents, sanitisedPayload }
}

const ProgrammeCurriculumComponent = ({
  categoriesState,
  venuesState,
  facilitatorState,
  curriculumState,
  programsState,
  notificationState
}) => {
  const { program_id, curriculum_id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const notificationData = useSelector(selectNotificationState)
  const [showUploadCurriculum, setShowUploadCurriculum] = useState(false)
  const curriculumsLoaded = useSelector(selectCurriculumsLoaded)
  const curriculum = useSelector(selectCurriculumById)(curriculum_id)
  const [formValues, setFormValues] = useState(initialCurriculumValues)
  const [payload, setPayload] = useState({})
  const [events, setEvents] = useState([])
  const [selectedTable, setSelectedTable] = useState('curriculum_summary')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    _fetch()
  }, [])

  useEffect(() => {
    if (!program_id) _onCancelForm()
    if (!curriculumsLoaded) dispatch(getAllCurriculums())
  }, [curriculumsLoaded])

  useEffect(() => {
    if (program_id) _setFormValues({ ...formValues, program_id })
    if (curriculum) _setFormValues({ ...formValues, ...curriculum })
  }, [curriculum])

  const dateFormat = date => {
    return moment(`${date}`, `${DATE_FORMAT}THH:mm:ss.SSSZ`)
      .utcOffset('+02:00')
      .format(`${DATE_FORMAT}THH:mm:ss.SSSZ`)
  }

  const _onCancelForm = () => history.goBack()
  const _onSaveCurriculum = () => {
    dispatch(saveCurriculum(payload))
    setLoading(false)
  }

  const _setFormValues = value => setFormValues({ ...formValues, ...value })

  const _toggleUploadCurriculum = () => {
    setShowUploadCurriculum(!showUploadCurriculum)
  }

  useEffect(() => {
    if (formValues.interactions.length > 0) {
      formValues.interactions.forEach(interaction => {
        interaction.program_id = formValues.program_id
        interaction.start_date = dateFormat(interaction.start_date)
        interaction.end_date = dateFormat(interaction.end_date)
      })
    }
  }, [formValues.interactions, formValues.program_id])

  const [programCurriculums, setProgramCurriculums] = useState([])

  const _fetch = async () => {
    await Promise.all([
      dispatch(getCategories()),
      dispatch(getPrograms()),
      dispatch(getFacilitators()),
      dispatch(getVenues())
    ])

    await get_program_curriculums(program_id).then(res => {
      if (res.data.success) {
        setProgramCurriculums(res.data.content)
      }
    })
  }

  useEffect(() => {
    const { sanitisedEvents, sanitisedPayload } = sanitiseEventsAndPayload(
      formValues.interactions,
      categoriesState.categories,
      venuesState.venues,
      programsState?.programs?.find(e => e._id === program_id)
    )

    console.log('programsState', programsState.programs)

    setEvents(sanitisedEvents)
    setPayload({
      ...sanitisedPayload,
      curriculum: {
        ...payload?.curriculum,
        ...formValues
      }
    })
  }, [
    formValues,
    categoriesState.categories,
    venuesState.venues,
    facilitatorState.facilitators,
    programsState.programs
  ])

  useEffect(() => {
    console.log('events', events)
  }, [events])

  useEffect(() => {
    console.log('payload', payload)
  }, [payload])

  useEffect(() => {
    console.log('formValues', formValues)
  }, [formValues])

  return (
    <TableContentWrapper title={'Programme Curriculum'} notificationData={notificationData}>
      <div className="max-h-screen bg-white p-2">
        <div className="grid grid-cols-8 space-x-4">
          <UploadCurriculum
            csvValues={_setFormValues}
            open={showUploadCurriculum}
            setShow={_toggleUploadCurriculum}
          />
          <div className="col-span-8 flex flex-col gap-2 justify-center">
            <ToggleComponent
              buttons={[
                { label: 'Curriculum Summary', value: 'curriculum_summary' },
                { label: 'Events', value: 'events' }
              ]}
              onClick={v => {
                setSelectedTable(v.value)
              }}
            />
            {selectedTable === 'curriculum_summary' && (
              <TableComponent
                title={`Curriculum (${programCurriculums.length}/4)`}
                showPagination={true}
                data={
                  formValues.interactions.map(e => {
                    return {
                      ...e,
                      start_date: moment(e.start_date).format('LLLL'),
                      end_date: moment(e.end_date).format('LLLL')
                    }
                  }) ?? []
                }
                displayKeys={curriculumTableColumns}
              >
                <div className="flex gap-2">
                  {!curriculum_id ? (
                    <ButtonComponentContained
                      disabled={curriculumState.isDetails === true}
                      title="Upload Curriculum"
                      bgColor="red"
                      loading={false}
                      onClick={_toggleUploadCurriculum}
                      startIcon={faUpload}
                      endIcon={undefined}
                      children={undefined}
                      size="small"
                    />
                  ) : null}
                  <DownloadCSV />
                </div>
              </TableComponent>
            )}
            {selectedTable === 'events' && (
              <TableComponent
                title="Events To Be Created"
                showPagination={true}
                data={events.map(e => {
                  return {
                    ...e,
                    start_date: moment(e.start_date).format('LLLL'),
                    end_date: moment(e.end_date).format('LLLL')
                  }
                })}
                displayKeys={eventsTableColumns}
              />
            )}
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-2 p-2">
        <ButtonComponentOutlined
          bgColor="red"
          title="Cancel"
          startIcon={faTimes}
          onClick={() => history.goBack()}
          endIcon={undefined}
          children={undefined}
        />
        <ButtonComponentContained
          title="Save"
          startIcon={faSave}
          loading={loading}
          onClick={() => {
            setLoading(true)
            _onSaveCurriculum()
          }}
          endIcon={undefined}
          children={undefined}
        />
      </div>
    </TableContentWrapper>
  )
}

const mapStateToProps = state => ({
  categoriesState: state.categoriesState,
  programsState: state.programsState,
  venuesState: state.venuesState,
  facilitatorState: state.facilitatorState,
  curriculumState: state.curriculumState,
  notificationState: state.notificationState
})

export default connect(mapStateToProps)(ProgrammeCurriculumComponent)
