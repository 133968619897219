import * as React from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

export default function ToggleComponent({ buttons = [], onClick = () => {} }) {
  const [alignment, setAlignment] = React.useState(buttons[0].value)

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment)
  }

  return (
    <ToggleButtonGroup
      //   color="primary"
      sx={{
        color: 'black'
      }}
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >
      {buttons.map((button, index) => (
        <ToggleButton
          key={index}
          value={button.value}
          aria-label={button.label}
          onClick={() => onClick(button)}
        >
          {button.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}
