import { Paper } from '@material-ui/core'
import React from 'react'

const Disclaimer = () => {
  return (
    <Paper className="p-4 flex flex-col gap-4">
      <div className="font-sans font-extrabold text-2xl">Disclaimer</div>
      <div className="text-sm text-gray">
        In accordance with the Protection of Personal Information Act (POPI Act) of South Africa, we
        would like to remind you that any personal information you provide us may be subject to
        'processing' as defined by the Act. This includes but is not limited to, your name, address,
        email address, phone number, and other personal details. The term 'processing' encompasses
        the collection, reception, recording, organization, retrieval, usage, distribution, or
        sharing of such information. We are committed to ensuring that your personal information is
        handled in accordance with the highest standards of privacy and security set forth by the
        POPI Act. Your personal information will not be used for purposes beyond those necessary for
        our services and as described in our Privacy Policy without your explicit consent.
      </div>
    </Paper>
  )
}

export default Disclaimer
