import { faUsers, faExchange, faFileChartLine, faFilePdf } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useEffect } from 'react'
import { get_recruitment_summary } from '../../api/calls/get_recruitment_summary'
import TitleCardControl from '../../controls/cards/titlecard'
import { MyTableControl } from '../../controls/myTableControl/myTableControl'
import { useDispatch } from 'react-redux'
import { setMainState } from '../../actions'
import { useHistory } from 'react-router-dom'
import { DoughnutChart } from '../../controls/doughnut_chart'
import CSVDownloaderControl from '../../controls/csv_downloader'
import DropdownButtonComponent from '../customComponents/dropdownButton'
import { get_all_groups } from '../../api/calls'
import TableComponent from '../UIComponents/components/tables/table'
import SingleSelectComponent from '../UIComponents/components/select/singleSelect'
import ButtonComponentContained from '../UIComponents/components/buttons/buttonContained'

const headers = [
  { value: 'user_name', label: 'Name' },
  { value: 'user_surname', label: 'Surname' },
  { value: 'user_cell_number', label: 'Phone Number' },
  { value: 'user_email', label: 'Email Address' },
  {
    value: 'status_code',
    label: 'Status',
    isChip: true,
    expression: [
      { value: 'ACTIVE', color: 'green' },
      { value: 'INACTIVE', color: 'red' }
    ]
  }
]

// const dayTypes

const RecruitmentSummary = () => {
  const [data, setData] = useState({})
  const [selectedUser, setSelectedUser] = useState({})
  const dispatch = useDispatch()
  const history = useHistory()
  const userType = 'LEARNER-ACC'
  const [tableTitle, setTableTitle] = useState('Recruited Learners (All)')
  const [groups, setGroups] = useState([])
  const [selectedGroup, setSelectedGroup] = useState({})

  useEffect(() => {
    onGetRecruitmentSummary('type=total')
    //get groups
    get_all_groups().then(e => {
      if (e.data.success) {
        setGroups(e.data.content)
      }
    })
  }, [])

  const onGetRecruitmentSummary = query => {
    get_recruitment_summary(query).then(res => {
      if (res.data.success) {
        setData(res.data.content)
      }
    })
  }

  return (
    <div className="flex flex-col gap-4 p-4 bg-white">
      <div className="grid grid-cols-3 gap-4">
        <TitleCardControl backgroundColor="bg-card1" titleColor="text-white">
          <div
            className="rounded-md flex lg:grid-cols-2 cursor-pointer"
            onClick={() => {
              setTableTitle('Recruited Learners')
              onGetRecruitmentSummary('type=total')
            }}
          >
            <div className="w-16 p-4 flex bg-cardIcon1 rounded-full justify-center">
              <FontAwesomeIcon
                className="fa-2x text-white ml-8 mr-8 align-baseline"
                icon={faUsers}
              />
            </div>
            <div className="pl-4 text-white font-bold text-24px">
              <span className="text-white text-12px font-normal">Total Recruits</span>
              <br />
              <span className="text-3xl text-white font-medium">{data?.total}</span>
            </div>
          </div>
        </TitleCardControl>

        <TitleCardControl backgroundColor="bg-card2" titleColor="text-white">
          <div
            className=" rounded-md flex lg:grid-cols-2 cursor-pointer"
            onClick={() => {
              setTableTitle('Enrolled Learners')
              onGetRecruitmentSummary('type=enrolled')
            }}
          >
            <div className="w-16 p-4 flex bg-cardIcon2 rounded-full justify-center">
              <FontAwesomeIcon
                className="fa-2x text-white ml-8 mr-8 align-baseline"
                icon={faExchange}
              />
            </div>
            <div className="pl-4 text-white font-bold text-24px">
              <span className="text-white text-12px font-normal">Total Enrolled</span>
              <br />
              <span className="text-3xl text-white font-medium">{data?.enrolled}</span>
            </div>
          </div>
        </TitleCardControl>

        <TitleCardControl backgroundColor="bg-card3" titleColor="text-white">
          <div
            className=" rounded-md flex lg:grid-cols-2 cursor-pointer"
            onClick={() => {
              setTableTitle('Unenrolled Learners')
              onGetRecruitmentSummary('type=unenrolled')
            }}
          >
            <div className="w-16 p-4 flex bg-cardIcon3 rounded-full justify-center">
              <FontAwesomeIcon
                className="fa-2x text-white ml-8 mr-8 align-baseline"
                icon={faFileChartLine}
              />
            </div>
            <div className="pl-4 text-white font-bold text-24px">
              <span className="text-white text-12px font-normal">Total Unenrolled</span>
              <br />
              <span className="text-3xl text-white font-medium">{data?.unenrolled}</span>
            </div>
          </div>
        </TitleCardControl>
      </div>
      <div className="grid grid-cols-6 gap-4">
        <TitleCardControl style="rounded-2xl shadow-x border border-homeBorder">
          <DoughnutChart
            data={data?.open_day}
            title={'OPEN DAY'}
            onClick={params => {
              console.log('Clicked slice:', params)
              setTableTitle(`Learners in Open Day (Status: ${params.name})`)
              onGetRecruitmentSummary(`type=open_day&status=${params.name.toLowerCase()}`)
            }}
          />
        </TitleCardControl>

        <TitleCardControl style="rounded-2xl shadow-xs border border-homeBorder">
          <DoughnutChart
            data={data?.oi_workshop}
            title={'OI WORKSHOP'}
            onClick={params => {
              console.log('Clicked slice:', params)
              setTableTitle(`Learners in OI Workshop (Status: ${params.name})`)
              onGetRecruitmentSummary(`type=oi_workshop&status=${params.name.toLowerCase()}`)
            }}
          />
        </TitleCardControl>

        <TitleCardControl style="rounded-2xl shadow-xs border border-homeBorder">
          <DoughnutChart
            data={data?.interview}
            title={'INTERVIEW'}
            onClick={params => {
              console.log('Clicked slice:', params)
              setTableTitle(`Learners in Interview (Status: ${params.name})`)
              onGetRecruitmentSummary(`type=interview&status=${params.name.toLowerCase()}`)
            }}
          />
        </TitleCardControl>

        <TitleCardControl style="rounded-2xl shadow-xs border border-homeBorder">
          <DoughnutChart
            data={data?.green_light_survey}
            title={'GREEN LIGHT SURVEY'}
            onClick={params => {
              console.log('Clicked slice:', params)
              setTableTitle(`Learners in Green Light Survey (Status: ${params.name})`)
              onGetRecruitmentSummary(`type=green_light_survey&status=${params.name.toLowerCase()}`)
            }}
          />
        </TitleCardControl>

        <TitleCardControl style="rounded-2xl shadow-xs border border-homeBorder">
          <DoughnutChart
            data={data?.shadow_week}
            title={'SHADOW WEEK'}
            onClick={params => {
              console.log('Clicked slice:', params)
              setTableTitle(`Learners in Shadow Week (Status: ${params.name})`)
              onGetRecruitmentSummary(`type=shadow_week&status=${params.name.toLowerCase()}`)
            }}
          />
        </TitleCardControl>

        <TitleCardControl style="rounded-2xl shadow-xs border border-homeBorder">
          <DoughnutChart
            data={data?.tech_interview}
            title={'TECH INTERVIEW'}
            onClick={params => {
              console.log('Clicked slice:', params)
              setTableTitle(`Learners in Tech Interview (Status: ${params.name})`)
              onGetRecruitmentSummary(`type=tech_interview&status=${params.name.toLowerCase()}`)
            }}
          />
        </TitleCardControl>
      </div>

      {
        <TableComponent
          displayKeys={headers}
          data={data?.learners ?? []}
          title={tableTitle ?? 'Recruited Learners (All)'}
          onRowClick={item => {
            setSelectedUser(item)
            dispatch(
              setMainState({
                selectedUser: item
              })
            )
            history.push(`learner-info/${item._id}/${userType}`)
          }}
        >
          <div className="w-80">
            <SingleSelectComponent
              data={groups ?? []}
              displayKey={'name'}
              title={'Filter by Group'}
              errorMessage={'Groups Not Found'}
              onClick={item => {
                setSelectedGroup(item)
                onGetRecruitmentSummary(`type=total&group_id=${item._id}`)
              }}
            />
          </div>

          <CSVDownloaderControl
            headers={[
              { key: 'user_name', label: 'Name' },
              { key: 'user_surname', label: 'Surname' },
              { key: 'user_cell_number', label: 'Phone Number' },
              { key: 'user_email', label: 'Email Address' },
              { key: 'status_code', label: 'Status' }
            ]}
            data={data?.learners ?? []}
            title={tableTitle}
          />
        </TableComponent>
      }
    </div>
  )
}
export default RecruitmentSummary
