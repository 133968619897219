import React, { useEffect } from 'react'
import TextField from '@mui/material/TextField'

export const TextFieldComponent = ({
  label,
  type = 'text',
  value = '',
  onChange,
  required = false,
  disabled = false,
  multiline = false,
  size = 'small',
  helperText
}) => {
  return (
    <TextField
      label={label}
      type={type}
      value={value}
      onChange={e => {
        onChange(e.target.value)
      }}
      required={required}
      disabled={disabled}
      multiline={multiline}
      size={'small'}
      helperText={helperText}
    />
  )
}

export default TextFieldComponent
