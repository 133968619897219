import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import { useEffect, useState } from 'react'
import React from 'react'
import { color } from 'echarts'

const ITEM_HEIGHT = 56
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  }
}

function getStyles(name, personName, theme) {
  return {
    fontWeight: personName.includes(name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular
  }
}

export default function MultipleSelectComponent({
  title,
  data = [],
  onMenuItemClick,
  displayKey,
  color = '#000',
  required = false
}) {
  const theme = useTheme()
  const [labels, setLabels] = useState([])
  const [selectedItems, setSelectedItems] = useState([])

  const handleChange = event => {
    const {
      target: { value }
    } = event
    setLabels(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    )
  }

  const handleItemSelection = item => {
    // Check if the item is already selected
    const isSelected = selectedItems.find(selectedItem => selectedItem._id === item._id)
    if (isSelected) {
      // If the item is already selected, remove it from the selected items
      setSelectedItems(selectedItems.filter(selectedItem => selectedItem._id !== item._id))
    } else {
      // If the item is not selected, add it to the selected items
      setSelectedItems([...selectedItems, item])
    }
  }

  useEffect(() => {
    onMenuItemClick(selectedItems)
  }, [selectedItems])

  return (
    <div className="ignore-click-outside" onClick={e => e.stopPropagation()}>
      <FormControl className="w-full flex">
        <InputLabel
          style={{
            color: color
          }}
          id="demo-multiple-chip-label"
        >
          <div>{`${data.length === 0 ? 'No items found' : title}`}</div>
        </InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          variant="outlined"
          multiple
          required={required}
          value={labels}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          style={{
            height: 56,
            color: color,
            fontSize: 14,
            fontWeight: 400,
            lineHeight: 1.5
          }}
          MenuProps={MenuProps}
        >
          {data.map(item => (
            <MenuItem
              key={item._id}
              value={item[displayKey]}
              style={{
                ...getStyles(item[displayKey], labels, theme),
                padding: '10px 20px', // Add spacing between items
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '4px' // Add gap for better spacing between lines
              }}
              onClick={() => {
                handleItemSelection(item)
              }} // Call onMenuItemClick when a menu item is clicked
            >
              {item[displayKey]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
