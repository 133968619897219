import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { SketchPicker } from 'react-color'
import { useDispatch, useSelector } from 'react-redux'
import { create_program, upload_file } from '../../../api/calls'
import { createProgram, getFacilitators, getPrograms, updateProgram } from '../../../asyncActions'
import { DATE_FORMAT } from '../../../constants/constants'
import LoaderControl from '../../../controls/loader/loader'
import PopupControl from '../../../controls/popup/popup'
import TabControl from '../../../controls/tab/tab'
import { selectFacilitators, selectFacilitatorsLoaded } from '../../facilitators'
import { faSave, faTimes } from '@fortawesome/pro-light-svg-icons'
import { update_program } from '../../../api/calls/programs/update_program'
import { Backdrop, CircularProgress, Paper } from '@material-ui/core'
import FileUploadButton from '../../UIComponents/components/buttons/fileUploadButton'
import DatePickerComponent from '../../UIComponents/components/calendar/date'
import ButtonComponentContained from '../../UIComponents/components/buttons/buttonContained'
import MultipleSelectComponent from '../../UIComponents/components/select/multiSelect'
import TextFieldComponent from '../../UIComponents/components/textfield/textfiled'
import RadioButtonComponent from '../../UIComponents/components/radio/radioButtton'
import ButtonComponentOutlined from '../../UIComponents/components/buttons/buttonOutlined'
import { validateForm } from '../../studentApplication/form/components/constants'
import BackDropComponent from '../../UIComponents/components/form/backdrop'
import { colorPickRemoveBtn, moduleValidationData, validationData } from './constants'

const CreateProgrammeComponent = ({ open = false, setShow, program = {} }) => {
  const dispatch = useDispatch()
  const [pickColor, setPickColor] = useState(false)
  const [formFields, setFormFields] = useState({ modules: [] })
  const [module, setModule] = useState({})
  const [modules, setModules] = useState([])
  const facilitatorsLoaded = useSelector(selectFacilitatorsLoaded)
  const facilitators = useSelector(selectFacilitators).map(_ => ({
    ..._,
    full_name: `${_?.user_name} ${_?.user_surname}`
  }))
  const [canSaveForm, setCanSaveForm] = useState(false)
  const [canSaveModule, setCanSaveModule] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!facilitatorsLoaded) dispatch(getFacilitators())
  }, [facilitatorsLoaded])

  useEffect(() => {
    console.log(program)
    if (program?._id) {
      setFormFields({ ...program })
      if (program?.modules && program?.modules.length > 0) {
        setModules({ ...program.modules })
      }
    } else {
      setFormFields({})
    }
  }, [open])

  const removeModule = e => {
    const index = modules.indexOf(e)
    let list = []
    if (index !== -1) {
      list = modules.splice(index, 1)
    }

    setModule(list)
  }

  //handle form fields change
  const handleFormFieldsChange = v => {
    setFormFields({ ...formFields, ...v })
  }

  useEffect(() => {
    const valid = validateForm(validationData, formFields)
    setCanSaveForm(valid)
  }, [formFields])

  useEffect(() => {
    const valid = validateForm(moduleValidationData, module)
    setCanSaveModule(valid)
  }, [module])

  const handleFacilitatorsChange = selected => {
    setFormFields({
      ...formFields,
      facilitator_ids: selected.map(e => e?._id),
      facilitator_id: selected[0]?._id
    })
  }

  const handleDisabled = v => {
    const found = validationData.find(_ => _.name === v)
    return found?.required ?? false
  }

  return (
    <div>
      <BackDropComponent isOpen={open} onBackDropClose={() => setShow(false)}>
        <TabControl titles={['General', 'Modules']}>
          <div className="w-full flex flex-col gap-2">
            <div className="grid grid-cols-2 gap-2">
              <TextFieldComponent
                label="Name"
                required={handleDisabled('program_name')}
                value={formFields?.program_name}
                onChange={v => {
                  handleFormFieldsChange({ program_name: v })
                }}
                helperText={undefined}
              />
              <TextFieldComponent
                label="Value Exchange Days"
                required={handleDisabled('value_exchange_days')}
                type="number"
                value={formFields?.value_exchange_days}
                onChange={v => {
                  handleFormFieldsChange({ value_exchange_days: v })
                }}
                helperText={undefined}
              />
            </div>

            <TextFieldComponent
              label="Description"
              required={handleDisabled('program_description')}
              multiline={true}
              rows={4}
              value={formFields?.program_description}
              onChange={v =>
                handleFormFieldsChange({
                  program_description: v
                })
              }
              helperText={undefined}
            />

            <MultipleSelectComponent
              title="Facilitator"
              data={facilitators}
              displayKey={'full_name'}
              onMenuItemClick={v => {
                handleFacilitatorsChange(v)
              }}
            />

            <div className="grid grid-cols-3 gap-2">
              <TextFieldComponent
                label="Purchase target per month"
                type="number"
                value={formFields?.purchase_target_per_month}
                onChange={v => {
                  handleFormFieldsChange({ purchase_target_per_month: v })
                }}
                helperText={undefined}
              />

              <TextFieldComponent
                label="Income/Profit target month"
                type="number"
                value={formFields?.income_profit_target_per_month}
                onChange={v => {
                  handleFormFieldsChange({ income_profit_target_per_month: v })
                }}
                helperText={undefined}
              />

              <TextFieldComponent
                label="Start-Up loan amount"
                type="number"
                value={formFields?.startup_loan_amount}
                onChange={v => {
                  handleFormFieldsChange({ startup_loan_amount: v })
                }}
                helperText={undefined}
              />
            </div>

            <div className="grid grid-cols-2 gap-2">
              <TextFieldComponent
                label="Loan repayments"
                type="number"
                value={formFields?.loan_repayments}
                onChange={v => {
                  handleFormFieldsChange({ loan_repayments: v })
                }}
                helperText={undefined}
              />
              <TextFieldComponent
                label="Length of loan repayments"
                type="number"
                value={formFields?.length_of_loan_repayments}
                onChange={v => {
                  handleFormFieldsChange({ length_of_loan_repayments: v })
                }}
                helperText={undefined}
              />
            </div>

            <div className="grid grid-cols-3 gap-2 w-full">
              <DatePickerComponent
                title="Month loan repayment start"
                value={formFields?.month_loan_repayment_start}
                onChange={v => {
                  handleFormFieldsChange({
                    month_loan_repayment_start: moment(v).format(DATE_FORMAT)
                  })
                }}
                helperText={undefined}
              />
              <DatePickerComponent
                title="Start Date"
                required={handleDisabled('start_date')}
                value={formFields?.start_date}
                onChange={v =>
                  handleFormFieldsChange({
                    start_date: moment(v).format(DATE_FORMAT)
                  })
                }
              />
              <DatePickerComponent
                title="End Date"
                required={handleDisabled('end_date')}
                value={formFields?.end_date}
                onChange={v => {
                  handleFormFieldsChange({
                    end_date: moment(v).format(DATE_FORMAT)
                  })
                }}
              />
            </div>

            <FileUploadButton
              title="Programme Artwork"
              onChange={v => {
                upload_file({ file: v, name: v.name }).then(res => {
                  if (res.data.success) {
                    handleFormFieldsChange({
                      location: res.data.content.location,
                      etag: res.data.content.etag
                    })
                  }
                })
              }}
            />

            <hr className="text-gray" />

            <div className="flex flex-col justify-center items-center">
              <h1 className="text-black text-2xl text-bold mb-4">Pick a color</h1>
              {pickColor ? (
                <div className="flex gap-2">
                  <SketchPicker
                    color={formFields?.program_color ? formFields?.program_color : '#000000'}
                    onChange={v => {
                      handleFormFieldsChange({
                        program_color: v.hex
                      })
                    }}
                  />
                  <p className="" onClick={() => setPickColor(false)} style={colorPickRemoveBtn}>
                    X
                  </p>
                </div>
              ) : (
                <div className="flex gap-2 justify-between items-center">
                  <div
                    title="Click to select a colour"
                    onClick={v => {
                      setPickColor(true)
                    }}
                    style={{
                      backgroundColor: formFields?.program_color
                        ? formFields?.program_color
                        : '#000000',
                      width: '50px',
                      height: '50px',
                      borderRadius: '100%'
                    }}
                  ></div>
                  <TextFieldComponent
                    label="Program Colour"
                    disabled={true}
                    required={handleDisabled('program_color')}
                    value={formFields?.program_color ? formFields?.program_color : '#000000'}
                    // onChange={v =>
                    //   handleFormFieldsChange({
                    //     program_color: v
                    //   })
                    // }
                  />
                </div>
              )}
            </div>
          </div>

          <div className="flex w-full flex-col gap-4">
            <div className="grid grid-rows-2 gap-2">
              <TextFieldComponent
                label="Module Name"
                required={handleDisabled('module_name')}
                value={module?.module_name}
                onChange={v => setModule({ ...module, module_name: v })}
                helperText={undefined}
              />
              <div className="grid grid-cols-3 gap-2">
                <RadioButtonComponent
                  title={'Optimization Type'}
                  color={'black'}
                  row={true}
                  displayKey={'label'}
                  buttons={[
                    { value: 'Human', label: 'Human' },
                    { value: 'Business', label: 'Business' }
                  ]}
                  onClick={e => {
                    setModule({
                      ...module,
                      optimization_type: e.value
                    })
                  }}
                />
                <DatePickerComponent
                  title="Start Date"
                  value={module?.start_date}
                  onChange={v =>
                    setModule({
                      ...module,
                      start_date: moment(v).format(DATE_FORMAT)
                    })
                  }
                />

                <DatePickerComponent
                  title="End Date"
                  value={module?.end_date}
                  onChange={v =>
                    setModule({
                      ...module,
                      end_date: moment(v).format(DATE_FORMAT)
                    })
                  }
                />
              </div>
            </div>

            <div className="flex w-full justify-end">
              <ButtonComponentContained
                size="small"
                title="Add module"
                disabled={!canSaveModule}
                onClick={v => {
                  let newModules = [...modules, module]
                  setModules(newModules)
                  setModule({})
                }}
                startIcon={undefined}
                endIcon={undefined}
                children={undefined}
              />
            </div>
            <hr />

            <div className="font-bold text-sm text-black">Added Modules</div>

            <Paper elevation={3} className="p-4">
              <div className="flex flex-col gap-1">
                {modules.map((module, i) => (
                  <div
                    key={i}
                    className="flex flex-row w-full py-1 px-4 rounded-md border border-white bg-gray shadow-sm hover:bg-theme justify-between items-center"
                  >
                    <div className="text-black">{module?.module_name}</div>

                    <ButtonComponentOutlined
                      endIcon={faTimes}
                      bgColor="#f00"
                      title="Remove"
                      onClick={() => removeModule(module)}
                    />
                  </div>
                ))}
              </div>
            </Paper>
          </div>
        </TabControl>

        <div className="pt-8 flex justify-end gap-2">
          <ButtonComponentOutlined
            title={'Cancel'}
            startIcon={faTimes}
            onClick={() => {
              setShow(false)
            }}
            bgColor="#f00"
          />

          <ButtonComponentContained
            disabled={!canSaveForm}
            title={'Submit'}
            loading={loading}
            startIcon={faSave}
            onClick={async () => {
              setLoading(true)
              if (program._id) {
                dispatch(updateProgram({ id: program._id, ...formFields, modules }))
              } else {
                dispatch(createProgram({ ...formFields, modules }))
              }
              setLoading(false)
            }}
          />
        </div>
      </BackDropComponent>
      <LoaderControl />
    </div>
  )
}

export default CreateProgrammeComponent
