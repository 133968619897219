import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  TablePagination,
  Tooltip,
  TextField
} from '@material-ui/core'
import React, { useEffect } from 'react'
import ButtonComponentContained from '../buttons/buttonContained'
import TextFieldComponent from '../textfield/textfiled'

const TableComponent = ({
  data = [],
  displayKeys = [],
  title = '',
  onRowClick = () => {},
  actions,
  children,
  search = false,
  rows = 10
}) => {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(rows)
  const [searchQuery, setSearchQuery] = React.useState('')
  const [selectedRow, setSelectedRow] = React.useState(null)

  useEffect(() => {
    setRowsPerPage(rows)
  }, [rows])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const headerStyle = {
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '10px'
  }

  const rowStyle = {
    fontWeight: 'normal',
    fontSize: '12px',
    padding: '10px'
  }

  // Filter data based on searchQuery
  const filteredData = data.filter(row =>
    displayKeys.some(key => {
      const cellValue = key.subKey ? row[key.value]?.[key.subKey] : row[key.value]
      return cellValue && cellValue.toString().toLowerCase().includes(searchQuery.toLowerCase())
    })
  )

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }} className="p-8">
      <div className="flex flex-col gap-4">
        <div className="flex w-full gap-12 items-center">
          <div className="font-sans font-extrabold text-lg">{title}</div>
          <div className="flex w-full gap-2 justify-end">
            {/* Add Search Input */}
            {search && (
              <Tooltip title="Search">
                <TextFieldComponent
                  label="Search"
                  value={searchQuery}
                  onChange={e => setSearchQuery(e)}
                  required={true}
                  size={'small'}
                  helperText={undefined}
                />
              </Tooltip>
            )}
            {children}
          </div>
        </div>

        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer>
            <Table>
              <TableHead
                style={{
                  backgroundColor: '#59a6a6',
                  color: '#fff'
                }}
              >
                <TableRow>
                  {displayKeys.map((key, index) => (
                    <TableCell key={index} style={{ ...headerStyle, color: '#fff' }}>
                      {key.label}
                    </TableCell>
                  ))}
                  {actions && (
                    <TableCell
                      style={{
                        ...headerStyle,
                        color: '#fff',

                        justifyContent: 'right',
                        //pad to the right
                        paddingRight: '30px',
                        textAlign: 'right'
                      }}
                    >
                      Actions
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        className="hover:bg-gray cursor-pointer"
                        hover={true}
                        selected={selectedRow === row}
                      >
                        {displayKeys.map((key, index) => (
                          <TableCell
                            key={index}
                            style={rowStyle}
                            onClick={() => {
                              onRowClick(row)
                              setSelectedRow(row)
                            }}
                          >
                            {key.isChip === true ? (
                              <Chip
                                label={row[key.value]}
                                size="small"
                                style={{
                                  fontWeight: 'bold',
                                  backgroundColor: key.expression.find(
                                    item => item.value === row[key.value]
                                  ).color,
                                  color: '#fff'
                                }}
                              />
                            ) : key.isArray === true ? (
                              row[key.value].map((item, index) => <div key={index}>{item}</div>)
                            ) : key.subKey ? (
                              Array.isArray(row[key.value][key.subKey]) ? (
                                row[key.value][key.subKey].map((item, index) => (
                                  <div key={index}>{item}</div>
                                ))
                              ) : (
                                row[key.value][key.subKey]
                              )
                            ) : key.isLink ? (
                              // <a href="#" onClick={() => key.onClick(row)}></a>
                              <a href={row[key.value]} target="_blank" className="text-green">
                                {row[key.value]}
                              </a>
                            ) : (
                              row[key.value]
                            )}
                          </TableCell>
                        ))}
                        {actions && (
                          <TableCell
                            style={{
                              ...rowStyle,
                              // display: 'flex',
                              // justifyContent: 'right'
                              alignContent: 'right',
                              textAlign: 'right'
                            }}
                          >
                            {actions.map((action, index) => (
                              <ButtonComponentContained
                                key={index}
                                title={action.label}
                                startIcon={action.icon}
                                onClick={() => action.action(row)}
                                style={{ margin: '0 2px' }}
                                size="small"
                                disabled={action.hide && action.hide(row)}
                              />
                            ))}
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </Paper>
  )
}

export default TableComponent
