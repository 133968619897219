import React, { useEffect, useState } from 'react'
import InputComponent from '../../customComponents/input'
import ButtonComponent from '../../customComponents/button'
import PopupControl from '../../../controls/popup/popup'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import DatePickerComponent from '../../customComponents/datepicker'
import { get_catch_up_days } from '../../../api/calls/tasks/get_catch_up_days'
import DropdownButtonComponent from '../../customComponents/dropdownButton'
import { issue_catch_up_day } from '../../../api/calls/issue_catch_up_day'

const IssueCatchUpDay = ({ show, setShow, data, showAlert, onSuccess = () => {} }) => {
  const [formValues, setFormValues] = useState({ type: 'Catch Up Day', ...data })
  const [catchUpdays, setCatchUpDays] = useState([])
  const [canSave, setCanSave] = useState(false)

  const _onInputChange = e => {
    setFormValues({ ...formValues, ...e })
  }

  const _validateForm = () => {
    if (formValues.date && formValues.reason) {
      setCanSave(true)
    } else {
      setCanSave(false)
    }
  }

  const _onSave = async () => {
    //api call...
    //write what operation should take place here.
    await issue_catch_up_day(formValues).then(e => {
      if (e.data.success) {
        showAlert(true)
        setShow(false)
        onSuccess()
      }
    })
  }

  const _fetchCatchUpDays = () => {
    //api call...
    //write what operation should take place here.
    get_catch_up_days().then(e => {
      if (e.data.success) {
        setCatchUpDays(e.data.content)
      }
    })
  }

  useEffect(() => {
    if (show) {
      _fetchCatchUpDays()
    }
  }, [show])

  useEffect(() => {
    _onInputChange({
      ...data
    })
  }, [data])

  useEffect(() => {
    _validateForm()
  }, [formValues])

  return (
    <div>
      <PopupControl
        title="Add Catch Up Day"
        isOpen={show}
        close={{
          showClose: true,
          onClose: () => {
            setShow(false)
          }
        }}
      >
        <div className="w-full flex flex-col gap-4">
          <DatePickerComponent
            title={'Select Date'}
            date={formValues?.date}
            isDateOnly={true}
            onChange={e => {
              _onInputChange({
                date: e
              })
            }}
          />
          <InputComponent
            theme="dark"
            size="lg"
            title="Reason for being absent"
            value={formValues?.reason}
            onChange={e =>
              _onInputChange({
                reason: e
              })
            }
          />
          <DropdownButtonComponent
            placeholder="Select Catch Up Day"
            isBusy={false}
            type="primary"
            theme="light"
            displayKey="event_name"
            dropdownItems={catchUpdays}
            selectedItem={
              catchUpdays?.length > 0
                ? catchUpdays.find(x => x._id == formValues?.event_id)?.event_name
                : ''
            }
            onClick={item => {
              _onInputChange({
                event_id: item._id
              })
            }}
          />
          <ButtonComponent
            title="Save"
            disabled={!canSave}
            icon={faSave}
            isBusy={false}
            type="primary"
            onClick={_ => {
              _onSave(formValues)
            }}
          />
        </div>
      </PopupControl>
    </div>
  )
}

export default IssueCatchUpDay
