import React, { useEffect, useState } from 'react'

import { connect, useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import {
  get_all_groups,
  get_program_users,
  get_venues,
  save_catch_up_register,
  save_register
} from '../../api/calls'
import { getLearners, getPrograms } from '../../asyncActions'
import { CircleIconText } from '../../controls/circleIconText/circleIconText'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import AttendanceTable from './components/attendanceTable'
import { initialAttendanceData } from './components/contants'
import { convertDateTimeFromISO } from '../../utils'
import moment from 'moment'
import { get_draft_register } from '../../api/calls/get_register_draft'
import { useHistory } from 'react-router-dom'
import { setAlert } from '../../asyncActions/alert'
import { get_curriculum_interactions } from '../../api/calls/curriculums/get_curriculum_interactions'
import { get_catch_up_days } from '../../api/calls/get_catch_up_days'
import FormComponent from '../../controls/forms/form'
import RadioButtonComponent from '../UIComponents/components/radio/radioButtton'
import TextFieldComponent from '../UIComponents/components/textfield/textfiled'
import DatePickerComponent from '../UIComponents/components/calendar/date'
import SingleSelectComponent from '../UIComponents/components/select/singleSelect'

const RemedialAction = ({
  venueState,
  learnerState,
  notificationState: notificationData,
  programsState,
  userState
}) => {
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState(initialAttendanceData)
  const [init, setInit] = useState(true)
  const [complete, setComplete] = useState(false)
  const [users, setUsers] = useState([])
  const [venues, setVenues] = useState([])
  const [selectedGroup, setSelectedGroup] = useState()
  const { program_id } = useParams()
  const [selectedProgram, setSelectedProgram] = useState({})
  const [attendanceForm, setAttendanceForm] = useState({})
  const [interactions, setInteractions] = useState([])
  const history = useHistory()
  const [dayType, setDayType] = useState()
  const [groups, setGroups] = useState([])
  const [loadUsers, setLoadUsers] = useState(true)
  const [learners, setLearners] = useState([])

  const _onSave = async draft => {
    const payload = { ...formValues, draft: draft }

    if (dayType === 'Catch Up') {
      await save_catch_up_register(payload).then(res => {
        if (res.data.success) {
          dispatch(
            setAlert({
              show: true,
              success: true,
              message: `You have successfully saved catch up register ${
                draft === true ? 'as draft' : ''
              }.`
            })
          )
          setComplete(true)
        } else {
          dispatch(
            setAlert({
              show: true,
              success: false,
              message: 'The catch up register could not be saved.'
            })
          )
        }
      })
    } else {
      save_register(payload).then(res => {
        if (res.data.success) {
          dispatch(
            setAlert({
              show: true,
              success: true,
              message: `You have successfully saved register ${draft === true ? 'as draft' : ''}.`
            })
          )
          setComplete(true)
        } else {
          dispatch(
            setAlert({
              show: true,
              success: false,
              message: 'The register could not be saved.'
            })
          )
        }
      })
    }

    //set back to default
    dispatch(
      setAlert({
        show: false,
        success: false
      })
    )
  }

  useEffect(() => {
    dispatch(
      setAlert({
        show: false,
        success: false,
        message: 'You have successfully saved register.'
      })
    )
    get_draft_register(program_id).then(e => {
      if (e.data.success && e.data.content != null) {
        const id = e.data.content._id
        delete e.data.content._id
        delete e.data.content.learners
        setFormValues({ ...formValues, id, ...e.data.content })
      }
    })
  }, [])

  useEffect(() => {
    console.log('formValues', formValues)
  }, [formValues])

  useEffect(() => {
    if (complete === true) {
      history.goBack()
    }
  }, [complete])

  useEffect(() => {
    if (Object.keys(attendanceForm).length > 0) {
      _onInputChange({
        attendance_form: {
          name: '',
          url: attendanceForm.attendance_form.location,
          etag: attendanceForm.attendance_form.etag
        }
      })
    }
  }, [attendanceForm])

  const _onInputChange = v => {
    setFormValues({ ...formValues, ...v })
  }

  useEffect(() => {
    if (!programsState.programsLoaded) {
      dispatch(getPrograms())
    }
    if (init && learnerState.learners.length == 0) {
      dispatch(getLearners())
      setInit(false)
    }

    if (init && groups.length == 0) {
      get_all_groups().then(res => {
        if (res.data.success) {
          setGroups(res.data.content)
        }
      })
      setInit(false)
    }

    let _program = programsState.programs.find(x => x._id === program_id)
    if (_program) {
      setSelectedProgram(_program)
    }

    get_venues()
      .then(response => {
        if (response.data.success) {
          setVenues(response.data.content)
        }
      })
      .catch(error => {})

    _onInputChange({ program_id: selectedProgram._id, facilitator_id: userState.id })
  }, [learnerState, programsState, selectedProgram, venueState, userState, groups])

  useEffect(() => {
    if (Object.keys(selectedProgram).length > 0) {
      get_program_users(`id=${selectedProgram._id}`).then(res => {
        if (res.data.success) {
          setUsers(res.data.content.length)
        }
      })
    }
  }, [selectedProgram])

  useEffect(() => {
    if (selectedGroup != null) {
      if (Object.keys(selectedProgram).length > 0) {
        get_program_users(`id=${selectedProgram._id}&group_id=${selectedGroup?._id}`).then(res => {
          if (res.data.success) {
            setUsers(res.data.content)
          }
        })
      }
    }
  }, [selectedGroup])

  const fetchInteractions = day_type => {
    if (program_id)
      get_curriculum_interactions({ program_id, day_type: day_type })
        .then(response => {
          if (response.data.success) {
            setInteractions(response.data.content)
          }
        })
        .catch(error => {})
  }

  const handleLearners = value => {
    setLearners(value)
  }

  useEffect(() => {
    _onInputChange({ learners })
  }, [learners])

  const fetchLearnersInCatchUp = async () => {
    await get_catch_up_days({
      program_id,
      interaction_id: formValues.interaction_id
    }).then(response => {
      if (response.data.success) {
        const learners = response.data.content.map(e => {
          return {
            _id: e.user._id,
            user_name: e.user.user_name,
            user_surname: e.user.user_surname,
            cell_number: e.user.cell_number
          }
        })

        setUsers(learners)
        setLoadUsers(false) //set load users to false so that when the page reloads it does not fetch again
        setLearners([]) //set learners to empty so that it can be reloaded
      }
    })
  }

  useEffect(() => {
    if (formValues.interaction_id && dayType == 'Catch Up' && loadUsers) {
      fetchLearnersInCatchUp()
    }
  }, [formValues, dayType, loadUsers])

  useEffect(() => {
    if (dayType) {
      handleDayTypeChange(dayType)
    }
  }, [loadUsers, dayType])

  const handleDayTypeChange = value => {
    fetchInteractions(value)
  }

  return (
    <TableContentWrapper title="Attendance Register" notificationData={notificationData}>
      <FormComponent
        title={'Attendance Register'}
        description={'Please fill in the form below to fill in the register'}
        onCancel={() => {
          history.goBack()
        }}
        onSubmit={() => {
          _onSave(false)
        }}
      >
        <div className="flex flex-col gap-2">
          <div className="font-semibold text-sm">1. Enter name and number of registers used</div>
          <div className="grid grid-cols-2 gap-2">
            <TextFieldComponent
              required={true}
              label="Name"
              value={formValues?.name}
              onChange={v => {
                _onInputChange({ name: v })
              }}
              helperText={undefined}
            />
            <TextFieldComponent
              required={true}
              label="Registers Used"
              value={formValues.number_of_registers}
              type="number"
              onChange={v => {
                _onInputChange({ number_of_registers: v })
              }}
              helperText={undefined}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2">
          <div className="flex flex-col gap-2">
            <div className="font-semibold text-sm">2. Select the day type (required)</div>
            <RadioButtonComponent
              displayKey={'label'}
              buttons={[
                { label: 'Standard Day', value: 'Standard' },
                { label: 'Catch Up Day', value: 'Catch Up' },
                { label: 'Value Exchange Day', value: 'VED' }
              ]}
              // value={''}
              onClick={e => {
                setDayType(e.value)
              }}
              // selectedValue={dayType ?? ''}
            />
          </div>
          <div className="flex flex-col gap-2">
            <div className="font-semibold text-sm">3. Select OI rating (required)</div>
            <RadioButtonComponent
              displayKey={'label'}
              // row={true}
              buttons={[
                { label: 'Human OI', value: 'Human' },
                { label: 'Technical OI', value: 'Technical' }
              ]}
              value={formValues?.level_type}
              onClick={e => {
                _onInputChange({ level_type: e.value })
              }}
              // selectedValue={formValues.level_type ?? ''}
            />
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <div className="font-semibold text-sm">4. Select the date</div>
          <DatePickerComponent
            title={'Select Date'}
            required={true}
            value={formValues?.date ?? ''}
            onChange={v => {
              _onInputChange({
                date: moment(v).format('YYYY-MM-DD')
              })
            }}
          />
        </div>

        <div className="grid grid-cols-3 gap-2">
          <div className="flex flex-col gap-2">
            <div className="font-semibold text-sm">5. Select Group</div>
            <SingleSelectComponent
              required={dayType == 'Catch Up' ? false : true}
              disabled={dayType == 'Catch Up' ? true : false}
              title="Select Group"
              errorMessage={'Groups Not Found'}
              data={groups ?? []}
              displayKey="name"
              onClick={item => {
                setSelectedGroup(item)
                _onInputChange({ group_id: item?._id })
              }}
              color={undefined}
            />
          </div>

          <div className="flex flex-col gap-2">
            <div className="font-semibold text-sm">6. Select Interaction</div>
            <SingleSelectComponent
              required={true}
              title="Select Interaction"
              errorMessage={'Interactions Not Found'}
              data={interactions.filter(e => {
                if (dayType === 'Catch Up') {
                  return e
                } else {
                  return !e.attended
                }
              })}
              displayKey="interaction_name"
              onClick={item => {
                _onInputChange({ interaction_id: item._id })
                setLoadUsers(true)
              }}
              color={undefined}
            />
          </div>

          <div className="flex flex-col gap-2">
            <div className="font-semibold text-sm">7. Select Venue</div>
            <SingleSelectComponent
              required={true}
              title="Select Venue"
              errorMessage={'Venues Not Found'}
              data={venues}
              displayKey="name"
              onClick={item => {
                _onInputChange({ venue_id: item._id })
                setLoadUsers(true)
              }}
              color={undefined}
            />
          </div>
        </div>

        <div className="w-full flex-col gap-2">
          <div className="flex flex-row justify-between gap-2 py-4">
            <div className="w-full rounded-lg">
              <div className="bg-gray flex justify-center p-2">Attendance Consequences</div>
              <div className="bg-card1 flex flex-col justify-start items-start p-2 gap-2">
                <CircleIconText
                  text="Not Present - Gets a dismissed after 3 mistakes (reason)"
                  className="text-red-600"
                />
                <CircleIconText text="Late - Gets a warning for reason" className="text-yellow" />
                <CircleIconText text="Present - No consequences" className="text-green" />
              </div>
            </div>
            <div className="w-full">
              <div className="bg-gray flex justify-center p-2">KPIs to be met</div>
              <div className="bg-card1 flex flex-col justify-start items-start p-2 gap-2">
                <CircleIconText text="0-49 %" className="text-red-600" />
                <CircleIconText text="50-69% orange" className="text-orange" />
                <CircleIconText text="70-100% green" className="text-green" />
              </div>
            </div>
          </div>
        </div>
        <hr className="text-gray" />

        <AttendanceTable
          users={users.length > 0 ? users : []}
          date={convertDateTimeFromISO(formValues.date).date}
          onChange={handleLearners}
          program={selectedProgram}
          dayType={dayType}
          reload={loadUsers}
        ></AttendanceTable>

        <TableContentWrapper children={undefined} title={undefined}>
          <div className="flex flex-col">
            <div className="text-lg font-extrabold">Note:</div>
            <div className="text-sm">
              Saving register as draft will not save it with learners and group.
            </div>
          </div>
        </TableContentWrapper>
      </FormComponent>
    </TableContentWrapper>
  )
}

const mapStateToProps = state => {
  return {
    learnerState: state.learnerState,
    venueState: state.venueState,
    notificationState: state.notificationState,
    programsState: state.programsState,
    facilitatorState: state.facilitatorState,
    userState: state.userState,
    alertState: state.alertState
  }
}

export default connect(mapStateToProps)(RemedialAction)
