export const validateForm = (validationData = [], formValues = {}) => {
  let valid = true
  validationData.forEach(e => {
    if (e.required && !formValues[e.name]) {
      if (e.isArray) valid = formValues[e.name]?.length > 0
      else valid = false
    }
  })

  return valid
}

export const contactValidationData = [
  {
    name: 'email',
    required: false
  },
  {
    name: 'phone_number',
    required: true
  }
]

export const addressValidationData = [
  {
    name: 'address_line_1',
    required: true
  },
  {
    name: 'address_line_2',
    required: true
  },
  {
    name: 'city',
    required: true
  },
  {
    name: 'province',
    required: true
  },
  {
    name: 'country',
    required: true
  },
  {
    name: 'postal_code',
    required: true
  }
]

export const personalValidationData = [
  { name: 'name', required: true },
  { name: 'surname', required: true },
  { name: 'training_provider', required: true },
  { name: 'municipality', required: true },
  { name: 'age', required: true },
  { name: 'race', required: true },
  { name: 'gender', required: true },
  { name: 'nationality', required: true }
]

export const groupsAndEventsValidationData = [
  {
    name: 'group_id',
    required: true
  },
  {
    name: 'event_id',
    required: true
  }
]
