import React, { useEffect, useState } from 'react'

import { faFilter } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect, useDispatch } from 'react-redux'
import { getGroups, getPrograms } from '../../asyncActions'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import DropdownButtonComponent from '../customComponents/dropdownButton'
import CheckBoxControl from '../../controls/checkbox/checkbox'
import { get_performance } from '../../api/calls/get_performance'
import { APTrackerTable } from './components/table'

const PerformanceTracker = ({ groupState, programsState }) => {
  const [init, setInit] = useState(true)
  const [formValues, setFormValues] = useState({
    target_filter: ['SEEDLINGS']
  })
  const [content, setContent] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [searchItem, setSearchItem] = useState('')
  const dispatch = useDispatch()
  const list = []
  const [seedlings, setSeedlings] = useState([])
  const [sqm, setSqm] = useState([])

  useEffect(() => {
    if (init) {
      dispatch(getPrograms())
      dispatch(getGroups())
      setInit(false)
    }
  }, [programsState, groupState])

  const _performFilter = query => {
    get_performance(query).then(response => {
      if (response.data.success) {
        setContent(response.data.content)
      }
    })
  }

  const _onGetPerformance = async () => {
    await get_performance(formValues).then(response => {
      let data = []
      setSqm([])
      setSeedlings([])

      if (response.data.success && response.data.content.length > 0) {
        for (const transaction of response.data.content) {
          data = []
          const isSeedlings = transaction.transaction_type === 'SEEDLINGS'
          const setFunction = isSeedlings ? setSeedlings : setSqm

          for (const user_transaction of transaction.user_transactions) {
            const user = {
              name: user_transaction.user_name,
              surname: user_transaction.user_surname
            }

            let months = []
            let total = 0

            for (const monthly_transaction of user_transaction.monthly_transactions) {
              const month = {
                name: monthly_transaction.month,
                amount: monthly_transaction.total_amount,
                indicator: monthly_transaction.performance_level
              }

              total += monthly_transaction.total_amount
              months.push(month)
            }

            data.push({
              user,
              months,
              total
            })
          }

          setFunction(data)
        }
      }
    })
  }
  

  useEffect(() => {
    console.log('seedlings', seedlings)
  }, [seedlings])

  useEffect(() => {
    filteredData.splice(0, filteredData.length)

    if (content.length > 0) {
      if (Object.keys(searchItem).length != 0) {
        content.forEach(e => {
          if (
            e.user_name.toString().toLowerCase().includes(searchItem.toLowerCase()) ||
            e.user_surname.toString().toLowerCase().includes(searchItem.toLowerCase())
          ) {
            list.push(e)
          }

          e.interactions.forEach(interaction => {
            if (interaction.code.toString().toLowerCase().includes(searchItem.toLowerCase())) {
              if (list.length == 0) {
                list.push(e)
              } else {
                list.forEach(element => {
                  if (element !== e) {
                    list.push(e)
                  }
                })
              }
            }
          })
        })
      } else {
        filteredData.splice(0, filteredData.length)
        setFilteredData([])
      }
    }
    setFilteredData(list)
  }, [searchItem])

  const _onFormValuesChange = values => {
    setFormValues({ ...formValues, ...values })
  }

  useEffect(() => {
    if (!formValues?.program_id) return

    _onGetPerformance()
  }, [formValues])

  return (
    <TableContentWrapper title={'Performance Tracker'}>
      {/* ----------Table */}
      <div className="flex flex-row gap-8 items-end">
        <div className="w-full rounded-md shadow-sm bg-indigo">
          <div className="p-4 flex gap-2">
            <div className="flex flex-row gap-1">
              <span>
                <FontAwesomeIcon size="sm" icon={faFilter} color="white" />
                <div className="text-white">Filter</div>
              </span>
            </div>
            <DropdownButtonComponent
              placeholder="Select Programme"
              isBusy={false}
              type="primary"
              theme="dark"
              displayKey="program_name"
              dropdownItems={programsState?.programs}
              selectedItem={
                programsState?.programs?.length > 0
                  ? programsState?.programs?.find(x => x._id == formValues?.program_id)
                      ?.program_name
                  : ''
              }
              onClick={item => {
                _onFormValuesChange({
                  program_id: item._id
                })
              }}
            />
            <DropdownButtonComponent
              placeholder="Select Group"
              isBusy={false}
              type="primary"
              theme="dark"
              displayKey="name"
              dropdownItems={groupState?.groups}
              selectedItem={
                groupState?.groups.length > 0
                  ? groupState?.groups.find(x => x._id == formValues?.group_id)?.name
                  : ''
              }
              onClick={item => {
                _onFormValuesChange({
                  group_id: item._id
                })
              }}
            />
            <div className="flex gap-4">
              <CheckBoxControl
                label="Seedlings"
                theme="dark"
                name="target_filter"
                checked={formValues?.target_filter?.find(x => x == 'SEEDLINGS')}
                value="SEEDLINGS"
                onChange={e => {
                  const found = formValues?.target_filter?.find(x => x == e.target.value)
                  if (found) {
                    const newTargets = formValues?.target_filter?.filter(x => x != e.target.value)
                    _onFormValuesChange({ target_filter: newTargets })
                  } else {
                    _onFormValuesChange({
                      target_filter: [...formValues?.target_filter, e.target.value]
                    })
                  }
                }}
              />
              <CheckBoxControl
                label="SQM"
                theme="dark"
                name="target_filter"
                checked={formValues?.target_filter?.find(x => x == 'SQM')}
                value="SQM"
                onChange={e => {
                  const found = formValues?.target_filter?.find(x => x == e.target.value)
                  let targets = []
                  if (found) {
                    const newTargets = formValues?.target_filter?.filter(x => x != e.target.value)
                    _onFormValuesChange({ target_filter: newTargets })
                  } else {
                    _onFormValuesChange({
                      target_filter: [...formValues?.target_filter, e.target.value]
                    })
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <APTrackerTable title={'Seedlings Tracker'} showPagination={true} data={seedlings} />
      <APTrackerTable title={'Square Meter Tracker'} showPagination={true} data={sqm} />
    </TableContentWrapper>
  )
}
const mapStateToProps = state => {
  return {
    groupState: state.groupState,
    programsState: state.programsState
  }
}

export default connect(mapStateToProps)(PerformanceTracker)
