import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import Loader from '../../assets/images/loader.png'
import { faChevronDown, faChevronUp, faSearch } from '@fortawesome/pro-light-svg-icons'

const DropdownButtonComponent = props => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownButtonRef = useRef()
  const [filteredDropdownItems, setFilteredDropdownItems] = useState([])

  const toggleDropdown = () => {
    setIsOpen(prevOpen => !prevOpen)
  }

  const {
    title,
    theme = 'light',
    disabled,
    onClick,
    isLoading = false,
    isLink = false,
    sizes = 'md',
    selectedItem,
    dropdownItems = [],
    displayKey,
    selector,
    showSearch = false,
    placeholder,
    required = false,
    buttonBg
  } = props
  const options = {
    light: {
      titleColor: 'text-white',
      placeholder: 'text-white',
      color: !disabled && !isLoading ? `${buttonBg ?? 'bg-theme'}` : 'bg-gray cursor-not-allowed',
      hover: !disabled && !isLoading ? 'hover:bg-blue-400' : 'null'
    },
    dark: {
      titleColor: 'text-white',
      placeholder: 'text-gray',
      color:
        !disabled && !isLoading && dropdownItems.length > 0
          ? 'bg-textinput-dark border border-gray'
          : 'bg-gray cursor-not-allowed',
      hover:
        !disabled && !isLoading && dropdownItems.length > 1
          ? 'hover:bg-blue-400 hover:rounded-md'
          : null
    },
    form: {
      titleColor: 'text-black',
      placeholder: 'text-gray',
      color: !disabled
        ? `${
            buttonBg != null ? `${buttonBg} text-white` : 'bg-white text-black'
          } text-sm border border-gray focus:border-blue-400`
        : 'bg-gray text-black cursor-not-allowed',
      hover: !disabled ? 'hover:bg-blue-400 hover:rounded-md' : null
    }
  }

  const size = {
    sm: 'text-sm h-8 py-1.5 font-medium',
    md: 'text-md h-10 py-2.5 font-medium',
    lg: 'text-lg h-12 px-4 py-4 font-medium'
  }

  useEffect(() => {
    setIsOpen(false)
  }, [selectedItem])

  useEffect(() => {
    const _handleClickOutside = event => {
      if (dropdownButtonRef.current && !dropdownButtonRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', _handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', _handleClickOutside)
    }
  }, [])

  //create a function that will filter the dropdown items based on a search query
  const filterDropdownItems = query => {
    //filter the dropdown items based on the query
    const filteredDropdownItems = dropdownItems.filter(item => {
      //check if the item's display key contains the query
      return item[displayKey].toLowerCase().includes(query.toLowerCase())
    })

    //set the filtered dropdown items
    setFilteredDropdownItems(filteredDropdownItems)
  }

  useEffect(() => {
    //set the filtered dropdown items
    setFilteredDropdownItems(dropdownItems)
  }, [dropdownItems])

  return (
    <div className="flex w-full flex-col gap-2">
      <div className="flex flex-col relative w-full h-30 gap-2" ref={dropdownButtonRef}>
        <div className={`${options[theme].hover} flex flex-col`}>
          {title && (
            <div className={`text-sm ${options[theme].titleColor}`}>
              {title} {required && <span className="text-red-400">*</span>}
            </div>
          )}

          <button
            onClick={toggleDropdown}
            className={`${options[theme].color} ${options[theme].hover} ${
              options[theme].titleColor
            } ${options[theme].placeholder} ${
              isLink ? 'hover:underline' : 'hover:none'
            } h-12 px-2 py-2 shadow rounded-md text-center w-full justify-between leading-normal inline-flex items-center dark:bg-blue-600 dark:focus:ring-blue-800`}
            disabled={isLoading ? true : dropdownItems.length < 1 ? true : disabled}
            // placeholder="Please select"
            // className="bg-blue-400 gap-2 p-4 w-full flex items-center justify-between font-bold text-md rounded-sm tracking-wider border-4 border-transparent active:border-white duration-300 active:text-white"
          >
            {isLoading == true ? (
              <div className="flex gap-2">
                <img src={Loader} className="h-5 w-5 animate-spin" />
                <div>Loading...</div>
              </div>
            ) : (
              <div className="text-center w-full justify-between inline-flex items-center gap-1">
                <div
                  className={`w-full justify-left items-center flex ${
                    theme == 'dark' && selectedItem && theme == 'light'
                      ? 'text-white'
                      : `${theme == 'form' && selectedItem ? 'text-black' : ''}`
                  }`}
                >
                  {dropdownItems.length < 1
                    ? 'No Item(s)'
                    : selectedItem != null && selectedItem.length > 0
                    ? selectedItem
                    : placeholder ?? 'Please select'}
                </div>
                {!isOpen ? (
                  <FontAwesomeIcon icon={faChevronDown} />
                ) : (
                  <FontAwesomeIcon icon={faChevronUp} />
                )}
              </div>
            )}
          </button>
        </div>

        {isOpen && (
          <div
            className={`absolute z-20 ${
              title ? 'top-20' : 'top-14 '
            } w-full max-h-80 bg-white text-black border-gray shadow-md rounded-md flex flex-col gap-2`}
          >
            {showSearch && (
              <div className="relative flex">
                <div className="absolute inset-y-0 right-10 flex items-center pl-3 pointer-events-none">
                  <FontAwesomeIcon icon={faSearch} />
                </div>
                <div className="flex p-4 w-full">
                  <input
                    type="search"
                    id="default-search"
                    className="w-full block p-4 pl-10 text-sm text-gray-900 border-b border-gray bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white shadow appearance-none ${options[theme].color} rounded-md  border border-gray h-12 w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Search"
                    required
                    onChange={e => {
                      // console.log(e.target.value)
                      filterDropdownItems(e.target.value)
                    }}
                  />
                </div>
              </div>
            )}
            <div className="w-full rounded-md max-h-80 bg-white text-black border-gray shadow-md overflow-scroll">
              {filteredDropdownItems.map((item, i) => (
                <div
                  key={i}
                  onClick={v => {
                    onClick(item)
                    toggleDropdown()
                  }}
                  className="text-sm flex w-full justify-between rounded-md hover:bg-blue-300 cursor-pointer border-transparent hover:border-blue-700 border-l-2 py-2 px-4"
                >
                  {`${item[displayKey]} ${item?.default == true ? ' - (Default)' : ''}`}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default DropdownButtonComponent
