import React, { useEffect, useState } from 'react'
import ButtonComponentOutlined from '../../components/UIComponents/components/buttons/buttonOutlined'
import ButtonComponentContained from '../../components/UIComponents/components/buttons/buttonContained'

const FormComponent = ({
    // props here
    title,
    description,
    onCancel = () => {},
    onSubmit = () => {},
    children
}) => {
    const [loading, setLoading]=useState(false)

    useEffect(()=>{
        setLoading(true)
    },[onSubmit])
  return (
    <div className='p-4 flex flex-col'>
        <div className='pb-2'>
            <div className='text-2xl font-bold'>{title}</div>
            <div className='text-sm'>{description}</div>
        </div>
        <hr className='text-gray'/>

        <div className='flex flex-col gap-4 pt-8'>
            <div className='flex flex-col gap-4'>
                {children}
            </div>

            <div className='flex flex-row gap-2 justify-end'>
                <ButtonComponentOutlined
                    title='Cancel'
                    onClick={onCancel}
                />
                <ButtonComponentContained
                      title='Submit'
                      disabled={!loading}
                      onClick={onSubmit} startIcon={undefined} endIcon={undefined} children={undefined}                />
            </div>
        </div>
    </div>
  )
}

export default FormComponent