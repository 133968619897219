import * as React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function FolderListComponent({ data = [] }) {
  return (
    <List
      style={{
        padding: 0
      }}
    >
      {data.map((item, index) => (
        <ListItem
          key={index}
          style={{
            padding: 4,
            borderBottom: '1px solid #f1f0f0',
            boxShadow: '0px 1px 0px 0px #f1f0f0'
          }}
        >
          <ListItemAvatar
            style={{
              backgroundColor: item.color,
              borderRadius: '100%',
              height: 56
            }}
          >
            <div className="flex items-center justify-center pt-5">
              <FontAwesomeIcon icon={item.icon} />
            </div>
          </ListItemAvatar>
          <ListItemText primary={item.primary} secondary={item.secondary} />
        </ListItem>
      ))}
    </List>
  )
}
