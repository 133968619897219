import React, { useEffect, useState } from 'react'

import moment from 'moment'
import { connect, useDispatch, useSelector } from 'react-redux'

import { upload_file } from '../../../api/calls/upload_file'
import { setNotification } from '../../../asyncActions'
import { DATE_FORMAT } from '../../../constants/constants'
import {
  initialTransactionValues,
  paymentTransactionTypes,
  paymentTypes,
  recurringTypes,
  transactionTypes
} from '../constants'
import { faSave, faTimes, faWarning } from '@fortawesome/pro-light-svg-icons'
import { save_transaction } from '../../../api/calls'
import BackDropComponent from '../../UIComponents/components/form/backdrop'
import SingleSelectComponent from '../../UIComponents/components/select/singleSelect'
import ButtonComponentOutlined from '../../UIComponents/components/buttons/buttonOutlined'
import LoaderControl from '../../../controls/loader/loader'
import ButtonComponentContained from '../../UIComponents/components/buttons/buttonContained'
import TextFieldComponent from '../../UIComponents/components/textfield/textfiled'
import DatePickerComponent from '../../UIComponents/components/calendar/date'
import FileUploadButton from '../../UIComponents/components/buttons/fileUploadButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { validateForm } from '../../studentApplication/form/components/constants'

const validationData = [
  {
    name: 'program_id',
    required: true
  },
  {
    name: 'transaction_type',
    required: true
  },
  {
    name: 'value',
    required: true
  },
  {
    name: 'payment_type',
    required: true
  },
  {
    name: 'recurring',
    required: true
  },
  {
    name: 'date',
    required: true
  }
]

const AddTransactionComponent = ({ open = false, setShow, user, programs }) => {
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState(initialTransactionValues)
  const [selectedTypeError, setSelectedTypeError] = useState('')
  const [canSaveForm, setCanSaveForm] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (user?._id) _onFormValueChange({ user_id: user._id })
  }, [user, programs])

  const _onFormValueChange = value => {
    setFormValues({ ...formValues, ...value })
  }

  const _submit = async () => {
    await save_transaction(formValues).then(res => {
      if (res.data.success) {
        dispatch(
          setNotification({
            title: 'Success',
            messages: ['You have successfully saved a transaction.']
          })
        )
      }

      setLoading(false)
      setShow(false)
    })
  }

  useEffect(() => {
    if (formValues?.recurring === 'No') {
      delete formValues.recurring_start
    }

    if (formValues.transaction_type === 'SEEDLINGS') {
      formValues.value.unit_type = 'Kg'
    } else if (formValues.transaction_type === 'SQM') {
      formValues.value.unit_type = 'Meters'
    }
  }, [formValues])

  const filterTransactionTypes = selectedType => {
    let program
    if (selectedType === 'SEEDLINGS' || selectedType === 'SQM') {
      program = programs?.find(x => x._id === formValues?.program_id)
      const found = program?.program_targets.find(
        x => x.target_name.toUpperCase() === selectedType.toUpperCase()
      )

      if (found) {
        setSelectedTypeError('')
      } else {
        setSelectedTypeError('This program does not have the selected target')
      }
    } else {
      setSelectedTypeError('')
    }
  }

  useEffect(() => {
    const valid = validateForm(validationData, formValues)
    setCanSaveForm(valid)
  }, [formValues])

  return (
    <div>
      <BackDropComponent isOpen={open} onBackDropClose={() => setShow(false)}>
        <div className="w-full flex flex-col gap-2">
          <div className="grid grid-cols-3 gap-2">
            <SingleSelectComponent
              required={true}
              title="Select Program"
              errorMessage={'Programmes Not Found'}
              displayKey="program_name"
              data={programs}
              onClick={program => {
                _onFormValueChange({
                  program_id: program._id
                })
              }}
            />
            <SingleSelectComponent
              title="Select transaction type"
              errorMessage={'Transaction Types Not Found'}
              required={true}
              displayKey="name"
              data={transactionTypes}
              onClick={item => {
                filterTransactionTypes(item.id)
                _onFormValueChange({
                  transaction_type: item.id
                })
              }}
            />
            <SingleSelectComponent
              title="Select payment type"
              errorMessage={'Payment Types Not Found'}
              required={true}
              displayKey="name"
              data={paymentTransactionTypes}
              onClick={item => {
                _onFormValueChange({
                  type: item.id
                })
              }}
            />
          </div>
          <TextFieldComponent
            label="Amount"
            type={'number'}
            required={true}
            value={formValues?.value?.amount}
            onChange={v =>
              _onFormValueChange({
                value: { amount: v }
              })
            }
          />
          <div className="grid grid-cols-2 gap-2">
            <SingleSelectComponent
              required={true}
              title="Select payment type"
              errorMessage={'Payment Types Not Found'}
              displayKey="name"
              data={paymentTypes}
              onClick={item => {
                _onFormValueChange({
                  payment_type: item.id
                })
              }}
            />
            <SingleSelectComponent
              title="Select Recurring Type"
              errorMessage={'Recurring Types Not Found'}
              displayKey="name"
              data={recurringTypes}
              onClick={item => {
                _onFormValueChange({
                  recurring: item.id
                })
              }}
            />
          </div>

          <div className="grid grid-cols-2 gap-2">
            {formValues.recurring && formValues.recurring !== 'No' && (
              <DatePickerComponent
                title="Recurring Date"
                value={formValues.recurring_start}
                onChange={date => {
                  _onFormValueChange({
                    recurring_start: moment(date).format(DATE_FORMAT)
                  })
                }}
              />
            )}
            <DatePickerComponent
              title="Transaction Date"
              value={formValues.date}
              onChange={date => {
                _onFormValueChange({
                  date: moment(date).format(DATE_FORMAT)
                })
              }}
            />
          </div>

          <FileUploadButton
            title={'Proof of transaction'}
            onChange={v => {
              upload_file({ file: v, name: v.name }).then(res => {
                if (res.data.success) {
                  _onFormValueChange({
                    proof: {
                      name: v.name,
                      url: res.data.content.location,
                      etag: res.data.content.etag
                    }
                  })
                }
              })
            }}
          />

          <hr className="text-gray" />
          {selectedTypeError && (
            <div className="flex gap-2 justify-center">
              <FontAwesomeIcon icon={faWarning} color="red" />
              <div className="text-red-500 text-sm font-sans font-bold">{selectedTypeError}</div>
            </div>
          )}
        </div>

        <div className="pt-8 flex justify-end gap-2">
          <ButtonComponentOutlined
            title={'Cancel'}
            startIcon={faTimes}
            onClick={() => {
              setShow(false)
            }}
            bgColor="#f00"
          />

          <ButtonComponentContained
            disabled={!canSaveForm}
            title={'Submit'}
            startIcon={faSave}
            onClick={() => {
              setLoading(true)
              _submit()
            }}
            loading={loading}
          />
        </div>
      </BackDropComponent>
      <LoaderControl />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    mainState: state.mainState,
    assetState: state.assetState,
    programState: state.programState
  }
}

export default connect(mapStateToProps)(AddTransactionComponent)
