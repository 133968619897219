import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { setGreenlightState } from '../../actions'
import { deleteGreenlightSurvey, getAllGreenLightSurveys } from '../../asyncActions'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import { selectNotificationState } from '../notifications/selectors'
import { greenlightSurveyListColumns } from './constants'
import { selectGreenlightSurveys, selectGreenlightSurveysLoaded } from './selectors'
import { faPen } from '@fortawesome/pro-light-svg-icons'
import TableComponent from '../UIComponents/components/tables/table'
import ConfirmationModal from '../customComponents/confirmationModal'
import ButtonComponentOutlined from '../UIComponents/components/buttons/buttonOutlined'
import CreateGreenlightSurveyComponent from './components/createGreenlightSurvey'

const GreenlightSurveys = props => {
  const dispatch = useDispatch()
  const [selectedGreenlightSurvey, setSelectedGreenlightSurvey] = useState(false)
  const greenlightSurveysLoaded = useSelector(selectGreenlightSurveysLoaded)
  const greenlightSurveys = useSelector(selectGreenlightSurveys)
  const notificationData = useSelector(selectNotificationState)
  const [isModalOpen, setModalOpen] = useState(false)
  const [showAddGreenlightSurvey, setShowAddGreenlightSurvey] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  useEffect(() => {
    if (!greenlightSurveysLoaded) dispatch(getAllGreenLightSurveys())
  }, [greenlightSurveysLoaded])

  const _toggleAddGreenlightSurveys = () => {
    // history.push('/dashboard/create-greenlight-survey')
    setIsEdit(false)
    setShowAddGreenlightSurvey(true)
  }

  return (
    <TableContentWrapper title={'Greenlight Surveys'} notificationData={notificationData}>
      {/* ----------Table */}
      <TableComponent
        data={greenlightSurveys}
        displayKeys={greenlightSurveyListColumns}
        onRowClick={null}
        actions={[
          {
            label: 'Edit',
            icon: faPen,
            action: item => {
              setSelectedGreenlightSurvey(item)
              setIsEdit(true)
              setShowAddGreenlightSurvey(true)
              // history.push(`/dashboard/greenlight-survey-info/${item._id}`)
            }
          },
          {
            label: 'Delete',
            icon: faPen,
            action: item => {
              setSelectedGreenlightSurvey(item)
              setModalOpen(true)
            }
          }
        ]}
      >
        <ButtonComponentOutlined
          title="Create Greenlight Survey"
          startIcon={faPen}
          onClick={_toggleAddGreenlightSurveys}
        />
      </TableComponent>

      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={() => {
          dispatch(deleteGreenlightSurvey(selectedGreenlightSurvey))
        }}
        title="Delete Greenlight Survey"
        description="Are you sure you want to delete this greenlight survey? Please note that this action is irreversible."
        confirmationText={selectedGreenlightSurvey?.name}
        cancelButtonText="Cancel"
      />

      <CreateGreenlightSurveyComponent
        isEdit={isEdit}
        open={showAddGreenlightSurvey}
        setShow={setShowAddGreenlightSurvey}
        data={selectedGreenlightSurvey}
        onSuccess={() => {
          setShowAddGreenlightSurvey(false)
          setIsEdit(false)
          dispatch(getAllGreenLightSurveys())
        }}
      />
    </TableContentWrapper>
  )
}

export default GreenlightSurveys
