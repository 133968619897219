import React, { useEffect, useState } from 'react'

import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getPrograms } from '../../asyncActions'
import { formatDate } from '../../utils/utils'
import CreateProgrammeComponent from './components/addProgram'
import ConfirmationModal from '../customComponents/confirmationModal'
import { delete_program } from '../../api/calls/programs/delete_program'
import TableComponent from '../UIComponents/components/tables/table'
import { faPen, faTrash } from '@fortawesome/pro-light-svg-icons'
import ButtonComponentContained from '../UIComponents/components/buttons/buttonContained'
import moment from 'moment'

const ProgramsComponent = ({ programsState }) => {
  const history = useHistory()

  const [createProgramme, setCreateProgramme] = useState(false)
  const dispatch = useDispatch()
  const [selectedProgram, setSelectedProgram] = useState({})
  const [tableDate, setTableData] = useState([])
  const [isModalOpen, setModalOpen] = useState(false)

  const handleConfirm = async () => {
    await delete_program(selectedProgram._id).then(res => {
      if (res.data.success) {
        dispatch(getPrograms())
        setModalOpen(false)
      }
    })
  }

  useEffect(() => {
    if (!programsState.programsLoaded) {
      dispatch(getPrograms())
    }
    let data = []
    data = getTableData()
    data.length > 0 ? setTableData(data) : setTableData([])
  }, [programsState])

  const getTableData = () => {
    let data = []
    if (programsState?.programs)
      for (let program of programsState?.programs) {
        data.push({
          ...program,
          startDate: moment(program.start_date).format('LL'),
          endDate: moment(program.end_date).format('LL')
        })
      }

    return data
  }

  return (
    <div className="flex-col flex">
      <TableComponent
        title="Programmes"
        search={true}
        displayKeys={[
          { value: 'program_name', label: 'Name' },
          { value: 'startDate', label: 'Start Date' },
          { value: 'endDate', label: 'End Date' }
        ]}
        data={tableDate}
        onRowClick={item => {
          console.log('item', item)
          history.push(`program-details/${item._id}`)
        }}
        actions={[
          {
            label: 'Edit',
            action: item => {
              setSelectedProgram(item)
              setCreateProgramme(true)
            },
            icon: faPen
          },
          {
            label: 'Delete',
            action: item => {
              setSelectedProgram(item)
              setModalOpen(true)
            },
            icon: faTrash
          }
        ]}
      >
        <ButtonComponentContained
          title="Create Programme"
          onClick={() => {
            setSelectedProgram({})
            setCreateProgramme(true)
          }}
          startIcon={faPen}
          endIcon={undefined}
        />
      </TableComponent>

      <CreateProgrammeComponent
        open={createProgramme}
        program={selectedProgram}
        setShow={setCreateProgramme}
      />

      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handleConfirm}
        title="Delete Programme"
        description="Are you sure you want to delete this programme? Please note that this action is irreversible."
        confirmationText={selectedProgram.program_name}
        cancelButtonText="Cancel"
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    facilitatorState: state.facilitatorState,
    programsState: state.programsState,
    alertState: state.alertState,
    userState: state.userState
  }
}

export default connect(mapStateToProps)(ProgramsComponent)
