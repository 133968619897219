import { Paper } from '@material-ui/core'
import React, { useEffect } from 'react'
import TextFieldComponent from '../../../UIComponents/components/textfield/textfiled'
import { set } from 'date-fns'
import SingleSelectComponent from '../../../UIComponents/components/select/singleSelect'
import RadioButtonComponent from '../../../UIComponents/components/radio/radioButtton'
import CountrySelect from '../../../UIComponents/components/autoselect/countrySelectAutocomplete'
import { personalValidationData, validateForm } from './constants'

const PersonalInformation = ({ onChange = () => {}, formData = {}, onValidate = e => {} }) => {
  const [formValues, setFormValues] = React.useState({ ...formData, disabled: false })

  const handleChange = e => {
    setFormValues({ ...formValues, ...e })
  }

  useEffect(() => {
    onChange(formValues)
    const valid = validateForm(personalValidationData, formValues)
    console.log('valid', valid)
    onValidate(valid)
  }, [formValues])

  return (
    <div className="flex flex-col gap-4">
      <Paper className="p-4 flex flex-col gap-4">
        <div className="font-sans font-extrabold text-2xl">Personal Information</div>
        <div className="grid grid-cols-2 gap-4">
          <TextFieldComponent
            label="Name"
            value={formValues?.name}
            onChange={e => {
              handleChange({ name: e })
            }}
            size="medium"
            required={true}
            helperText={undefined}
          />
          <TextFieldComponent
            label="Surname"
            value={formValues?.surname}
            onChange={e => {
              handleChange({ surname: e })
            }}
            size="medium"
            required={true}
            helperText={undefined}
          />

          <TextFieldComponent
            label="ID Number"
            required={true}
            value={formValues?.id_number}
            onChange={e => {
              handleChange({ id_number: e })
            }}
            size="medium"
            type="number"
            helperText={undefined} // required={true}
          />
          <TextFieldComponent
            label="Training Provider"
            value={formValues?.training_provider}
            onChange={e => {
              handleChange({ training_provider: e })
            }}
            size="medium"
            required={true}
            helperText={undefined}
          />

          <TextFieldComponent
            label="Municipality"
            value={formValues?.municipality}
            onChange={e => {
              handleChange({ municipality: e })
            }}
            size="medium"
            required={true}
            helperText={undefined}
          />
          <TextFieldComponent
            label="Age"
            value={formValues?.age}
            onChange={e => {
              handleChange({ age: e })
            }}
            size="medium"
            type="number"
            required={true}
            helperText={undefined}
          />

          <SingleSelectComponent
            displayKey={'name'}
            title={'Please select applicable race'}
            required={true}
            data={[
              { name: 'Black', value: 'Black' },
              { name: 'Coloured', value: 'Coloured' },
              { name: 'Indian', value: 'Indian' },
              { name: 'White', value: 'White' },
              { name: 'Asian', value: 'Asian' }
            ]}
            onClick={e => {
              handleChange({
                race: e.value
              })
            }}
          />

          <RadioButtonComponent
            required={true}
            title={'Gender'}
            color={'black'}
            displayKey={'label'}
            row={true}
            selectedValue={formValues?.gender}
            buttons={[
              { value: 'Male', label: 'Male' },
              { value: 'Female', label: 'Female' },
              { value: 'Other', label: 'Other' }
            ]}
            onClick={e => {
              handleChange({
                gender: e.value
              })
            }}
          />

          <CountrySelect
            required={true}
            onChange={e => handleChange({ nationality: e })}
            title={'Country of Birth'}
          />

          <div className="grid grid-cols-2 gap-2">
            <RadioButtonComponent
              required={true}
              title={'Disability'}
              color={'black'}
              displayKey={'label'}
              row={true}
              selectedValue={formValues?.disabled ?? formValues?.disabled ? 'Yes' : 'No'}
              buttons={[
                { value: true, label: 'Yes' },
                { value: false, label: 'No' }
              ]}
              onClick={e => {
                handleChange({
                  disabled: e.value
                })
              }}
            />
            {formValues.disabled && (
              <TextFieldComponent
                label="Add disabilities (sep. by comma)"
                value={formValues?.disability}
                onChange={e => {
                  handleChange({ disability: e })
                }}
                size="medium"
                required={true}
                helperText={undefined}
              />
            )}
          </div>
        </div>
      </Paper>
    </div>
  )
}

export default PersonalInformation
