const entityName = 'Entity'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { selectNotificationState } from '../../notifications/selectors'
import { faArrowLeft, faUser, faUserGroup } from '@fortawesome/pro-light-svg-icons'
import { saveBilling, getFacilitatorTransactions } from '../../../asyncActions'
import { getEntityPrograms, getEntityTransactions } from '../../../asyncActions/entities'
import { selectSelectedUser } from '../../../selectors/selectors'
import { extractById } from '../../../utils/entityUtils'
import { getTransactionTableData } from '../../facilitators/utils'
import { initialRateValues, programColumn, transactionsColumn } from '../../user/constants'
import { selectEntities } from '../selectors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BreadCrumb from '../../../controls/breadcrumb/breadcrumb'
import ButtonControl from '../../../controls/button/button'
import TitleCardControl from '../../../controls/cards/titlecard'
import EntityTable from '../../../controls/entityTable/entityTable'
import TableContentWrapper from '../../../controls/tableContentWrapper/tableContentWrapper'
import AddRateModal from '../../facilitators/modals/addRate'
import ValueExchange from '../../user/components/valueExchange'
import AddPaymentComponent from '../../user/components/addPayment'
import AddTransactionComponent from '../../user/components/addTransactions'
import AddDocumentComponent from '../../user/components/UploadDocuments'
import { connect, useDispatch, useSelector } from 'react-redux'
import DetailsBlock from '../../user/components/detailsBlock'
import Pills from './pills'
import { MyTableControl } from '../../../controls/myTableControl/myTableControl'
import { Link } from '@material-ui/core'
import TableComponent from '../../UIComponents/components/tables/table'
import { get_entity_programs } from '../../../api/calls/programs/get_entity_programs'

const EntityDetails = props => {
  const { id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const notificationData = useSelector(selectNotificationState)
  const [showAddPayment, setShowAddPayment] = useState(false)
  const [showAddRate, setShowAddRate] = useState(false)
  const [showAddDocument, setShowAddDocument] = useState(false)
  const [selectedVebProgram, setSelectedVebProgram] = useState()
  const [programs, setPrograms] = useState([])

  const selectedUser = useSelector(selectSelectedUser)

  const entities = useSelector(selectEntities)
  const entity = extractById(entities)(selectedUser._id)

  const transactionsTableData = getTransactionTableData(entity?.transactions)

  const [formValues] = useState({
    ...initialRateValues,
    user_id: id
  })

  useEffect(() => {
    if (selectedUser?._id) {
      if (entity?.programs && !entity?.transactions) {
        dispatch(getEntityTransactions(entity))
      }
    } else {
      history.goBack()
    }
  }, [entity, selectedUser])

  // useEffect(() => {
  //   if (selectedUser?._id) {
  //     dispatch(getEntityPrograms(selectedUser))
  //   }
  // }, [])

  useEffect(() => {
    console.log('entity', entity)
    console.log('entites', entities)

    if (entity) {
      onGetEntityPrograms(selectedUser)
    }
  }, [entities, entity])

  const _onViewProgram = item => {
    history.push(`/dashboard/program-details/${item._id}`)
  }

  const _onEditRate = () => {
    setShowAddRate(!showAddRate)
  }

  const _onViewTeachingDays = () => {}

  const _onSubmitHandler = values => {
    entity['billingInfo'] = values
    dispatch(saveBilling(values))
  }

  const _onSelectVebProgram = item => {
    setSelectedVebProgram(item)
    dispatch(getFacilitatorTransactions(entity, item))
  }

  const onGetEntityPrograms = async user => {
    await get_entity_programs(user._id).then(res => {
      console.log('res', res.data.content)
      if (res.data.success) {
        setPrograms(res.data.content?.programs)
      }
    })
  }

  return (
    <TableContentWrapper
      title={`${entityName} - ${entity?.user_name} ${entity?.user_surname}`}
      notificationData={notificationData}
    >
      <div className="flex flex-col gap-4">
        <div className="flex gap-4">
          <div className="flex flex-1 flex-col gap-2">
            <TitleCardControl css={{ height: '150px' }}>
              <div className="grid grid-cols-2 gap-8">
                <div className="flex flex-col gap-4 justify-between">
                  <div className="flex flex-row space-x-8">
                    <div
                      className="p-4 flex bg-indigo rounded-full justify-center"
                      style={{ height: '100px', width: '100px' }}
                    >
                      <FontAwesomeIcon
                        className="fa-4x text-white ml-8 mr-8 align-baseline"
                        icon={faUser}
                      />
                    </div>
                    <div className="mx-10 items-start flex flex-col justify-between">
                      <div className="font-extrabold text-lg font-sans">{`${entity?.user_name} ${entity?.user_surname}`}</div>
                      <div className="text-sm text-gray">{entity?.user_email}</div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-end">
                  <Pills data={entity?.account} />
                </div>
              </div>
            </TitleCardControl>
            <TableComponent
              title={'Programmes'}
              data={programs ?? []}
              displayKeys={programColumn}
            />
            <TableComponent
              title={'Transactions'}
              data={transactionsTableData ?? []}
              displayKeys={[
                { value: 'date', date: true, label: 'Date' },
                { value: 'transaction_type', label: 'Transaction type' },
                { value: 'payment_type', label: 'Payment type' },
                { value: 'amount', label: 'Amount' }
              ]}
            />
          </div>
          {entity && <DetailsBlock data={entity} addRate={_onEditRate} userType={undefined} />}
        </div>
      </div>
    </TableContentWrapper>
  )
}

export default EntityDetails
