import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setVenuesState } from '../../actions'
import { deleteVenue, getVenues } from '../../asyncActions'
import { useFormActionHook } from '../../hooks/useFormActionHook'
import { selectNotificationState } from '../notifications/selectors'
import { initialVenueValues, venueColumns, venueValidationConfig } from './constants'
import { selectVenues, selectVenuesLoaded } from './selectors'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import { faPen, faScissors } from '@fortawesome/pro-light-svg-icons'
import CreateUpdateVenueComponent from './components/addEditVenue'
import TableComponent from '../UIComponents/components/tables/table'
import ButtonComponentOutlined from '../UIComponents/components/buttons/buttonOutlined'
import ConfirmationModal from '../customComponents/confirmationModal'

const Venues = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [showAddVenues, setShowAddVenues] = useState(false)
  const [selectedVenue, setSelectedVenue] = useState({})
  const venuesLoaded = useSelector(selectVenuesLoaded)
  const venues = useSelector(selectVenues, shallowEqual)
  const notificationData = useSelector(selectNotificationState)
  const [isEdit, setIsEdit] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (!venuesLoaded) dispatch(getVenues())
  }, [venuesLoaded])

  return (
    <TableContentWrapper notificationData={notificationData}>
      {/* ----------Table */}
      <TableComponent
        title={'Venues'}
        data={venues}
        displayKeys={venueColumns}
        onRowClick={e => {
          history.push(`venue-details/${item._id}`)
        }}
        actions={[
          {
            label: 'Edit',
            icon: faPen,
            action: item => {
              setSelectedVenue(item)
              setIsEdit(true)
              setShowAddVenues(true)
            }
          },
          {
            label: 'Delete',
            icon: faScissors,
            action: item => {
              setSelectedVenue(item)
              setModalOpen(true)
            }
          }
        ]}
      >
        <ButtonComponentOutlined
          title="Add a New Venue"
          startIcon={faPen}
          onClick={() => {
            setSelectedVenue({})
            setIsEdit(false)
            setShowAddVenues(true)
          }}
        />
      </TableComponent>
      <CreateUpdateVenueComponent
        isEdit={isEdit}
        open={showAddVenues}
        setShow={setShowAddVenues}
        formData={selectedVenue}
      />

      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={() => {
          dispatch(deleteVenue(selectedVenue))
          dispatch(
            setVenuesState({
              venues: venues.filter(venue => venue._id !== selectedVenue._id)
            })
          )
        }}
        title="Delete Venue"
        description="Are you sure you want to delete this venue? Please note that this action is irreversible."
        confirmationText={selectedVenue?.name}
        cancelButtonText="Cancel"
      />
    </TableContentWrapper>
  )
}

export default Venues
