import React, { useEffect, useState } from 'react'
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core'

export default function SingleSelectComponent({
  onClick = () => {},
  data = [],
  errorMessage,
  title,
  displayKey,
  color,
  required = false,
  disabled = false,
  value = ''
}) {
  const [selectedValue, setSelectedValue] = useState({})
  const selectedItem = data.find(item => item.value === value) ?? {}

  const handleSelect = item => {
    setSelectedValue(item)
    onClick(item)
  }

  return (
    <FormControl
      fullWidth={true}
      variant="outlined"
      required={required}
      style={{ color: color || undefined }}
      disabled={disabled}
    >
      <InputLabel style={{ color: color || undefined }}>
        {data.length === 0 ? errorMessage ?? 'No Items Found' : title}
      </InputLabel>
      <Select
        value={selectedValue[displayKey] || selectedItem[displayKey] || ''}
        style={{ height: 40, color: color || undefined }}
      >
        {data.length > 0 ? (
          data.map((item, index) => (
            <MenuItem key={index} value={item[displayKey]} onClick={() => handleSelect(item)}>
              {item[displayKey]}
            </MenuItem>
          ))
        ) : (
          <MenuItem value="">
            <em>No items found</em>
          </MenuItem>
        )}
      </Select>
    </FormControl>
  )
}
