import React, { useEffect, useState } from 'react'
import { Box, Stepper, Step, StepButton, Button, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Cookies from 'universal-cookie'
import { save_application } from '../../../api/calls'
import { get_application_events } from '../../../api/calls/tasks/get_applicatio_events'
import { setAlert } from '../../../asyncActions/alert'
import AddressInformation from '../address'
import ContactInformation from './components/contact'
import Disclaimer from './components/disclaimer'
import GroupsEventsInformation from './components/groupsEvents'
import PersonalInformation from './components/personal'
import { Paper } from '@material-ui/core'
import logo from '../../../assets/images/black_logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'
import { get_groups } from '../../../api/calls/studentApplications/get_groups'

const steps = [
  'Personal Information',
  'Groups and Events',
  'Contact Information',
  'Address Information',
  'Disclaimer'
]

export default function ApplicationForm() {
  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState({})
  const [validated, setValidated] = useState([])
  const [formValues, setFormValues] = useState({})
  const [groups, setGroups] = useState([])
  const [events, setEvents] = useState([])
  const dispatch = useDispatch()
  const cookies = new Cookies()
  const history = useHistory()

  useEffect(() => {
    // Fetch groups
    get_groups().then(e => {
      if (e.data.success) {
        setGroups(e.data.content)
      }
    })

    // Fetch events
    get_application_events().then(e => {
      if (e.data.success) {
        setEvents(e.data.content)
      }
    })
  }, [])

  const totalSteps = () => steps.length

  const allStepsCompleted = () => {
    return Object.keys(completed).length === totalSteps()
  }

  const handleNext = () => {
    setActiveStep(prev => (prev === totalSteps() - 1 ? prev : prev + 1))
  }

  const handleBack = () => {
    setActiveStep(prev => (prev > 0 ? prev - 1 : prev))
  }

  const handleStep = step => () => {
    setActiveStep(step)
  }

  const handleValidate = step => {
    setValidated(prev => [...prev, step])
  }

  const handleComplete = () => {
    setCompleted(prev => ({
      ...prev,
      [activeStep]: true
    }))
    handleNext()
  }

  const handleReset = () => {
    setActiveStep(0)
    setCompleted({})
  }

  const handleChange = e => {
    setFormValues(prev => ({ ...prev, ...e }))
  }

  const onFormSubmit = async () => {
    try {
      const res = await save_application(formValues)
      if (res.data.success) {
        dispatch(
          setAlert({
            title: 'Success',
            messages: [res.data.message]
          })
        )
        if (cookies.get('veb-user-cookie')?.token) {
          history.push('/dashboard/application-portal')
        } else {
          history.replace('/thank-you')
        }
      } else {
        dispatch(
          setAlert({
            title: 'Error',
            messages: [res.data.message]
          })
        )
      }
    } catch {
      dispatch(
        setAlert({
          title: 'Error',
          messages: ['An error occurred while saving the application']
        })
      )
    }
  }

  useEffect(() => {
    console.log('formValues', formValues)
  }, [formValues])

  return (
    <div className="p-8 flex flex-col h-screen w-full px-52">
      <div className="flex flex-col">
        <img
          onClick={() => history.goBack()}
          src={logo}
          className="object-contain h-20 pb-8 pt-4"
        />
        <div className="flex items-center pb-8">
          <FontAwesomeIcon
            className="text-2xl cursor-pointer"
            icon={faArrowLeft}
            onClick={() => history.goBack()}
          />

          <div className="font-sans font-extrabold text-2xl w-full flex justify-center">
            Application Form
          </div>
        </div>
      </div>
      <div className="h-full pt-12">
        <Box sx={{ width: '100%' }}>
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton color="inherit">{label}</StepButton>
              </Step>
            ))}
          </Stepper>
          <div>
            {allStepsCompleted() ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you're finished</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="pt-8">
                  {activeStep === 0 && (
                    <PersonalInformation
                      onChange={handleChange}
                      formData={formValues}
                      onValidate={isValid => isValid && handleValidate(activeStep)}
                    />
                  )}
                  {activeStep === 1 && (
                    <GroupsEventsInformation
                      onChange={handleChange}
                      formData={formValues}
                      groups={groups}
                      events={events}
                      onValidate={isValid => isValid && handleValidate(activeStep)}
                    />
                  )}
                  {activeStep === 2 && (
                    <ContactInformation
                      onChange={handleChange}
                      formData={formValues}
                      onValidate={isValid => isValid && handleValidate(activeStep)}
                    />
                  )}
                  {activeStep === 3 && (
                    <AddressInformation
                      onChange={handleChange}
                      formData={formValues}
                      onValidate={isValid => isValid && handleValidate(activeStep)}
                    />
                  )}
                  {activeStep === 4 && <Disclaimer />}
                </div>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {activeStep === steps.length - 1 ? (
                    <Button onClick={onFormSubmit}>Finish</Button>
                  ) : validated.includes(activeStep) ? (
                    <Button onClick={handleComplete} sx={{ mr: 1 }}>
                      Complete Step
                    </Button>
                  ) : (
                    <Button disabled onClick={handleComplete} sx={{ mr: 1 }}>
                      Complete Step
                    </Button>
                  )}
                </Box>
              </React.Fragment>
            )}
          </div>
        </Box>
      </div>
    </div>
  )
}
