import React, { useEffect, useState } from 'react'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { getLearners } from '../../../asyncActions/learners'
import TitleCardControl from '../../../controls/cards/titlecard'
import NotificationControl from '../../../controls/notification/notification'
import TableControl from '../../../controls/table/table'
import { selectNotificationState } from '../../notifications/selectors'
import { getGroups, removeLearnerFromGroup } from '../../../asyncActions/groups'
import { getFacilitators } from '../../../asyncActions/facilitators'
import { formatDate } from '../../../utils'
import TableContentWrapper from '../../../controls/tableContentWrapper/tableContentWrapper'
import ButtonControl from '../../../controls/button/button'
import AddLearnerToGroupComponent from './addLearnerToGroup'
import { initialPageNumber } from '../../../constants/constants'
import { selectPaginateState } from '../../../selectors/selectors'
import { actionButtonItems, facilitatorsColumn } from '../../user/constants'
import { setMainState } from '../../../actions'
import { selectLearners, selectLearnersLoaded } from '../../learner/selectors'
import { add_learner } from '../../../api/calls/add_learner'
import { setNotification } from '../../../asyncActions/notification'
import { MyTableControl } from '../../../controls/myTableControl/myTableControl'
import ButtonComponent from '../../customComponents/button'
import InformationBlock from './informationBlock'
import { get_group } from '../../../api/calls/groups/get_group'
import TableComponent from '../../UIComponents/components/tables/table'
import { faUserGroup } from '@fortawesome/pro-light-svg-icons'

const GroupDetailsComponent = ({ learnerState, groupState, facilitatorState }) => {
  const history = useHistory()
  const notificationData = useSelector(selectNotificationState)
  const [showAddLearner, setShowAddLearner] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState({})
  const [facilitators, setFacilitators] = useState([])
  const [learners, setLearners] = useState([])
  const { id } = useParams()
  const [details, setDetails] = useState({})
  const learnersState = useSelector(selectLearners).map(_ => ({
    ..._,
    fullName: `${_?.user_name} ${_?.user_surname}`
  }))

  const _onGetGroupDetails = async () => {
    await get_group({
      id: id
    }).then(response => {
      if (response.data.success) {
        const learners = response.data.content?.learners
        const facilitators = response.data.content?.facilitators

        setLearners(learners)
        setFacilitators(facilitators)

        delete response.data.content?.learners
        delete response.data.content?.Facilitators

        setSelectedGroup(response.data.content)
      }
    })
  }

  useEffect(() => {
    _onGetGroupDetails()
  }, [])

  return (
    <TableContentWrapper title={selectedGroup?.name}>
      <Link
        to="/dashboard/groups"
        className="mt-4 mb-4 text-green hover:underline focus:outline-none cursor-pointer"
      >
        <FontAwesomeIcon className="text-sm" icon={faArrowLeft} /> Back to all groups
      </Link>

      <div className="flex flex-col gap-4">
        <div className="flex gap-4">
          <div className="flex flex-1 flex-col gap-2">
            <TitleCardControl css={{ height: '150px' }}>
              <div className="flex flex-col gap-4 justify-between">
                <div className="flex flex-row gap-8">
                  <div
                    className="p-4 flex bg-indigo rounded-full justify-center"
                    style={{ height: '100px', width: '100px' }}
                  >
                    <FontAwesomeIcon
                      className="fa-4x text-white ml-8 mr-8 align-baseline"
                      icon={faUserGroup}
                    />
                  </div>
                  <div className="flex flex-col justify-center gap-2">
                    <div className="font-extrabold text-lg font-sans">{selectedGroup?.name}</div>
                    <div className="text-sm text-gray">{selectedGroup?.allocation_code}</div>
                  </div>
                </div>
              </div>
            </TitleCardControl>
            <TableComponent
              title={'Learners'}
              showPagination={true}
              data={learners ?? []}
              displayKeys={[
                { value: 'user_name', label: 'Name' },
                { value: 'user_surname', label: 'Surname' },
                { value: 'user_cell_number', label: 'Phone Number' },
                { value: 'user_email', label: 'Email Address' }
              ]}
              onRowClick={e => {
                console.log('e', e)
                history.replace(`/dashboard/learner-info/${e._id}/LEARNER-ACC`)
              }}
            />

            <TableComponent
              title={'Facilitators'}
              data={facilitators ?? []}
              displayKeys={[
                { value: 'user_name', label: 'Name' },
                { value: 'user_surname', label: 'Surname' },
                { value: 'user_cell_number', label: 'Phone Number' },
                { value: 'user_email', label: 'Email Address' }
              ]}
              onRowClick={e => {
                console.log('e', e)
                history.replace(`/dashboard/facilitator-info/${e._id}/FACILITATOR-ACC`)
              }}
            />
          </div>
          <div className="flex min-w-min max-h-full">
            <InformationBlock data={selectedGroup} />
          </div>
        </div>
      </div>
    </TableContentWrapper>
  )
}

const mapStateToProps = state => {
  return {
    learnerState: state.learnerState,
    mainState: state.mainState,
    userState: state.userState,
    groupState: state.groupState,
    facilitatorState: state.facilitatorState
  }
}

export default connect(mapStateToProps)(GroupDetailsComponent)
