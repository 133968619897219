import React, { useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Input
} from '@material-ui/core'
import { faArrowLeft, faFilePdf, faFilter } from '@fortawesome/pro-light-svg-icons'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DropdownControl from '../dropdown/dropdown'
import ButtonControl from '../button/button'
import TableContentWrapper from '../tableContentWrapper/tableContentWrapper'
import { formatDate } from '../../utils/utils'
import { get_group } from '../../api/calls/groups/get_group';
import { get_program } from '../../api/calls/programs/get_program';
import ButtonComponent from '../../components/customComponents/button';
import DropdownButtonComponent from '../../components/customComponents/dropdownButton';

const ITEMS_PER_PAGE = 10
const defaultSearchValue = {
  show: false,
  search_by: ''
}

export const MyTableControl = props => {
  const {
    title,
    data=[],
    search = defaultSearchValue,
    showPagination = true,
    displayKeys = [],
    actionButton = {},
    mainButton,
    type,
    onFilterClick = null
  } = props

  const [currentPage, setCurrentPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')
  const [defaultGroup, setDefaultGroup] = useState()
  const [defaultProgram, setDefaultProgram] = useState()
  const [searchByValue, setSearchByValue] = useState({})
  let filteredData = data;

  if(data.length > 0 && searchByValue!=null){
    filteredData = data?.filter(item =>
        searchByValue&&item[`${searchByValue?.key}`]?.toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
  }

  useEffect(()=>{
    if(type==='groups'){
        get_group().then(response => {
            if (response.data.success) {
                setDefaultGroup(response.data.content)
            }
        })
    }

    if(type==='programs'){
        get_program().then(response => {
            if (response.data.success) {
                setDefaultProgram(response.data.content)
            }
        })
    }
  },[type])

  const action = (idx, title) => {
    return (
      <>
        {actionButton?.isDropdown ? (
            <DropdownButtonComponent
            placeholder={title}
            dropdownItems={actionButton.items}
            displayKey="name"
            onClick={item => actionButton.onClick(filteredData[idx], item)}
          />
        ) : actionButton?.isButton ? (
          <ButtonComponent
          title={actionButton?.title}
          placeholder={actionButton?.title}
          onClick={item => actionButton.onClick(filteredData[idx], item)}
        />
        ) : null}
      </>
    )
  }

  const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE)

  const handleChangePage = page => {
    setCurrentPage(page)
  }

  useEffect(()=>{
    if(search.show && displayKeys.length > 0){
        setSearchByValue(search.search_by.length > 0 ? displayKeys.find(e => e.key === search.search_by) : displayKeys[0])
    }
    
  },[search, displayKeys])

  

  return (
    <TableContentWrapper>
      <div className="flex flex-col gap-4">
        <div className="flex justify-between">
          <div className="flex-auto text-xl font-bold">{title}</div>
          <div className="flex justify-end items-end">
            <div className="flex min-w-min">{mainButton ? mainButton : null}</div>
          </div>
        </div>

        <div className="flex gap-20">
          <div className="flex-1">
            {data.length > 0 ? (
              search.show ? (
                <div className="flex flex-col gap-2">
                  <Input
                    className="w-full"
                    placeholder={`Search by ${searchByValue.display}`}
                    value={searchQuery}
                    onChange={event => {

                        setSearchQuery(event.target.value)
                    }}
                  />
                  <div className="text-gray text-xs leading-normal">
                   {`${search.search_by < 1? ' To search by column, simply click on the column header.' : ''}`}
                  </div>
                  <br />
                </div>
              ) : (
                <div></div>
              )
            ) : null}
          </div>
        </div>

        <Table className="flex min-w-full">
          <TableHead className="bg-main text-lg">
            <TableRow>
              {actionButton != null && actionButton?.side?.toLowerCase() == 'left' ? (
                <TableCell className="text-gray-600 text-xs font-extrabold leading-normal">
                  {actionButton?.title}
                </TableCell>
              ) : null}
              {displayKeys.map((title, i) => (
                <TableCell
                    key={i}
                  onClick={() => {
                    if(search.search_by.length < 1){
                        if (!Array.isArray(filteredData[0][`${title.key}`])) {
                            setSearchByValue(title)
                        }
                    }
                   
                  }}
                  className="text-gray-600 text-sm font-bold leading-normal"
                >
                    <div className='flex flex-row gap-2 items-center'>
                    {title.display}
                  <div onClick={()=>{
                    onFilterClick(title);
                  }} >
                  {title.filter&&<FontAwesomeIcon icon={faFilter} className="w-5 h-5 text-black hover:text-green cursor-pointer" />}
                  </div>
                  </div>
                 
                </TableCell>
              ))}
              {actionButton != null && actionButton?.side?.toLowerCase() == 'right' ? (
                <TableCell className="text-gray-600 text-lg leading-normal">
                  {actionButton?.title}
                </TableCell>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody className="text-gray-600 text-sm font-normal">
            {data.length > 0 ? (
              filteredData
                .slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE)
                .map((item, idx) => {

                  return (
                    
                    <TableRow key={((currentPage - 1) * ITEMS_PER_PAGE) + item._id} className={`border-b border-gray-200 hover:bg-gray-100 border-dark font-light h-8`}>
                      {actionButton != null && actionButton?.side?.toLowerCase() == 'left' ? (
                        <TableCell className="text-gray-600 uppercase text-xs leading-normal font-light">
                            {action(((currentPage - 1) * ITEMS_PER_PAGE) + idx, (defaultGroup!=null && defaultGroup?._id==item._id) || (defaultProgram!=null && defaultProgram?._id==item._id)? "Details (Default)": actionButton.title)}
                        </TableCell>
                      ) : null}
                      {displayKeys.map((title, tInx) => {
                        return (
                            <TableCell key={tInx}
                          className="text-gray-600 text-xs leading-normal font-light"
                          onClick={() => {
                          }}
                        >
                          <div className="flex flex-col">
                            {Array.isArray(item[`${title.key}`]) ? (
                              item[`${title.key}`].map((e, i) => {
                                return <div key={e}>{e}</div>
                              })
                            ) : title.date ? (
                              formatDate(item[title['key']], item.unix, title.toLocal)
                            ) : title.link ? (
                              <a href={item[title.key]} target="_blank" className="text-green">
                                {title.link}
                              </a>
                            ) : title.isObject == true? title.objectKeys.map(e=>{
                                return <div key={e}>{item[`${title.key}`][e]}</div>
                            }): (
                              item[`${title.key}`]
                            )}
                            {/* {item[`${title.key}`]} */}
                          </div>
                        </TableCell>
                        )
                      })}
                      {actionButton != null && actionButton?.side?.toLowerCase() == 'right' ? (
                        <TableCell className="text-gray-600 w-60 uppercase text-xs leading-normal font-light">
                          {action(((currentPage - 1) * ITEMS_PER_PAGE) + idx, (defaultGroup!=null && defaultGroup?._id==item._id) || (defaultProgram!=null && defaultProgram?._id==item._id)? "Details (Default)": actionButton.title)}
                        </TableCell>
                      ) : null}
                    </TableRow>
                  )
                })
            ) : (
              <TableRow>
                <TableCell>This table is empty</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
    
        {data && 
            <div className="flex justify-center mt-5">
                <button
                    onClick={() => handleChangePage(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={`rounded-l-lg px-3 py-1 ${
                    currentPage === 1
                        ? 'bg-gray-200 cursor-default'
                        : 'bg-main hover:bg-blue-700 cursor-pointer'
                    }`}
                >
                    <FontAwesomeIcon icon={faArrowLeft} className="w-5 h-5 text-black" />
                </button>
                <div className="flex items-center bg-gray-200 px-3 py-1">
                    {currentPage} of {totalPages}
                </div>
                <button
                    onClick={() => handleChangePage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className={` rounded-r-lg px-3 py-1 ${
                    currentPage === totalPages
                        ? 'bg-gray-200 cursor-default'
                        : 'bg-main hover:bg-blue-700 cursor-pointer'
                    }`}
                >
                    <FontAwesomeIcon icon={faArrowRight} className="w-5 h-5 text-black" />
                </button>
            </div>
        }
      </div>
    </TableContentWrapper>
  )
}
