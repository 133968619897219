import React, { useEffect, useState } from 'react'
import TableComponent from '../../components/UIComponents/components/tables/table'

const AttendancePerQuarterTable = ({ title, data }) => {
  const displayKeys = [
    {
      label: 'Programme Name',
      value: 'program_name'
    },
    {
      label: 'Year',
      value: 'program_year'
    },
    {
      label: 'Quarter (Defined by Date on Programme Creation)',
      value: 'program_quarters',
      subKey: 'quarter',
      isArray: true
    },
    {
      label: 'Avg Human OI',
      value: 'program_quarters',
      subKey: 'quarter_human_oi',
      isArray: true
    },
    {
      label: 'Avg Technical OI',
      value: 'program_quarters',
      subKey: 'quarter_business_oi',
      isArray: true
    }
  ]

  return (
    <TableComponent
      title={title}
      data={data}
      displayKeys={displayKeys}
      rows={10}
      search={true}
    />
  )
}

export default AttendancePerQuarterTable
