import { apiGet, apiPost } from '../api'

export const get_attendance = user_id => {
  return apiGet({
    endpoint: `attendance/get_user_attendance?user_id=${user_id}`
  })
}

export const save_register = data => {
  return apiPost({
    endpoint: 'attendance/save_register',
    data: data
  })
}

export const save_catch_up_register = data => {
  return apiPost({
    endpoint: 'attendance/save_catch_up_register',
    data: data
  })
}

export const get_registers = programme_id => {
  return apiGet({
    endpoint: `attendance/get_registers?id=${programme_id}`
  })
}

export const get_learner_attendance_info = user_id => {
  return apiGet({
    endpoint: `attendance/learner?user_id=${user_id}`
  })
}

export const download_register_historical_data = program_id => {
  return apiGet({
    endpoint: `attendance/download_register?id=${program_id}`
  })
}
  
export const get_absent_learners = data => {
  let query
  if (data?.program?._id && !data?.group?._id) {
    query = `program_id=${data?.program?._id}${
      data.filter_date != null ? `&date_filter${data.date_filter}` : ''
    }`
  }

  if (!data?.program?._id && data?.group?._id) {
    query = `group_id=${data?.group?._id}${
      data.filter_date != null ? `&date_filter${data.date_filter}` : ''
    }`
  }

  if (data?.program?._id && data?.group?._id) {
    query = `group_id=${data?.group?._id}&program_id=${data?.program?._id}${
      data.filter_date != null ? `&date_filter${data.date_filter}` : ''
    }`
  }

  return apiGet({
    endpoint: `attendance/absent_learners?${query}${
      data.date_filter != null ? `&date_filter=${data?.date_filter}` : ''
    }`
  })
}

export const get_absenteeism = data => {
  return apiGet({
    endpoint: `attendance/get_absenteeism?user_id=${data.user_id}${
      data.date != null ? `&date=${data.date}` : ''
    }`
  })
}

export const absentee_history = () => {
  return apiGet({
    endpoint: `attendance/absentee_history`
  })
}
      
    
  