import React from 'react'
import ButtonComponentOutlined from '../UIComponents/components/buttons/buttonOutlined'
import { faDownload } from '@fortawesome/pro-light-svg-icons'

const DownloadCSV = () => {
  // Function to create the CSV content based on your data
  const createCSV = () => {
    const headers = [
      'banner',
      'stream',
      'num interactions',
      'cost',
      'codes',
      'interaction name',
      'start_date',
      'end_date',
      'description',
      'Event',
      'venue',
      'category',
      'facilitator_email'
    ]

    const data = [
      [
        'HO',
        'BETTER TOGETHER',
        1,
        1,
        'TGG-OD',
        'Name of interaction',
        '2024/08/24 08:00:00',
        '2024/08/24 10:00:00',
        'Description of interaction',
        'Event Name',
        'Venue Name',
        'Category Name (e.g. Open Day)',
        'Email of facilitator (e.g. brenda.scheepers@belgotex.co.za)'
      ]
    ]

    let csvContent = headers.join(',') + '\n' // Add headers

    data.forEach(row => {
      csvContent += row.join(',') + '\n' // Add each row of data
    })

    return csvContent
  }

  // Function to trigger CSV download
  const downloadCSV = () => {
    const csvContent = createCSV()
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'Example CSV.csv') // Set the file name
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link) // Clean up
  }

  return (
    <ButtonComponentOutlined
      onClick={downloadCSV}
      title="Download Sample CSV"
      startIcon={faDownload}
      endIcon={undefined}
      children={undefined}
      size="small"
    />
  )
}

export default DownloadCSV
