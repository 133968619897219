import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  TablePagination
} from '@material-ui/core'
import React from 'react'

const GroupDashboardTable = ({ data = [], displayKeys = [] }) => {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const rowStyle = {
    color: '#000',
    fontWeight: 'bold',
    fontSize: '14px'
  }

  console.log('summary data', data)

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
        <Table>
          <TableHead
            style={{
              backgroundColor: '#f1f0f0'
            }}
          >
            <TableRow>
              {displayKeys.map((key, index) => (
                <TableCell key={index} style={rowStyle}>
                  {key.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
              console.log('row', row)
              return (
                <TableRow key={index}>
                  {displayKeys.map((key, index) => (
                    <TableCell key={index} style={rowStyle}>
                      {key.value === 'status' ? (
                        <Chip
                          label={row.status}
                          size="small"
                          style={{
                            fontWeight: 'bold',
                            backgroundColor: row.status === 'ACTIVE' ? '#41be5b' : '#ff0200',
                            color: '#fff'
                          }}
                        />
                      ) : key.value === 'attendance_percentage' ? (
                        Math.round(row[key.value])
                      ) : key.subKey ? (
                        // Check if the subKey is an array
                        Array.isArray(row[key.value][key.subKey]) ? (
                          row[key.value][key.subKey].map((item, index) => (
                            <div key={index}>{item}</div>
                          ))
                        ) : (
                          row[key.value][key.subKey]
                        )
                      ) : (
                        row[key.value]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default GroupDashboardTable
