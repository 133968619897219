import { Button } from '@material-ui/core'
import React, { useEffect } from 'react'
import { colorPalette } from './constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ButtonComponentContained = ({
  disabled = false,
  bgColor = '#59a6a6',
  textColor = 'white',
  onClick = () => {},
  startIcon,
  endIcon,
  title,
  style = {},
  children,
  size = 'medium',
  loading = false
}) => {
  return (
    <Button
      startIcon={
        <FontAwesomeIcon
          icon={startIcon}
          style={{
            color: textColor ?? '#fff',
            height: 10,
            width: 10
          }}
          size="2xs"
        />
      }
      endIcon={endIcon ?? null}
      variant="contained"
      disabled={disabled || loading}
      color="secondary"
      style={{
        backgroundColor: disabled
          ? 'gray'
          : style.backgroundColor ?? bgColor ?? colorPalette.primary, // Background color
        color: textColor ?? '#fff', // Text color
        ...style, // Optional padding
        height: size === 'small' ? '28px' : size === 'medium' ? '40px' : '56px',
        fontSize: size === 'small' ? '12px' : size === 'medium' ? '14px' : '16px'
      }}
      onClick={onClick}
    >
      {loading ? 'Loading...' : <div>{children ?? title}</div>}
    </Button>
  )
}

export default ButtonComponentContained
