import React, { useEffect, useState } from 'react'

import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { deleteGroup, getGroups } from '../../asyncActions'
import { CreateGroup } from './components'
import MakeDefaultGroupPopUp from './components/makeDefaultGroupPopUp'
import ConfirmationModal from '../customComponents/confirmationModal'
import TableComponent from '../UIComponents/components/tables/table'
import { faPen, faTrash } from '@fortawesome/pro-light-svg-icons'
import { selectGroups, selectGroupsLoaded } from './selectors'
import ButtonComponentContained from '../UIComponents/components/buttons/buttonContained'
import NotificationControl from '../../controls/notification/notification'

const GroupsComponent = ({ facilitatorState, groupState, mainState }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [showAddGroup, setShowAddGroup] = useState(false)
  const [showDefaultGroupPopup, setShowDefaultGroupPopup] = useState(false)
  const [showDeleteGroupPopup, setShowDeleteGroupPopup] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState({})
  const groups = useSelector(selectGroups)
  const groupsLoaded = useSelector(selectGroupsLoaded)
  const [notificationData, setNotificationData] = useState({})

  const _toggleAddGroup = () => {
    setShowAddGroup(!showAddGroup)
  }

  useEffect(() => {
    dispatch(getGroups())
  }, [])

  return (
    <div className="flex-col flex">
      <TableComponent
        search={true}
        title="Groups"
        displayKeys={[
          { value: 'name', label: 'Group Name' },
          { value: 'description', label: 'Description' },
          { value: 'allocation_code', label: 'Allocation Code' },
          {
            value: 'facilitators',
            label: 'Facilitators',
            isArray: true
          }
        ]}
        data={groupState.groups}
        onRowClick={item => {
          history.push(`group-details/${item._id}`)
        }}
        actions={[
          {
            label: 'Edit',
            action: item => {
              setSelectedGroup(item)
              setIsEdit(true)
              _toggleAddGroup()
            },
            icon: faPen
          },
          {
            label: 'Delete',
            action: item => {
              setSelectedGroup(item)
              setShowDeleteGroupPopup(true)
            },
            icon: faTrash
          }
        ]}
      >
        <ButtonComponentContained
          title="Add Group"
          onClick={() => {
            setIsEdit(false)
            _toggleAddGroup()
          }}
          startIcon={faPen}
          endIcon={undefined}
        />
      </TableComponent>

      <CreateGroup
        data={selectedGroup}
        open={showAddGroup}
        setIsEdit={setIsEdit}
        isEdit={isEdit}
        onSuccess={async () => {
          await dispatch(getGroups())
        }}
      />
      <MakeDefaultGroupPopUp
        openPopUp={showDefaultGroupPopup}
        onOpenPopUp={setShowDefaultGroupPopup}
        group_id={selectedGroup._id}
      />

      <ConfirmationModal
        isOpen={showDeleteGroupPopup}
        onClose={v => setShowDeleteGroupPopup(false)}
        onConfirm={async v => {
          await dispatch(deleteGroup(selectedGroup._id))
          setShowDeleteGroupPopup(false)
          setNotificationData({
            title: 'Group Deleted',
            messages: ['Group has been deleted successfully']
          })
        }}
        title="Confirmation"
        description="Are you sure you want to proceed? This is a permanent action and cannot be undone."
        confirmationText={selectedGroup.name}
        confirmButtonText="Confirm"
        cancelButtonText="Cancel"
      />

      <NotificationControl notificationData={notificationData} />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    facilitatorState: state.facilitatorState,
    groupState: state.groupState,
    mainState: state.mainState
  }
}

export default connect(mapStateToProps)(GroupsComponent)
