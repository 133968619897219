import React, { useState } from 'react'

import { connect, useDispatch } from 'react-redux'

import { saveGreenlightSurvey } from '../../asyncActions/greenlight'
import { setNotification } from '../../asyncActions/notification'
import { surveyIndicators } from '../../constants/constants'
import ImageAvatarControl from '../../controls/imageAvatar/imageAvatar'
import TableControl from '../../controls/table/table'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import { useFormActionHook } from '../../hooks/useFormActionHook'
import { getDataWithIndicatorsIcons, getQuestionsAndIndicators } from '../../utils/surveyUtils'
import { greenlightColumns, greenlightValidationConfig, initialGreenlightValues } from './constants'
import AddDimensionsModal from './modals/addDimensionsModal'
import AddPhotoModal from './modals/addPhotoModal'
import ImportCsvModal from './modals/importCsvModal'
import { faFileImport, faPlus, faSave, faSearch } from '@fortawesome/pro-light-svg-icons'
import ButtonComponent from '../customComponents/button'
import TextFieldComponent from '../UIComponents/components/textfield/textfiled'
import DatePickerComponent from '../UIComponents/components/calendar/date'
import { Avatar } from '@material-ui/core'
import ButtonComponentContained from '../UIComponents/components/buttons/buttonContained'
import ButtonComponentOutlined from '../UIComponents/components/buttons/buttonOutlined'

const AddGreenlightSurvey = ({ notificationState: notificationData }) => {
  const dispatch = useDispatch()
  const [showAddDimensions, setShowAddDimensions] = useState(false)
  const [showImportCsv, setShowImportCsv] = useState(false)
  const [showAddPhoto, setShowAddPhoto] = useState(false)
  const [formValues, setFormValues] = useState(initialGreenlightValues)
  const { handleSubmit } = useFormActionHook(formValues, greenlightValidationConfig)

  const greenlightTableData = getDataWithIndicatorsIcons({
    data: formValues.dimensions,
    iconConfig: surveyIndicators,
    lookup: 'questions.indicator'
  })
  const tableData = getQuestionsAndIndicators({ data: greenlightTableData, lookup: 'indicator' })

  const _toggleAddDimensions = () => {
    setShowAddDimensions(!showAddDimensions)
  }
  const _toggleAddPhoto = () => {
    setShowAddPhoto(!showAddPhoto)
  }

  const _toggleImportCsv = () => {
    setShowImportCsv(!showImportCsv)
  }

  const _onInputChange = value => {
    setFormValues({ ...formValues, ...value })
  }

  const _onSubmitDimensionResults = values => {
    setFormValues({ ...formValues, dimensions: [...formValues.dimensions, values] })
  }
  const _onSubmitCsv = values => {
    setFormValues({ ...formValues, dimensions: [...formValues.dimensions, ...values] })
  }
  const _onSubmitPhoto = photo => {
    setFormValues({ ...formValues, photo })
  }

  const _saveSurvey = () =>
    handleSubmit({
      formAction: () => {
        dispatch(saveGreenlightSurvey(formValues))
      },
      onFailedValidation: errors => {
        dispatch(
          setNotification({
            title: 'Validation Failed',
            messages: errors
          })
        )
      }
    })

  return (
    <TableContentWrapper
      title="Greenlight Results"
      notificationData={notificationData}
      children={undefined}
      description={undefined}
    >
      {/* ----------Forms */}
      <div className="flex flex-col gap-16">
        <div className="flex flex-row gap-4">
          <div className="flex gap-2 items-center">
            <div
              className={`flex h-5 w-5 bg-green rounded-full justify-center items-center text-white`}
            >
              1
            </div>
            <p className={'text-xs'}>Not Stuck</p>
          </div>
          <div className="flex gap-2 items-center">
            <div
              className={`flex h-5 w-5 bg-orange rounded-full justify-center items-center text-white`}
            >
              2
            </div>
            <p className={'text-xs'}>Struggling</p>
          </div>
          <div className="flex gap-2 items-center">
            <div
              className={`flex h-5 w-5 bg-red rounded-full justify-center items-center text-white`}
            >
              3
            </div>
            <p className={'text-xs'}>Stuck</p>
          </div>
        </div>
        <div className="flex flex-col w-1/2 gap-2">
          <div className="flex justify-between w-full gap-4">
            <div className="flex flex-col gap-2 w-full ">
              <TextFieldComponent
                label="Name"
                value={formValues.name}
                onChange={name => _onInputChange({ name })}
                helperText={undefined}
                required={true}
              />
              <TextFieldComponent
                label="Identity Card"
                value={formValues.identity_card}
                onChange={identity_card => _onInputChange({ identity_card })}
                helperText={undefined}
                required={true}
              />
            </div>
            <div className="flex flex-col gap-2 w-full">
              <TextFieldComponent
                label="Office"
                value={formValues.office}
                onChange={office => _onInputChange({ office })}
                helperText={undefined}
                required={true}
              />
              <TextFieldComponent
                label="Survey timeline"
                value={formValues.survey_timeline}
                onChange={survey_timeline => _onInputChange({ survey_timeline })}
                helperText={undefined}
                required={true}
              />
            </div>
          </div>
          <DatePickerComponent
            title="Date"
            value={formValues.date}
            onChange={date => _onInputChange({ date })}
            required={true}
          />
          {/* <ImageAvatarControl imgUrl={formValues.photo} onClick={_toggleAddPhoto} /> */}
          <Avatar src={formValues?.photo} onClick={_toggleAddPhoto} className="h-44 w-44 bg-red" />

          <TextFieldComponent
            label="Narrative"
            multiline={true}
            value={formValues.narrative}
            onChange={narrative => _onInputChange({ narrative })}
            helperText={undefined}
            required={true}
          />
          <TableControl
            columns={greenlightColumns}
            data={tableData}
            hideEmptyTableData={false}
            width="full"
            rowClicked={id => {}}
          />
          <div className="flex flex-row justify-end items-center w-full gap-4">
            <ButtonComponentOutlined
              title="Add Dimensions"
              startIcon={faPlus}
              onClick={_toggleAddDimensions}
            />
            <ButtonComponentOutlined
              onClick={_toggleImportCsv}
              startIcon={faFileImport}
              title="Import CSV"
            />
            <ButtonComponentContained title="Save" startIcon={faSave} onClick={_saveSurvey} />
          </div>
        </div>
      </div>

      <AddDimensionsModal
        onSuccess={_onSubmitDimensionResults}
        open={showAddDimensions}
        setShow={_toggleAddDimensions}
      />
      <AddPhotoModal onSuccess={_onSubmitPhoto} open={showAddPhoto} setShow={_toggleAddPhoto} />
      <ImportCsvModal onSuccess={_onSubmitCsv} open={showImportCsv} setShow={_toggleImportCsv} />
    </TableContentWrapper>
  )
}

const mapStateToProps = state => {
  return {
    notificationState: state.notificationState,
    greenlightState: state.greenlightState
  }
}

export default connect(mapStateToProps)(AddGreenlightSurvey)
