import { Button } from '@material-ui/core'
import React, { useEffect } from 'react'
import { colorPalette } from './constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ButtonComponentOutlined = ({
  disabled = false,
  bgColor = '#59a6a6',
  textColor = 'white',
  onClick = () => {},
  startIcon,
  endIcon,
  title,
  style = {},
  children,
  size = 'medium'
}) => {
  return (
    <Button
      startIcon={
        <FontAwesomeIcon
          icon={startIcon}
          style={{
            color: bgColor ?? '#fff',
            height: 10,
            width: 10
          }}
          size="2xs"
        />
      }
      endIcon={
        <FontAwesomeIcon
          icon={endIcon}
          style={{
            color: bgColor ?? '#fff',
            height: 10,
            width: 10
          }}
          size="2xs"
        />
      }
      variant="outlined"
      disabled={disabled}
      color={'secondary'}
      style={{
        borderColor: disabled ? 'gray' : style.borderColor ?? bgColor ?? colorPalette.primary, // Background color
        color: bgColor, // Text color
        ...style, // Optional padding
        height: size === 'small' ? '28px' : size === 'medium' ? '40px' : '56px'
      }}
      onClick={onClick}
    >
      <div>{children ?? title}</div>
    </Button>
  )
}

export default ButtonComponentOutlined
