import React, { useRef } from 'react'
import { Paper } from '@material-ui/core'
import { Backdrop } from '@mui/material'

export default function BackDropComponent({
  isOpen,
  onBackDropClose = () => {},
  title = '',
  description = '',
  children
}) {
  const backdropRef = useRef(null)

  const handleClick = event => {
    if (backdropRef?.current && !backdropRef?.current?.contains(event.target)) {
      onBackDropClose()
    }
  }

  return (
    <Backdrop
      sx={theme => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
      open={isOpen}
      onClick={handleClick}
    >
      <Paper
        ref={backdropRef}
        elevation={3}
        className="flex flex-col bg-main rounded-md shadow-lg m-auto"
        style={{ width: '50%', padding: '20px' }}
      >
        <div className="flex flex-col gap-4">
          {title || description ? (
            <div>
              <div className="font-sans font-extrabold text-lg">{title}</div>
              <div className="font-sans font-normal text-sm text-gray">{description}</div>
            </div>
          ) : null}
          {children}
        </div>
      </Paper>
    </Backdrop>
  )
}
