import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFilteredGroupDashboard } from '../../asyncActions'
import { selectGroupDashboard } from '../groups'
import { OILevels } from './constants'
import NotificationControl from '../../controls/notification/notification'
import { get_all_groups, get_all_programs } from '../../api/calls'
import FilterComponent from './components/filterComponent'
import GroupDashboardTable from '../UIComponents/components/tables/groupDashboardTable'
import ToggleComponent from '../UIComponents/components/toggle/toggle'

const GroupDashboard2 = () => {
  const dispatch = useDispatch()

  const [formValues, setFormValues] = useState()
  const groupDashboard = useSelector(selectGroupDashboard)
  const [groups, setGroups] = useState([])
  const [programs, setPrograms] = useState([])
  const [selectedTable, setSelectedTable] = useState('group_summary')

  useEffect(() => {
    get_all_programs().then(res => {
      if (res.data.success) {
        setPrograms(res.data.content)
      }
    })

    get_all_groups().then(res => {
      if (res.data.success) {
        setGroups(res.data.content)
      }
    })
  }, [])

  const _onInputChange = value => {
    setFormValues({ ...formValues, ...value })
  }

  const _filterGroups = () => dispatch(getFilteredGroupDashboard(formValues))

  useEffect(() => {
    const groups = formValues?.group_ids ?? []

    if (groups.length > 0) {
      _filterGroups()
      return
    }

    if (groups.length > 0 && formValues?.program_id != null) {
      _filterGroups()
      return
    }
  }, [formValues])

  return (
    <div>
      <div className="max-h-screen bg-white p-2">
        <div className="grid grid-cols-8 space-x-4">
          <div className="col-span-2">
            <FilterComponent
              programs={programs.map(program => {
                return { _id: program._id, name: program.program_name }
              })}
              groups={groups.map(group => {
                return { _id: group._id, name: group.name }
              })}
              oiLevels={OILevels}
              onChange={v => {
                _onInputChange(v)
              }}
            />
          </div>
          <div className="col-span-6 flex flex-col gap-2 justify-center">
            <ToggleComponent
              //   defaultSelected={'group_summary'}
              buttons={[
                { label: 'Group Summary', value: 'group_summary' },
                { label: 'Attendance & Performance', value: 'attendance_performance' }
              ]}
              onClick={v => {
                setSelectedTable(v.value)
              }}
            />
            {selectedTable === 'group_summary' && (
              <GroupDashboardTable
                displayKeys={[
                  { value: 'name', label: 'Group' },
                  { value: 'attendance_percentage', label: 'Attendance (%)' },
                  { value: 'average_oi', label: 'Avg Human OI', subKey: 'human_level' },
                  { value: 'average_oi', label: 'Avg Technical OI', subKey: 'technical_level' },
                  { value: 'average_oi', label: 'Avg VED OI', subKey: 'ved_level' },
                  { value: 'transactions', label: 'Sales in Units', subKey: 'unit_sales' },
                  { value: 'transactions', label: 'Sales Amount', subKey: 'amount' },
                  { value: 'ved_outstanding', label: 'VED Outstanding' }
                ]}
                data={groupDashboard?.group_summary ?? []}
              />
            )}
            {selectedTable === 'attendance_performance' && (
              <GroupDashboardTable
                displayKeys={[
                  { value: 'group_name', label: 'Group' },
                  { value: 'user_name', label: 'Name' },
                  { value: 'user_surname', label: 'Surname' },
                  { value: 'status', label: 'Status' },
                  { value: 'total_attendances_taken', label: 'Att. Taken' },
                  { value: 'average_oi', label: 'Avg Human OI', subKey: 'human_level' },
                  { value: 'average_oi', label: 'Avg Technical OI', subKey: 'technical_level' },
                  { value: 'average_oi', label: 'Avg VED OI', subKey: 'ved_level' },
                  { value: 'transactions', label: 'Sales in Units', subKey: 'unit_sales' },
                  { value: 'transactions', label: 'Sales Amount', subKey: 'amount' },
                  { value: 'ved_outstanding', label: 'VED Outstanding' },
                  { value: 'attendance_percentage', label: 'Progress (%)' }
                ]}
                data={groupDashboard?.learner_summary ?? []}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    groupState: state.groupState,
    programsState: state.programsState
  }
}

export default GroupDashboard2
