import React from 'react'
import { CSVLink, CSVDownload } from 'react-csv'
import ButtonComponentContained from '../components/UIComponents/components/buttons/buttonContained';
import { faFilePdf } from '@fortawesome/pro-light-svg-icons';


export const CSVDownloaderControl =  ({headers, data, title})=> {
  

  return (
   <ButtonComponentContained startIcon={faFilePdf} title={title} >
    <CSVLink data={data} headers={headers} filename={`${title}.csv`}>
      Download CSV
    </CSVLink>
   </ButtonComponentContained>
  )
}

export default CSVDownloaderControl
