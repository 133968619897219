import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import LoaderControl from '../../../controls/loader/loader'
import { save_targets } from '../../../api/calls/save_targets'
import BackDropComponent from '../../UIComponents/components/form/backdrop'
import FormComponent from '../../../controls/forms/form'
import TextFieldComponent from '../../UIComponents/components/textfield/textfiled'

const AddProgramTarget = ({ open = false, setShow, program_id, onSuccess }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [target, setTarget] = useState({})

  useEffect(() => {
    setTarget({
      ...target,
      program_id
    })
  }, [program_id])

  const save = async () => {
    setIsBusy(false)

    // Call API to save target
    await save_targets(target).then(res => {
      if (res.data.success) {
        if (res.data.success) {
          alert('Target successfully added!')
          // Call API to get program information
          onSuccess()
        }
        setShow(false)
      }
    })
  }
  return (
    <div>
      <BackDropComponent isOpen={open} onBackDropClose={() => setShow(false)}>
        <FormComponent
          title={'Add Target'}
          description={'Add a target to the program'}
          onCancel={() => setShow(false)}
          onSubmit={async () => {
            setIsBusy(true)
            await save()
          }}
        >
          <div className="flex w-full flex-col gap-4">
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Target Name</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="SEEDLINGS"
                  control={<Radio color="secondary" />}
                  label="Seedlings"
                  // className="text-white"
                  onClick={e => {
                    console.log('Selected value:', e)
                    setTarget({
                      ...target,
                      target_name: e
                    })
                  }}
                />
                <FormControlLabel
                  value="SQM"
                  control={<Radio />}
                  label="Sqm (Square Meters)"
                  // className="text-white"
                  onClick={e => {
                    console.log('Selected value:', e)
                    setTarget({
                      ...target,
                      target_name: e
                    })
                  }}
                />
              </RadioGroup>
            </FormControl>
            <TextFieldComponent
              label="Target Value (in Rands)"
              type="number"
              value={target.target_value}
              onChange={v => {
                setTarget({
                  ...target,
                  target_value: v
                })
              }}
            />
          </div>
        </FormComponent>
      </BackDropComponent>
      <LoaderControl show={isBusy} />
    </div>
  )
}

export default AddProgramTarget
