import { apiGet } from '../api'

export const get_catch_up_days = data => {
  let query = ''

  if (data.program_id && data.interaction_id) {
    query = `?program_id=${data.program_id}&interaction_id=${data.interaction_id}`
  }
  return apiGet({
    endpoint: `attendance/get_catch_up_days${query}`
  })
}
