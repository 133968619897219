export const greenlightColumns = [
  { key: 'dimension', display: 'Dimension' },
  { key: 'questions', display: 'Question(s)' },
  { key: 'indicator', display: 'Indicator' }
]
export const greenlightSurveyListColumns = [
  { value: 'name', label: 'Name' },
  { value: 'office', label: 'Office' },
  { value: 'date', date: true, label: 'Date' }
]

export const greenlightValidationConfig = [
  { field: 'name', types: ['required', 'notEmpty'] },
  { field: 'identity_card', types: ['required', 'notEmpty'] },
  { field: 'survey_timeline', types: ['required', 'notEmpty'] },
  { field: 'date', types: ['required', 'notEmpty'] },
  { field: 'photo', types: ['required'] },
  { field: 'narrative', types: ['required', 'notEmpty'] },
  { field: 'office', types: ['required', 'notEmpty'] }
]

export const dimensionsValidationConfig = [
  { field: 'dimension', types: ['required'] },
  { field: 'questions', types: ['required'] }
]

export const initialGreenlightValues = {
  name: '',
  identity_card: '',
  survey_timeline: '',
  photo: '',
  narrative: '',
  date: '',
  office: '',
  dimensions: [] // of type dimension
}

export const initialDimensionsValues = {
  dimension: '',
  questions: [] // of type Question
}

export const initialQuestionsValues = {
  question: '',
  indicator: ''
}

export const mockData = {
  ...initialGreenlightValues,
  dimensions: [
    {
      ...initialDimensionsValues,
      questions: [initialQuestionsValues, initialQuestionsValues, initialQuestionsValues]
    },
    { ...initialDimensionsValues, questions: [initialQuestionsValues] }
  ]
}
