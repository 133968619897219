import React from 'react'

import LoaderControl from '../loader/loader'
import NotificationControl from '../notification/notification'
import { Paper } from '@material-ui/core'

const TableContentWrapper = ({
  children,
  title,
  actionButtons = [],
  loaderIsActive = false,
  notificationData = { title: '', messages: [], type: 0 }, description
}) => {
  return ( 
    <Paper style={{
      padding: '20px',
      position: 'relative'
    }} >
      
    <div className="flex flex-col gap-4">
      <div className="flex flex-row">
        <div className='flex flex-col p-4'>
        <div className="flex-auto text-lg font-sans font-bold">{title}</div>
        <div className="flex-auto text-sm text-gray">{description}</div>
        </div>
        <div className="flex justify-between mr-4">
          {actionButtons.map((item, id) => (<div key={id}> {item}</div>))}
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="bg-white p-4">{children}</div>
      </div>
      <LoaderControl show={loaderIsActive} />
      {notificationData && <NotificationControl notificationData={notificationData} />}
    </div> 

    </Paper>
  )
}

export default TableContentWrapper
