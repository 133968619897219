import React, { useEffect, useState } from 'react'

import { connect, useDispatch } from 'react-redux'

import { uploadFile } from '../../../asyncActions'
import FileInputControl from '../../../controls/fileinput/fileinput'
import ImageAvatarControl from '../../../controls/imageAvatar/imageAvatar'
import ModalFormWrapper from '../../../controls/modalFormWrapper/modalFormWrapper'
import PopupControl from '../../../controls/popup/popup'
import { INITIAL_STATE as initialNotificationData } from '../../../reducers/states/notification'
import ButtonComponent from '../../customComponents/button'
import FilesComponent from '../../customComponents/files'
import { title } from 'process'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import ButtonComponentContained from '../../UIComponents/components/buttons/buttonContained'
import ButtonComponentOutlined from '../../UIComponents/components/buttons/buttonOutlined'

const AddPhotoModal = ({ open = false, setShow, onSuccess }) => {
  const dispatch = useDispatch()
  const [notificationData, setNotificationData] = useState(initialNotificationData)
  const [uploadedFile, setUploadedFile] = useState(undefined)

  const _fileUploadCallback = document => {
    setUploadedFile(document?.content?.location)
  }

  const _handlePhotoUpload = file => {
    dispatch(uploadFile(file, _fileUploadCallback))
  }

  const _resetUploadedFile = () => {
    setUploadedFile(undefined)
  }

  const _onSubmitHandler = () => {
    onSuccess(uploadedFile)
    setNotificationData(initialNotificationData)
    setShow(false)
    _resetUploadedFile()
  }

  useEffect(() => {
    console.log('uploadedFile', uploadedFile)
  }, [uploadedFile])

  return (
    <div>
      <PopupControl
        title="Add your image"
        isOpen={open}
        close={{
          showClose: true,
          onClose: () => {
            setShow(false)
          }
        }}
      >
        <ModalFormWrapper notificationData={notificationData}>
          <div className="flex flex-row gap-4">
            <div className="w-72 flex flex-col gap-4">
              {uploadedFile ? (
                <div className="place-self-center">
                  <ImageAvatarControl imgUrl={uploadedFile} onClick={() => {}} />
                </div>
              ) : (
                <FilesComponent
                  label="Attach photo"
                  theme="light"
                  placeholder={uploadedFile ?? 'Upload a photo'}
                  onChange={_handlePhotoUpload}
                />
              )}
            </div>
          </div>
          <div className="flex justify-center">
            <div className="w-full flex justify-end gap-2">
              {uploadedFile && (
                <ButtonComponentOutlined
                  title="Delete"
                  onClick={_resetUploadedFile}
                  bgColor="red"
                />
              )}
              <div className="flex justify-center items-center">
                <ButtonComponentContained
                  title="Save"
                  startIcon={faSave}
                  onClick={_onSubmitHandler}
                />
              </div>
            </div>
          </div>
        </ModalFormWrapper>
      </PopupControl>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    mainState: state.mainState
  }
}

export default connect(mapStateToProps)(AddPhotoModal)
