import React, { useState } from 'react'
import ButtonComponentContained from '../UIComponents/components/buttons/buttonContained'

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  title = 'Confirmation',
  description = 'Are you sure you want to proceed?',
  confirmationText = 'CONFIRM',
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel'
}) => {
  const [inputValue, setInputValue] = useState('')

  if (!isOpen) return null

  // Handler to prevent closing when clicking inside the modal
  const handleContainerClick = e => {
    e.stopPropagation()
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div
        className="bg-dark text-white rounded-lg p-6 w-96 shadow-lg"
        onClick={handleContainerClick}
      >
        <h2 className="text-lg font-semibold mb-4">{title}</h2>
        <p className="mb-4 text-gray-300 text-md font-sans font-extrabold">{description}</p>
        <label className="block text-sm mb-2">
          Please type this to confirm:{' '}
          <span className="text-red-400 text-md font-sans font-extrabold">{confirmationText}</span>
        </label>
        <input
          type="text"
          className="w-full px-3 py-2 border border-gray-600 rounded bg-gray-700 text-black focus:outline-none focus:ring-2 focus:ring-red-500"
          placeholder={confirmationText}
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
        />
        <div className="flex justify-end gap-2 mt-6">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-semibold text-gray-300 bg-gray-700 rounded hover:bg-gray-600"
          >
            {cancelButtonText}
          </button>
          {/* <button
            onClick={() => inputValue === confirmationText && onConfirm()}
            className={`px-4 py-2 text-sm font-bold rounded ${
              inputValue === confirmationText
                ? 'bg-red-500 hover:bg-red-600 text-white'
                : 'bg-red-300 text-gray-700 cursor-not-allowed'
            }`}
            disabled={inputValue !== confirmationText}
          >
            {confirmButtonText}
          </button> */}

          <ButtonComponentContained
            title={confirmButtonText}
            bgColor="#fc5f39"
            textColor="white"
            onClick={() => inputValue === confirmationText && onConfirm()}
            disabled={inputValue !== confirmationText}
          />
        </div>
      </div>
    </div>
  )
}

export default ConfirmationModal
