import React from 'react'
import { formatDate } from '../../../utils/utils'
import CardControl from '../../learner/components/card'

const InformationBlock = ({ data }) => {
  console.log('data', data)
  return (
    <div className="flex flex-col gap-2">
      <CardControl title="Group Information">
        <div className="flex flex-col">
          <div className="flex pb-2 flex-col">
            <div className="font-extrabold font-sans text-xs italic">Group</div>
            <div className="font-extrabold text-sm">{data?.name ?? '-'}</div>
          </div>
          <div className="flex pb-2 flex-col">
            <div className="font-extrabold font-sans text-xs italic">Location</div>
            <div className="font-extrabold text-sm">{data?.location ?? '-'}</div>
          </div>
          <div className="flex pb-2 flex-col">
            <div className="font-extrabold font-sans text-xs italic">Description</div>
            <div className="font-extrabold text-sm">{data?.description ?? '-'}</div>
          </div>
          <div className="flex pb-2 flex-col">
            <div className="font-extrabold font-sans text-xs italic">Date Added</div>
            <div className="font-extrabold text-sm">{formatDate(data?.createdAt) ?? '-'}</div>
          </div>
          <div className="flex pb-2 flex-col">
            <div className="font-extrabold font-sans text-xs italic">Added By</div>
            <div className="font-extrabold text-sm">{data?.created_by?.full_name ?? '-'}</div>
          </div>
        </div>
      </CardControl>
      <CardControl title="Group Attendance Information">
        <div className="flex flex-col">
          <div className="flex py-2 flex-col">
            <div className="font-extrabold font-sans text-xs italic">
              <div>Average Attendace</div>
            </div>
            <div className="text-sm font-extrabold">
              {data?.attendance_percentage != null ? `${data?.attendance_percentage}%` : '-'}
            </div>
          </div>
          <div className="flex pb-2 flex-col">
            <div className="font-extrabold font-sans text-xs italic">
              <div>Average OI Level(s)</div>
            </div>
            <div className="text-sm font-extrabold">
              {data?.average_oi?.human_level ? (
                <div className="flex flex-col gap-2">
                  <div>
                    Human OI Level:{' '}
                    <span className="flex gap-2">
                      {data?.average_oi?.human_level.map(e => {
                        return <div>{e}</div>
                      })}
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="text-sm font-extrabold">
              {data?.average_oi?.human_level ? (
                <div className="flex flex-col gap-2">
                  <div>
                    Technical OI Level:{' '}
                    <span className="flex gap-2">
                      {data?.average_oi?.technical_level.map(e => {
                        return <div>{e}</div>
                      })}
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {/* <div className="flex pb-2 flex-col">
            <div className="font-extrabold font-sans text-xs italic">
              <div>Business OI Level</div>
            </div>
            <div className="text-sm font-extrabold">{data?.attendance?.business_oi ?? '-'}</div>
          </div> */}
        </div>
      </CardControl>
    </div>
  )
}

export default InformationBlock
