import React from 'react'
import { useHistory } from 'react-router-dom'
import { MyTableControl } from '../../../controls/myTableControl/myTableControl'
import TableComponent from '../../UIComponents/components/tables/table'
import { faDownload, faEye } from '@fortawesome/pro-light-svg-icons'

const HistoricalRegisters = ({ data }) => {
  const history = useHistory()

  return (
    <div>
      <div className="bg-white shadow-md">
        <TableComponent
          title="Register History"
          data={data ?? []}
          displayKeys={[
            { value: 'name', label: 'Name' },
            { value: 'date', label: 'Date' },
            {
              value: 'number_of_registers',
              label: 'Number of Registers Taken (Cumulatively per interaction)'
            }
          ]}
          actions={[
            {
              label: 'Download',
              icon: faDownload,
              action: e => {
                history.push(`/dashboard/register-download/${e._id}`)
              }
            }
          ]}
        />
      </div>
    </div>
  )
}

export default HistoricalRegisters
