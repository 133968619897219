import React, { useEffect, useState } from 'react'

import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { acceptApplication, allApplications, rejectApplication } from '../../asyncActions'
import { applicationTableColumns } from './constants'
import { selectApplications, selectApplicationsLoaded, selectApplicationsState } from './selectors'
import EditApplicationComponent from './editApplication'
import TableComponent from '../UIComponents/components/tables/table'
import { faPen, faX } from '@fortawesome/pro-light-svg-icons'
import ButtonComponentContained from '../UIComponents/components/buttons/buttonContained'

const StudentApplications = ({ alertState, groupState, taskState }) => {
  const dispatch = useDispatch()
  const applicationsLoaded = useSelector(selectApplicationsLoaded)
  const applications = useSelector(selectApplications, shallowEqual)
  const [editApplication, setEditApplication] = useState(false)
  const [applicationData, setApplicationData] = useState({})
  const history = useHistory()
  useEffect(() => {
    dispatch(allApplications())
  }, [alertState])

  useEffect(() => {
    if (!applicationsLoaded) dispatch(allApplications())
  }, [applicationsLoaded])

  const _onClickActionButton = (item, _) => {
    if (_.id == 1) {
      setApplicationData(item)

      //check if email is already added, if yes then accept the application, else open the edit application popup
      if (item.email != undefined && item.email != '') {
        dispatch(acceptApplication(item))
      } else {
        setApplicationData(item)
        setEditApplication(true)
      }
    }
    if (_.id == 2) {
      dispatch(rejectApplication(item))
    }
  }

  return (
    <div>
      <TableComponent
        title="Applications Portal"
        search={true}
        displayKeys={applicationTableColumns}
        data={applications.map((item, index) => {
          return {
            ...item,
            group: item?.group?.name,
            event: item?.event?.event_name
          }
        })}
        actions={[
          {
            label: 'Accept',
            action: item => {
              setApplicationData(item)

              //check if email is already added, if yes then accept the application, else open the edit application popup
              if (item.email == undefined || item.email == '') {
                setApplicationData(item)
                setEditApplication(true)
              } else {
                dispatch(acceptApplication(item))
              }
            },
            //add icon to indicate that the application is accepted
            icon: faPen
          },
          {
            label: 'Reject',
            action: item => {
              dispatch(rejectApplication(item))
            },
            icon: faX
          }
        ]}
      >
        <ButtonComponentContained
          title="Create Application"
          onClick={() => {
            history.push(`/student-application`)
          }}
          startIcon={faPen}
          endIcon={undefined}
          children={undefined}
        />
      </TableComponent>
      <EditApplicationComponent
        data={applicationData}
        open={editApplication}
        setShow={setEditApplication}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    userState: state.userState,
    taskState: state.taskState,
    groupState: state.groupState,
    permissionsState: state.permissionsState,
    alertState: state.alertState
  }
}

export default connect(mapStateToProps)(StudentApplications)
