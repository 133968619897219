import React, { useEffect } from 'react'
import MultipleSelectComponent from '../../UIComponents/components/select/multiSelect'
import SingleSelectComponent from '../../UIComponents/components/select/singleSelect'
import RadioButtonComponent from '../../UIComponents/components/radio/radioButtton'
import { validateDate } from '@mui/x-date-pickers/internals'
import { color } from 'echarts'

const FilterComponent = ({ programs, groups, oiLevels, onChange }) => {
  const [formValues, setFormValues] = React.useState({})
  const _onFormValueChange = v => {
    setFormValues({ ...formValues, ...v })
  }

  useEffect(() => {
    onChange(formValues)
  }, [formValues])

  return (
    <div className="bg-indigo shadow-lg h-full p-4 flex flex-col rounded-md gap-4">
      <MultipleSelectComponent
        title={'Select Groups'}
        displayKey={'name'}
        data={groups ?? []}
        color={'white'}
        onMenuItemClick={v => {
          console.log('MultipleSelectComponent clicked', v)
          if (v.length > 0) {
            _onFormValueChange({ group_ids: v.map(g => g._id) })
          } else {
            delete formValues?.group_ids
            setFormValues({ ...formValues })
          }
        }}
      />
      <SingleSelectComponent
        title={'Select Programme'}
        errorMessage={'Programmes Not Found'}
        displayKey={'name'}
        data={programs ?? []}
        color={'white'}
        onClick={v => {
          _onFormValueChange({ program_id: v._id })
        }}
      />
      <SingleSelectComponent
        title={'Select OI Levels'}
        errorMessage={'OI Levels Not Found'}
        displayKey={'display'}
        data={oiLevels ?? []}
        color="white"
        onClick={v => {
          _onFormValueChange({ oi_level: v.display })
        }}
      />
      <hr />
      <div className="flex flex-col p-2">
        <RadioButtonComponent
          title={'Learner Statuses'}
          color={'white'}
          displayKey={'label'}
          disabled={!formValues?.group_ids ?? true}
          buttons={[
            { value: 'ACTIVE', label: 'Active' },
            { value: 'INACTIVE', label: 'Inactive' }
          ]}
          onClick={e => {
            console.log('RadioButtonComponent clicked', e)
            _onFormValueChange({ learner_status: e.value })
          }}
        />
        <RadioButtonComponent
          title={'Attendance Percentage Ranges'}
          color={'white'}
          displayKey={'label'}
          disabled={!formValues?.group_ids ?? true}
          buttons={[
            { value: '50-80', label: '50-80%' },
            { value: '80-100', label: '80-100%' }
          ]}
          onClick={e => {
            console.log('RadioButtonComponent clicked', e)
            _onFormValueChange({ attendance_range: e.value })
          }}
        />
      </div>
    </div>
  )
}

export default FilterComponent
