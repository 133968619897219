import { faEnvelope } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  IconButton
} from '@material-ui/core'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { set } from 'date-fns'

export default function RecruitmentSelectComponent({
  onClick = () => {},
  onSendSMS = e => {},
  data = [],
  title,
  displayKey,
  color = '#000',
  selected
}) {
  const [selectedValue, setSelectedValue] = useState()
  const [confirmSms, setConfirmSms] = useState(false)
  const [sendSms, setSendSms] = useState(false)
  const [smsIsSent, setSmsIsSent] = useState(false)

  useEffect(() => {
    console.log('selected', selected)
  }, [selected])

  useEffect(() => {
    if (sendSms) {
      _onSendSMS()
    }
  }, [sendSms])

  const _onSendSMS = () => {
    onSendSMS(selectedValue)
    setSmsIsSent(true)
    setSendSms(false)
  }

  return (
    <FormControl
      style={{
        width: '100%',
        color: color
      }}
      variant="outlined"
    >
      <InputLabel
        style={{
          color:
            selected == 'yes' ? 'green' : selected == 'no' ? 'red' : selectedValue?.color ?? color,
          fontSize: 14,
          fontWeight: 400,
          lineHeight: 1.5
        }}
        id="demo-simple-select-helper-label"
      >{`${data.length === 0 ? 'No items found' : title}`}</InputLabel>
      <Select
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        value={
          selected && selected !== 'No Status'
            ? data.find(e => e.value === selected)?.[displayKey]
            : selectedValue?.[displayKey] ?? ''
        }
        style={{
          height: 56,
          color:
            selected == 'yes' ? 'green' : selected == 'no' ? 'red' : selectedValue?.color ?? color
        }}
      >
        {data.map((item, index) => (
          <MenuItem
            key={index}
            value={item[displayKey]}
            onClick={v => {
              setSelectedValue(item)
              onClick(item)
            }}
          >
            <div>{item[displayKey]}</div>
          </MenuItem>
        ))}

        {data.length === 0 && (
          <MenuItem value="">
            <em>No items found</em>
          </MenuItem>
        )}
      </Select>
      {selectedValue && selectedValue?.value !== 'maybe' && !smsIsSent && (
        <div
          className="flex items-center hover:bg-gray-200 cursor-pointer"
          onClick={() => {
            if (!smsIsSent) {
              setConfirmSms(true)
            }
          }}
        >
          <IconButton aria-label="sms" size="small">
            <FontAwesomeIcon icon={faEnvelope} />
          </IconButton>
          <span>Send SMS</span>
          {confirmSms && (
            <span
              className={`text-green ${smsIsSent ? '' : 'hover:text-indigo cursor-pointer'} px-2`}
              onClick={() => {
                setSendSms(true)
                setConfirmSms(false)
              }}
            >
              ({`${smsIsSent ? 'Sms sent' : 'Confirm'}`})
            </span>
          )}
        </div>
      )}
    </FormControl>
  )
}
