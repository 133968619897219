import { faSearch, faCheck } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useEffect } from 'react'
import ButtonComponent from '../button'
import InputComponent from '../input'

const SubItemsComponent = props => {
  const { isOpen, name, items, onClick, displayKey, selectedOptions, setOpen } = props
  const [searchQuery, setSearchQuery] = useState('')
  const [subItems, setSubItems] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const [searchItems, setSearchItems] = useState([])
  const [selectedItem, setSelectedItem] = useState({})

  useEffect(() => {
    const list = []
    for (const item of items) {
      list.push({ item, selected: false })
    }
    setSubItems(list)
  }, [items])

  const _onClick = (item, idx) => {
    const updateItems = subItems.map(i => {
      if (i.item === item) {
        if (i.selected == false) {
          setSelectedItem({ ...i, selected: true })
          return { ...i, selected: true }
        } else {
          setSelectedItem({ ...i, selected: false })
          return { ...i, selected: false }
        }
      } else {
        return i
      }
    })
    setSubItems(updateItems)
  }

  const _onItemSelection = item => {
    setSelectedItems([...selectedItems, item])
  }

  const _onItemRemoval = item => {
    const array = selectedItems.filter(e => e != item)
    setSelectedItems(array)
  }

  useEffect(() => {
    if (selectedItem.item) {
      if (selectedItem.selected == true) {
        _onItemSelection(selectedItem.item)
      } else {
        _onItemRemoval(selectedItem.item)
      }
    }
  }, [selectedItem])

  useEffect(() => {
    setSearchItems(subItems)
  }, [subItems])

  useEffect(() => {
    if (searchQuery.length > 0) {
      let array = []
      array = subItems.filter(item =>
        item?.item[`${displayKey}`].toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
      setSearchItems(array)
    } else {
      setSearchItems(subItems)
    }
  }, [searchQuery])

  return (
    <div
      className={`${
        isOpen ? 'visible' : 'hidden'
      } bg-white p-4 shadow-xl absolute selectBox z-20 top-14 w-full rounded-md`}
    >
      <div className="flex flex-col gap-2">
        {selectedItems.length > 0 ? (
          <span className="text-xs font-bold text-red-600">
            Please click select to capture your selection(s)
          </span>
        ) : null}
        <span className="text-md font-bold">{name}</span>
        <InputComponent
          theme="form"
          placeholder={'Search'}
          value={searchQuery}
          onChange={v => setSearchQuery(v)}
          icon={faSearch}
        />
      </div>
      <div className="max-h-80 overflow-auto">
        {searchItems.map((item, i) => (
          <div className="gap" key={i}>
            {/* <div className='flex gap-3' onClick={()=>{
            _onClick(item.item);
          }}>
              <div className={`flex h-5 w-5 justify-center items-center ${item.selected? 'bg-main-green': 'bg-gray'} rounded-full`}>
                  <FontAwesomeIcon icon={faCheck} className='text-white'></FontAwesomeIcon>
              </div>
              {item.item[`${displayKey}`]}
          </div> */}
            <div
              onClick={() => {
                _onClick(item.item)
              }}
              className={`text-sm flex w-full justify-between hover:bg-blue-300 cursor-pointer border-transparent hover:border-blue-700 border-l-2 px-2 py-2`}
            >
              <div className="flex flex-row justify-between items-center w-full">
                {item.item[`${displayKey}`]}
                <div
                  className={`flex h-5 w-5 justify-center items-center ${
                    item.selected ? 'bg-main-green' : 'bg-gray'
                  }`}
                >
                  <FontAwesomeIcon icon={faCheck} className="text-white"></FontAwesomeIcon>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {selectedItems.length > 0 ? (
        <div className="flex w-full pt-2 justify-center items-center">
          <ButtonComponent
            title="Capture Selection(s)"
            onClick={() => {
              onClick(selectedItems)
              //set selected items list to 0 to avoid duplicates
              setSelectedItems([])
            }}
          />
        </div>
      ) : null}
    </div>
  )
}

export default SubItemsComponent
