import { setGroupState } from '../actions/group'
import { get_filtered_group_dashboard, get_group_dashboard } from '../api/calls'
import { set_default_group } from '../api/calls/groups/default_group'
import { delete_group } from '../api/calls/groups/delete_group'
import { get_all_groups } from '../api/calls/groups/get_all_groups'
import { get_groups } from '../api/calls/groups/get_groups'
import { remove_learners } from '../api/calls/groups/remove_learner'
import facilitator from '../components/facilitators/facilitator'
import { selectGroups } from '../components/groups'
import { setNotification } from './notification'

export const getGroups = () => {
  return dispatch => {
    get_groups()
      .then(res => {
        if (res.data.success) {
          dispatch(
            setGroupState({
              groupsLoaded: true,
              groups: res.data.content.map(group => ({
                ...group,
                facilitators: group.facilitators.map(facilitator => facilitator.full_name)
              }))
            })
          )
        }
      })
      .catch(error => {})
  }
}

export const getFilteredGroupDashboard = (query, sort) => {
  return (dispatch, getState) => {
    const groups = selectGroups(getState())
    get_filtered_group_dashboard(query, sort)
      .then(res => {
        if (res.data.success) {
          dispatch(
            setGroupState({
              ...groups,
              groupsLoaded: true,
              groupDashboard: res.data.content
            })
          )
        }
      })
      .catch(error => {})
  }
}
export const deleteGroup = group_id => {
  return dispatch => {
    delete_group(group_id)
      .then(res => {
        if (res.data.success) {
          dispatch(
            setNotification({
              title: 'Success',
              messages: ['You have successfully deleted the group.']
            })
          )
          dispatch(getGroups())
        }
      })
      .catch(error => {})
  }
}

export const removeLearnerFromGroup = payload => {
  return dispatch => {
    remove_learners(payload)
      .then(res => {
        if (res.data.success) {
          dispatch(
            setNotification({
              title: 'Success',
              messages: ['You have successfully removed learner(s) from the group.']
            })
          )
        }
      })
      .catch(error => {})
  }
}

export const makeGroupDefault = group_id => {
  return dispatch => {
    set_default_group(group_id)
      .then(res => {
        if (res.data.success) {
          dispatch(
            setNotification({
              title: 'Success',
              messages: ['Successfully set group to default.']
            })
          )
          dispatch(getGroups())
        }
      })
      .catch(error => {})
  }
}
