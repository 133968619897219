import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getVenues, saveVenue } from '../../../asyncActions/venues'
import BackDropComponent from '../../UIComponents/components/form/backdrop'
import TextFieldComponent from '../../UIComponents/components/textfield/textfiled'
import ButtonComponentContained from '../../UIComponents/components/buttons/buttonContained'
import { faPen, faSave } from '@fortawesome/pro-light-svg-icons'

const CreateUpdateVenueComponent = ({
  isEdit = false,
  setShow,
  open = false,
  formData,
  venues
}) => {
  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
    city: '',
    address_line_1: '',
    address_line_2: '',
    area_code: '',
    room_number: ''
  })
  const dispatch = useDispatch()
  const [isBusy, setIsBusy] = useState(false)

  const onFormValueChange = value => {
    setFormValues({ ...formValues, ...value })
  }

  useEffect(() => {
    if (isEdit) {
      setFormValues(formData)
    } else {
      setFormValues({
        name: '',
        description: '',
        city: '',
        address_line_1: '',
        address_line_2: '',
        area_code: '',
        room_number: ''
      })
    }
  }, [formData, isEdit])

  const _onSave = () => {
    if (isEdit) {
      const id = formValues._id
      delete formValues._id
      dispatch(saveVenue({ ...formValues, id }))
      dispatch(getVenues())
    } else {
      dispatch(saveVenue(formValues))
      dispatch(getVenues())
    }
    setIsBusy(false)
    setShow(false)
  }

  return (
    <BackDropComponent
      isOpen={open}
      onBackDropClose={() => {
        setShow(false)
      }}
      title={`${isEdit ? 'Update' : 'Create'} Venue`}
    >
      <div className="flex flex-col gap-2">
        <TextFieldComponent
          label="Name"
          value={formValues.name}
          onChange={v =>
            onFormValueChange({
              name: v
            })
          }
        />

        <TextFieldComponent
          label="Description"
          value={formValues.description}
          onChange={v =>
            onFormValueChange({
              description: v
            })
          }
        />

        <TextFieldComponent
          label="City"
          value={formValues.city}
          onChange={v =>
            onFormValueChange({
              city: v
            })
          }
        />
        <TextFieldComponent
          label="Address Line 1"
          value={formValues.address_line_1}
          onChange={v =>
            onFormValueChange({
              address_line_1: v
            })
          }
        />
        <TextFieldComponent
          label="Address Line 2"
          value={formValues.address_line_2}
          onChange={v =>
            onFormValueChange({
              address_line_2: v
            })
          }
        />
        <TextFieldComponent
          label="Area Code"
          value={formValues.area_code}
          onChange={v =>
            onFormValueChange({
              area_code: v
            })
          }
        />
        <TextFieldComponent
          label="Building"
          value={formValues.building}
          onChange={v =>
            onFormValueChange({
              building: v
            })
          }
        />
        <TextFieldComponent
          label="Room Number"
          value={formValues.room_number}
          onChange={v =>
            onFormValueChange({
              room_number: v
            })
          }
        />

        <div className=" flex justify-end items-center pt-4">
          <ButtonComponentContained
            title={`${isEdit ? 'Update' : 'Add'} Venue`}
            loading={isBusy}
            disabled={isBusy}
            startIcon={isEdit ? faPen : faSave}
            onClick={() => {
              setIsBusy(true)
              _onSave()
            }}
          />
        </div>
      </div>
    </BackDropComponent>
  )
}

export default CreateUpdateVenueComponent
