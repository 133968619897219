import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core'
import React from 'react'

function CheckboxComponent({
  disabled = false,
  required = false,
  onClick = () => {},
  onChange = () => {}
}) {
  return (
    <FormGroup>
      <div className="flex items-center">
        <FormControlLabel
          disabled={disabled}
          control={
            <Checkbox defaultChecked value={required} onClick={onClick} onChange={onChange} />
          }
          label="LabelX"
        />
        {required ? <span className="text-red-500"> *</span> : null}
      </div>
    </FormGroup>
  )
}

export default CheckboxComponent
