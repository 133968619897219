import { Paper } from '@material-ui/core'
import React, { useEffect } from 'react'
import TextFieldComponent from '../../../UIComponents/components/textfield/textfiled'
import { set } from 'date-fns'
import SingleSelectComponent from '../../../UIComponents/components/select/singleSelect'
import RadioButtonComponent from '../../../UIComponents/components/radio/radioButtton'
import CountrySelect from '../../../UIComponents/components/autoselect/countrySelectAutocomplete'
import { groupsAndEventsValidationData, validateForm } from './constants'

const GroupsEventsInformation = ({
  onChange = () => {},
  formData = {},
  groups = [],
  events = [],
  onValidate = e => {}
}) => {
  const [formValues, setFormValues] = React.useState(formData)

  const handleChange = e => {
    setFormValues({ ...formValues, ...e })
  }

  useEffect(() => {
    onChange(formValues)
    const valid = validateForm(groupsAndEventsValidationData, formValues)
    console.log('valid', valid)
    onValidate(valid)
  }, [formValues])

  return (
    <div className="flex flex-col gap-4">
      <Paper className="p-4 flex flex-col gap-4">
        <div className="font-sans font-extrabold text-2xl">Groups and Events</div>
        <div className="grid grid-cols-2 gap-4">
          <SingleSelectComponent
            displayKey={'name'}
            title={'Select Group'}
            errorMessage={'Groups Not Found'}
            required={true}
            data={groups}
            onClick={e => {
              console.log('e', e)
              handleChange({
                group_id: e?._id
              })
            }}
          />
          <SingleSelectComponent
            displayKey={'event_name'}
            title={'Select Event'}
            errorMessage={'Events Not Found'}
            required={true}
            data={events}
            onClick={e => {
              console.log('e', e)
              handleChange({
                event_id: e?._id
              })
            }}
          />
        </div>
      </Paper>
    </div>
  )
}

export default GroupsEventsInformation
