import React, { useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getFacilitators, getGroups, getPrograms } from '../../asyncActions'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import { selectNotificationState } from '../notifications/selectors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { get_recruits } from '../../api/calls/recruitment/get_recruits'
import BreadCrumbs from './components/breadCrumbs'
import { selectGroups, selectGroupsLoaded } from '../groups'
import { selectFacilitators } from '../facilitators'
import TableComponent from '../UIComponents/components/tables/table'
import RecruitmentTableComponent from '../UIComponents/components/tables/RecruitmentTable'
import { setAlert } from '../../asyncActions/alert'
import { Box, Fab } from '@material-ui/core'
import { faNotebook, faPeople } from '@fortawesome/pro-light-svg-icons'

const RecruitmentComponent2 = ({}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const notificationData = useSelector(selectNotificationState)
  const [selectedRecruit, setSelectedRecruit] = useState({})
  const [buttonIndex, setButtonIndex] = useState(0)
  const [recruits, setRecruits] = useState([])
  const groups = useSelector(selectGroups)
  const groupsLoaded = useSelector(selectGroupsLoaded)
  const facilitators = useSelector(selectFacilitators)
  const facilitatorsLoaded = useSelector(selectFacilitators)
  const [recruitmentGroups, setRecruitmentGroups] = useState([])

  const _onButtonIndexChange = index => {
    setButtonIndex(index)
  }

  useEffect(() => {
    dispatch(getGroups())
    dispatch(getFacilitators())
  }, [])

  useEffect(() => {
    if (buttonIndex == 1) {
      dispatch(getPrograms())
    }
  }, [buttonIndex])

  return (
    // <div></div>
    <TableContentWrapper notificationData={notificationData}>
      {/* ----------Table */}
      <div className="flex flex-row justify-between">
        <div className="flex flex-row gap-2">
          <BreadCrumbs
            data={{
              label: 'Groups',
              icon: faPeople
            }}
            onClick={() => {
              if (buttonIndex > 0) {
                setButtonIndex(0)
              }
            }}
            enabled={buttonIndex >= 0}
            selected={buttonIndex == 0}
          />
          <BreadCrumbs
            data={{
              label: 'Recruits',
              icon: faPeople
            }}
            onClick={() => {
              if (buttonIndex > 1) {
                setButtonIndex(1)
              }
            }}
            enabled={buttonIndex >= 1}
            selected={buttonIndex == 1}
          />
          <BreadCrumbs
            data={{
              label: 'Recruitment',
              icon: faNotebook
            }}
            selected={buttonIndex == 2}
          />
        </div>
      </div>
      {buttonIndex == 0 && (
        <TableComponent
          displayKeys={[
            { value: 'name', label: 'Group Name' },
            { value: 'description', label: 'Description' },
            { value: 'allocation_code', label: 'Allocation Code' },
            {
              value: 'facilitators',
              label: 'Facilitators',
              isArray: true
            },
            {
              value: 'total_applicants',
              label: 'Total Applicants'
            }
          ]}
          data={groups}
          onRowClick={item => {
            get_recruits(item._id).then(e => {
              if (e.data.success) {
                setRecruits(e.data.content ?? [])
                setButtonIndex(1)
              }
            })
          }}
        />
      )}
      {buttonIndex == 1 && (
        <TableComponent
          displayKeys={[
            { value: 'user_name', label: 'Name' },
            { value: 'user_surname', label: 'Surname' },
            { value: 'group_name', label: 'Group' },
            { value: 'user_cell_number', label: 'Phone Number' },
            { value: 'user_email', label: 'Email' }
          ]}
          search={true}
          data={recruits}
          onRowClick={item => {
            setSelectedRecruit(item)
            setButtonIndex(2)
          }}
          actions={[
            {
              label: 'Recruit',
              action: item => {
                setSelectedRecruit(item)
                setButtonIndex(2)
              },
              icon: faPen
            }
          ]}
        />
      )}
      {buttonIndex == 2 && (
        <div>
          <RecruitmentTableComponent
            selectedRecruit={selectedRecruit}
            recruits={recruits}
            onSmsSuccess={e => {
              if (e) {
                console.log('sms sent')
                dispatch(setAlert({ message: 'SMS Sent', type: 'success' }))
              }
            }}
            onFinished={() => {
              _onButtonIndexChange(0)
              dispatch(getGroups())
            }}
            onSuccess={recruit => {
              const filteredRecruits = recruits.filter(e => e._id !== recruit._id)
              setRecruits(filteredRecruits)
            }}
            onNextRecruit={recruit => {
              const index = recruits.findIndex(e => e._id == recruit._id)
              if (index < recruits.length - 1) {
                setSelectedRecruit(recruits[index + 1])
              }
            }}
          />
        </div>
      )}
      <div className="pt-8 flex items-end justify-end">
        <Box sx={{ '& > :not(style)': { m: 1 } }}>
          <Fab
            variant="extended"
            size="large"
            color="primary"
            onClick={() => {
              history.push(`/student-application`)
            }}
          >
            <FontAwesomeIcon icon={faPen} />
            <span className="pl-2">Add New Recruit</span>
          </Fab>
        </Box>
      </div>
    </TableContentWrapper>
  )
}
const mapStateToProps = state => {
  return {
    facilitatorState: state.facilitatorState,
    mainState: state.mainState,
    recruiterState: state.recruiterState,
    learnerState: state.learnerState,
    paginateState: state.paginateState,
    groupState: state.groupState
  }
}

export default connect(mapStateToProps)(RecruitmentComponent2)
