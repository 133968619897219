const { green, red, orange } = require( "@material-ui/core/colors" );

module.exports = {
  purge: [],
  content: [ 
    "./src/**/*.{js,jsx,ts,tsx}",
    "./node_modules/react-tailwindcss-datepicker/dist/index.esm.js"
  ],
  darkMode: false, // or 'media' or 'class'
  important: false,
  theme: {
    borderColor: (theme) => ({
      ...theme('colors'),
      gray: '#c6ced8',
      homeBorder: '#dee4ea',
      dark: 'rgba(151,151,151,0.23)',
      indigo: '#004f84',
      main:"#96dade",
      green: '#3bc47f',
      red: '#d22d2d',
      yellow: '#FFB800',
      orange:  '#e1a91e',

    }),
    extend: {
      textColor:{
        white: '#FFFFFF',
        dark: '#20293C',
        gray: '#979797',
        orange: '#fc5f39',
        green: '#00A944',
        yellow: '#FFB800',
        indigo: '#004f84', 
      },
      backgroundColor: {
        gray: '#f1f0f0',
        // orange: '#fc5f39',
        red: '#d22d2d',
        orange: '#e1a91e',
        main: '#ebe9e3',
        white: '#ffffff',
        theme: '#59a6a6',
        dark: '#2F3B52',
        tableheader: '#1F487E',
        actionButton: '#829CBC',
        card1: '#96dade',
        cardIcon1: '#a5dee2',
        card2: '#009fde',
        cardIcon2: '#26ade3',
        activeMenu: '#96dade',
        card3: '#fc5f39',
        cardIcon3: '#fc7657',
        card4: '#004f84',
        cardIcon4: '#256996',
        // green: '#96dade',
        green: '#3bc47f',
        buttonColor: '#000000',
        navigation: '#ebe9e3',
        overlay: 'rgba(0,0,0,0.8)',
        popup: '#191E2D',
        indigo: '#004f84',
        'main-green': '#00A944',
        'main-light': '#f1f1f1',
        'textinput-dark': '#2F3B52',
        'milky-white': '#ebe9e3',
      },
      placeholderColor: {
        gray: '#979797',
      },
      minWidth: {
        'button-dropdown': '200px',
      },
      maxHeight: {
        'dropdown-content': '200px',
      },
    },
  },
  variants: {
    extend: {},
  },
  plugins: [],
  purge: ["./src/**/*.{js,jsx,ts,tsx}", "./public/index.html"],
}
