export const learnerAttendanceInfoSkeleton = {
  attendance_percentage: '',
  human_oi: '',
  business_oi: '',
  programs: [],
  recruitment: {}
}

export const catchUpDayTableColumn = [
  { key: 'attendance_date', date: true, display: 'Date Absent' },
  { key: 'interaction_name', display: 'Interaction' },
  { key: 'program_name', display: 'Programme' }
]