import * as React from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'

export default function RadioButtonComponent({
  buttons = [],
  onClick = () => {},
  title,
  displayKey,
  disabled = false,
  color = '#000',
  row = false,
  selectedValue = null,
  required = false
}) {
  const [selected, setSelected] = React.useState(selectedValue)

  React.useEffect(() => {
    setSelected(selectedValue) // Update the state when selectedValue prop changes
  }, [selectedValue])

  const handleChange = event => {
    const value = event.target.value
    setSelected(value)
    const selectedButton = buttons.find(button => button[displayKey] === value)
    onClick(selectedButton) // Trigger the onClick callback
  }

  return (
    <FormControl>
      <FormLabel
        style={{
          color: color,
          fontSize: 14,
          fontWeight: 400,
          lineHeight: 1.5
        }}
        id="demo-row-radio-buttons-group-label"
      >
        <div className="flex">
          {title} {required ? <span className="pl-1">*</span> : null}
        </div>
      </FormLabel>
      <RadioGroup
        row={row}
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={selected}
        onChange={handleChange}
        style={
          {
            //pad to the left and align with the form label
            // marginLeft: '1.5rem'
          }
        }
      >
        {buttons.map((button, index) => (
          <FormControlLabel
            key={index}
            value={button[displayKey]}
            control={<Radio />}
            label={button[displayKey]}
            onClick={() => onClick(button)}
            disabled={disabled}
            style={{
              color: color,
              fontSize: 14,
              fontWeight: 400,
              lineHeight: 2
            }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
