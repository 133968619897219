import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import ButtonComponentContained from '../buttons/buttonContained'
import DatePickerComponent from '../calendar/date'
import { useDispatch, useSelector } from 'react-redux'
import { selectPrograms } from '../../../programs/selectors'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import { get_configurations } from '../../../../api/calls/configurations/get_configurations'
import { send_sms } from '../../../../api/calls/recruitment/send_sms'
import { enroll_recruitee } from '../../../../api/calls/recruitment/enroll_recruitee'
import { setAlert } from '../../../../asyncActions/alert'
import { get_recruitee, save_recruitee } from '../../../../api/calls'
import moment from 'moment'
import RecruitmentSelectComponent from '../../../recruitment/components/recruitmentSelect'
import SingleSelectComponent from '../select/singleSelect'

const displayLabels = ['Description', 'Status', 'Date Created', 'Comments']
const dayTypes = [
  {
    label: 'Open Day',
    key: 'open_day'
  },
  {
    label: 'OI Workshop',
    key: 'oi_workshop'
  },
  {
    label: 'Interview',
    key: 'interview'
  },
  {
    label: 'Tech Interview',
    key: 'tech_interview'
  },
  {
    label: 'Green Light Survey',
    key: 'green_light_survey'
  },
  {
    label: 'Shadow Week',
    key: 'shadow_week'
  }
]

const RecruitmentTableComponent = ({
  selectedRecruit = {},
  recruits = [],
  onFinished = () => {},
  onSmsSuccess = () => {},
  onSuccess = () => {},
  onNextRecruit = () => {}
}) => {
  const programs = useSelector(selectPrograms)
  const [sms, setSms] = useState({})
  const [formValues, setFormValues] = useState({})
  const [selectedProgram, setSelectedProgram] = useState()
  //This is used to determine whether or not to show the enrol button
  //enrolment is only possible if a program has been selected and the user has been saved
  const [isSaved, setIsSaved] = useState(false)
  const dispatch = useDispatch()
  const [enrolmentDate, setEnrolmentDate] = useState(new Date())

  React.useEffect(() => {
    _getConfigurations()
  }, [])

  useEffect(() => {
    if (selectedRecruit) {
      setFormValues(selectedRecruit)
    }
  }, [selectedRecruit])

  const rowStyle = {
    fontWeight: 'bold',
    fontSize: '14px'
  }

  const _onFormValueChange = v => {
    setFormValues({
      ...formValues,
      ...v
    })
  }
  const _getConfigurations = async () => {
    await get_configurations().then(res => {
      if (res.data.success) {
        setSms(res.data.content)
      }
    })
  }

  const _sendSMS = async data => {
    await send_sms(data).then(response => {
      if (response.data.success) {
        onSmsSuccess(true)
      }
    })
  }

  const enrol = async date => {
    if (!selectedProgram?._id) {
      alert('Please select a program')
      return
    }
    const data = {
      recruit_id: formValues?._id,
      program_id: selectedProgram?._id,
      program_start_date: date
    }

    await enroll_recruitee(data)
      .then(response => {
        const message = response.data.success ? 'Enrollment successful.' : 'Already enrolled.'
        dispatch(setAlert({ show: true, success: response.data.success, message }))
        if (response.data.success) {
          //call the next in line method
          _onNextInLineChange()
          const index = recruits.findIndex(e => e._id === formValues?._id)
          onSuccess(recruits[index])
        }
      })
      .catch(error => {})

    //default values
    setEnrolmentDate(new Date())
    setSelectedProgram()
    setIsSaved(false)
  }

  const _onSave = async () => {
    dispatch(setAlert({ success: false, show: false }))

    delete formValues.recruitment_information

    await save_recruitee(formValues).then(res => {
      const alertMessage = res.data.success
        ? 'Recruitment statuses successfully updated.'
        : 'Failure'
      dispatch(setAlert({ success: res.data.success, show: true, message: alertMessage }))
      setIsSaved(true)
    })
  }

  useEffect(() => {
    console.log('FORM VALUES', formValues)
  }, [formValues])

  //This method is called when the user has been enrolled and lines up the next recruit
  const _onNextInLineChange = async () => {
    const index = recruits.findIndex(e => e._id === formValues?._id)
    console.log('index of selected recruit', index)
    if (index > -1) {
      if (index + 1 < recruits.length) {
        const nextRecruit = recruits[index + 1]
        console.log('next recruit', nextRecruit)

        await get_recruitee(nextRecruit?._id).then(e => {
          console.log('next recruit info fetched', e)
          if (e.data.success) {
            setFormValues(e.data.content)
          }
        })

        // Check if the next recruit has been passed to form values
        // If not then set the next recruit to form values
        if (formValues?._id !== nextRecruit?._id) {
          setFormValues(nextRecruit)
        }

        // Call the onNextRecruit callback with the next recruit
        onNextRecruit(nextRecruit)
      } else {
        onFinished()
      }
    } else {
      console.log('onFinished triggered')
      onFinished()
    }
  }

  return (
    <div className="flex flex-col gap-8 p-2">
      <div className="flex flex-col gap-2">
        <div className="flex flex-row justify-between">
          <div className="font-sans font-bold text-l">
            Recruitment for{' '}
            <span className="text-blue-500">{`${formValues?.user_name} ${formValues?.user_surname}`}</span>
          </div>
          <div className="flex gap-2">
            {isSaved && enrolmentDate && selectedProgram && (
              <ButtonComponentContained
                title="Enroll"
                onClick={() => {
                  if (isSaved && enrolmentDate && selectedProgram) {
                    enrol(enrolmentDate)
                  }
                }}
              />
            )}
            <ButtonComponentContained
              title="Save"
              onClick={_onSave}
              startIcon={faSave}
              bgColor="red"
            />
          </div>
        </div>
        <hr />
      </div>

      <div className="flex flex-col gap-2">
        <p className="font-sans font-extrabold">1. Please select a programme</p>
        <SingleSelectComponent
          displayKey={'program_name'}
          data={programs}
          title={'Select a Programme'}
          errorMessage={'Programmes Not Found'}
          onClick={e => {
            console.log('e', e)
            _onFormValueChange({ program_id: e._id })
            setSelectedProgram(e)
          }}
        />
      </div>
      <div className="flex flex-col gap-2">
        <p className="font-sans font-extrabold">
          2. Please select an enrolment date (default is today's date)
        </p>
        <DatePickerComponent
          onChange={e => {
            setEnrolmentDate(e)
          }}
        />
      </div>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer>
          <Table>
            <TableHead
              style={{
                backgroundColor: '#59a6a6',
                color: '#fff'
              }}
            >
              <TableRow>
                {displayLabels.map((label, index) => (
                  <TableCell key={index} style={{ ...rowStyle, color: '#fff' }}>
                    {label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dayTypes.map((d, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell style={rowStyle}>{d.label}</TableCell>
                    <TableCell style={rowStyle}>
                      <RecruitmentSelectComponent
                        data={[
                          { label: 'Yes', value: 'yes', color: 'green' },
                          { label: 'No', value: 'no', color: 'red' },
                          { label: 'Maybe', value: 'maybe', color: 'orange' }
                        ]}
                        displayKey={'label'}
                        title={'Select Status'}
                        selected={formValues?.[d.key]?.status}
                        onClick={e => {
                          _onFormValueChange({
                            [d.key]: {
                              ...formValues[d.key],
                              status: e.value
                            }
                          })
                        }}
                        onSendSMS={e => {
                          console.log('e', e)
                          console.log('sending sms...')

                          const smsBody = {
                            cell_number: formValues?.user_cell_number
                          }

                          if (e.value === 'yes') {
                            console.log('sending YES sms...')
                            smsBody.message = sms?.yes_sms
                              .replace(
                                '$full_name',
                                `${formValues?.user_name} ${formValues?.user_surname}` ?? ''
                              )
                              .replace('$description', d.label)
                              .replace('$date', Date.now ?? '')
                          } else if (e.value === 'no') {
                            console.log('sending NO sms...')
                            smsBody.message = sms?.no_sms
                              .replace(
                                '$full_name',
                                `${formValues?.user_name} ${formValues?.user_surname}` ?? ''
                              )
                              .replace('$description', d.label)
                              .replace('$date', Date.now ?? '')
                          }

                          console.log('smsBody', smsBody)

                          _sendSMS(smsBody)
                        }}
                      />
                    </TableCell>
                    <TableCell style={rowStyle}>
                      <DatePickerComponent
                        title={'Select Date'}
                        value={formValues?.[d.key]?.date}
                        onChange={e => {
                          _onFormValueChange({
                            [d.key]: {
                              ...formValues[d.key],
                              date: moment(e).format('YYYY-MM-DD')
                            }
                          })
                        }}
                      />
                    </TableCell>
                    <TableCell style={rowStyle}>
                      <TextField
                        id="outlined-multiline-static"
                        label="Comment"
                        multiline
                        maxRows={4}
                        defaultValue={formValues?.[d.key]?.comment}
                        onChange={e => {
                          console.log('e', e.target.value)
                          _onFormValueChange({
                            [d.key]: {
                              ...formValues[d.key],
                              comment: e.target.value
                            }
                          })
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}

export default RecruitmentTableComponent
