import React, { useState } from 'react'

import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { setUserState } from '../../actions/user'
import { sign_in } from '../../api/calls/sign_in'
import logo from '../../assets/images/black_logo.png'
import Img from '../../assets/images/Signin-bg-image.png'
import Img2 from '../../assets/images/carousel/flooring.jpg'
import Img3 from '../../assets/images/carousel/agri.jpg'
import Img4 from '../../assets/images/carousel/business.jpg'
import Img5 from '../../assets/images/carousel/rug_vendor.jpg'
import Img6 from '../../assets/images/carousel/veg.jpg'

// import agri from '../../assets/images/carousel/agri.jpg'
// import business from '../../assets/images/carousel/business.jpg'

import ButtonControl from '../../controls/button/button'
import LoaderControl from '../../controls/loader/loader'
import NotificationControl from '../../controls/notification/notification'
import TextInputControl from '../../controls/textinput/textinput'
import { isDevMode } from '../../utils/utils'
import ForgotPasswordContainer from './forgot/forgot'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

const LoginComponent = props => {
  const [email, setEmail] = useState(isDevMode() ? 'chris@doshex.com' : '')
  const [password, setPassword] = useState(isDevMode() ? 'doshtest' : '')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [signInType, setSignInType] = useState(undefined)
  const [showExistingPassword, setShowExistingPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const cookies = new Cookies()
  const [notificationData, setNotificationData] = useState({
    title: '',
    messages: [],
    type: 'ok' | 'confirm'
  })
  const [isBusy, setIsBusy] = useState(false)

  const history = useHistory()
  let { path, url } = useRouteMatch()
  const dispatch = useDispatch()
  const userState = useSelector(state => state.userState)

  const signIn = () => {
    setIsBusy(true)
    sign_in({
      user_email: email,
      user_password: password,
      confirm_password: confirmPassword,
      sign_in_type: signInType
    }).then(response => {
      setIsBusy(false)
      if (!response.data.success && response.data.content) {
        switch (response.data.content.type) {
          case 'ADD_PASSWORD':
            setSignInType(response.data.content.type)
            setShowNewPassword(true)
            break
          case 'INVALID_PASSWORD':
            setNotificationData({
              title: 'Invalid',
              messages: ['The password you entered for this account is invalid.']
            })
            break
          case 'ENTER_PASSWORD':
            setShowExistingPassword(true)
            break
          case 'NOT_FOUND':
            setNotificationData({
              title: 'Not found',
              messages: ['This account was not found.']
            })
            break
          default:
            //Show popup showing message.
            break
        }
      } else {
        if (response.data.content.account.content.type_code === 'LEARNER-ACC') {
          setNotificationData({
            title: 'Not found',
            messages: ['This account was not found.']
          })
        } else {
          //Success
          const { token, user, profile, permissions, account } = response.data.content

          cookies.set(
            'veb-user-cookie',
            {
              token: token,
              ...user,
              id: user._id,
              expiry_time: moment().add(1, 'hour'),
              type: account.content.type_code
            },
            {
              maxAge: 3600, //55 minutes, in seconds.
              sameSite: true
            }
          )

          dispatch(
            setUserState({
              ...userState,
              token: token,
              ...user,
              id: user._id
            })
          )

          history.push(`/dashboard`)
        }
      }
    })
  }

  return (
    <div className="component-login w-screen h-screen lg:flex lg:flex-column">
      <div className="flex flex-auto justify-center items-center">
        {/* <img className="lg:w-full lg:h-screen object-cover" src={Img} /> */}
        <Carousel
          showThumbs={false}
          autoPlay={true}
          swipeable={true}
          stopOnHover={true}
          showArrows={false}
          showIndicators={false}
          showStatus={false}
        >
          <img className="lg:w-full lg:h-screen object-cover" src={Img6} />
          <img className="lg:w-full lg:h-screen object-cover" src={Img4} />
          <img className="lg:w-full lg:h-screen object-cover" src={Img5} />
          <img className="lg:w-full lg:h-screen object-cover" src={Img} />
          <img className="lg:w-full lg:h-screen object-cover" src={Img2} />
          <img className="lg:w-full lg:h-screen object-cover" src={Img3} />
        </Carousel>
        <div className="absolute lg:text-6xl text-5xl text-white text-left lg:bottom-5 left-5 font-bold p-4">
          The Go Exchange.
        </div>
      </div>
      <div className="lg:max-w-lg h-fill  bg-main p-8 flex flex-col items-center justify-center">
        <Switch>
          <Route
            exact
            path={path}
            render={() => (
              <div>
                <img className="object-contain max-w-lg px-20 pb-20 pt-10" src={logo} />

                <div className="flex flex-col space-y-5 w-full max-w-lg px-4">
                  <TextInputControl
                    title="Email / ID Number"
                    value={email}
                    placeholder="Email"
                    onChange={v => setEmail(v)}
                  />
                  <TextInputControl
                    title="Password"
                    value={password}
                    type="password"
                    placeholder="Password"
                    onChange={v => setPassword(v)}
                  />
                  {showNewPassword && (
                    <TextInputControl
                      title="Confirm Password"
                      value={confirmPassword}
                      type="password"
                      placeholder="Confirm Password"
                      onChange={v => setConfirmPassword(v)}
                    />
                  )}
                  <ButtonControl
                    style="rounded-full bg-buttonColor"
                    placeholder="SIGN IN"
                    isBusy={isBusy}
                    type="primary"
                    onClick={v => signIn()}
                  />
                  <ButtonControl
                    placeholder="Forgot Your Password?"
                    isBusy={false}
                    type="link"
                    onClick={v => history.push(`${path}/forgot-passsword`)}
                  />
                </div>
              </div>
            )}
          />

          <Route path={`${path}/forgot-passsword`} render={() => <ForgotPasswordContainer />} />
        </Switch>
      </div>

      <LoaderControl show={isBusy} />
      <NotificationControl notificationData={notificationData} />
    </div>
  )
}

export default LoginComponent
