import React, { useEffect, useState } from 'react'
import InputComponent from '../../customComponents/input'
import ButtonComponent from '../../customComponents/button'
import PopupControl from '../../../controls/popup/popup'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import { issue_ved_days } from '../../../api/calls/issue_ved_days'
import { use } from 'echarts'
import { issue_doctors_note } from '../../../api/calls/issue_doctors_note'
import FilesComponent from '../../customComponents/files'
import { upload_file } from '../../../api/calls'
import { toTitleCase } from '../../../utils'

const IssueDoctorsNote = ({ show, setShow, data, showAlert, onSuccess = () => {} }) => {
  const [formValues, setFormValues] = useState({
    type: 'Doctors Note'
  })
  const [canSave, setCanSave] = useState(false)

  const _onInputChange = e => {
    setFormValues({ ...formValues, ...e })
  }

  const _validateForm = () => {
    if (formValues?.doctors_note) {
      setCanSave(true)
    } else {
      setCanSave(false)
    }
  }

  const _onSave = async () => {
    //api call...
    await issue_doctors_note(formValues).then(async res => {
      if (res?.data.success) {
        showAlert({
          type: 'success',
          message: 'Issue doctors note issued successfully'
        })
        setShow(false)
        onSuccess()
      } else {
        showAlert({
          type: 'error',
          message: 'Failed to issue doctors note'
        })
      }
    })
  }

  useEffect(() => {
    _validateForm()
  }, [formValues])

  useEffect(() => {
    console.log('formValues', formValues)
    _validateForm()
  }, [formValues])

  useEffect(() => {
    _onInputChange({
      ...data,
      doctors_note: {
        name: `Doctors Note - ${
          data.register?.interaction?.interaction_name
            ? toTitleCase(data.register?.interaction?.interaction_name)
            : '-'
        }`
      }
    })
  }, [data])

  return (
    <div>
      <PopupControl
        title="Issue Doctors Note"
        isOpen={show}
        close={{
          showClose: true,
          onClose: () => {
            setShow(false)
          }
        }}
      >
        <div className="w-full flex flex-col gap-4">
          <InputComponent
            theme="dark"
            title="Document Name"
            value={formValues?.doctors_note?.name}
            disabled={true}
            // onChange={v =>
            //   setValues({
            //     ...values,
            //     name: v
            //   })
            // }
          />
          <FilesComponent
            label="Attach Doctors Note"
            onChange={v => {
              upload_file({ file: v, name: v.name }).then(res => {
                if (res.data.success) {
                  _onInputChange({
                    doctors_note: {
                      ...formValues.doctors_note,
                      ...res.data.content
                    }
                  })
                }
              })
            }}
          />
          <ButtonComponent
            title="Save"
            disabled={!canSave}
            icon={faSave}
            isBusy={false}
            type="primary"
            onClick={_ => {
              _onSave()
            }}
          />
        </div>
      </PopupControl>
    </div>
  )
}

export default IssueDoctorsNote
