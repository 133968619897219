import { editProgramState, setProgramState } from '../actions/programs'
import { create_program } from '../api/calls'
import { get_all_programs } from '../api/calls/programs/get_all_programs'
import { get_program_users } from '../api/calls/programs/get_program_users'
import { get_program_venues } from '../api/calls/programs/get_program_venues'
import { update_program } from '../api/calls/programs/update_program'
import { resetAlert, setAlert } from './alert'

export const getPrograms = () => {
  return dispatch => {
    get_all_programs()
      .then(res => {
        if (res.data.success) {
          dispatch(
            setProgramState({
              programsLoaded: true,
              programs: res.data.content
            })
          )
        }
      })
      .catch(error => {})
  }
}

export const createProgram = data => {
  return dispatch => {
    create_program(data)
      .then(res => {
        if (res.data.success) {
          dispatch(getPrograms())
          dispatch(
            setAlert({
              message: 'Programme created successfully!',
              type: 'success'
            })
          )
        } else {
          dispatch(
            setAlert({
              message: 'Programme creation failed!',
              type: 'error'
            })
          )
        }
        dispatch(resetAlert())
      })
      .catch(error => {})
  }
}

export const updateProgram = data => {
  return dispatch => {
    update_program(data)
      .then(res => {
        if (res.data.success) {
          dispatch(getPrograms())
          dispatch(
            setAlert({
              message: 'Programme updated successfully!',
              type: 'success'
            })
          )
        } else {
          dispatch(
            setAlert({
              message: 'Programme update failed!',
              type: 'error'
            })
          )
        }
        dispatch(resetAlert())
      })
      .catch(error => {})
  }
}

export const getProgramUsers = program => {
  return dispatch => {
    get_program_users(`id=${program._id}`)
      .then(res => {
        if (res.data.success) {
          dispatch(
            editProgramState({
              ...program,
              learners: res.data.content
            })
          )
        }
      })
      .catch(error => {})
  }
}

export const getProgramVenues = program => {
  return dispatch => {
    get_program_venues(`id=${program._id}`)
      .then(res => {
        if (res.data.success) {
          dispatch(editProgramState({ ...program, venues: res.data.content }))
        }
      })
      .catch(error => {})
  }
}
