import { faCheck, faSearch } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'

const InputComponent = props => {
  const {
    placeholder,
    title,
    value,
    disabled = false,
    theme = 'light',
    rounded = 'lg',
    onChange,
    showContent = false,
    required = false,
    size = 'normal',
    isNumber = false,
    icon,
    type,
    showErrorMessage = ''
  } = props

  const options = {
    light: {
      textColor: 'text-white',
      color: !disabled
        ? 'bg-theme focus:border-white text-white'
        : 'bg-gray text-white cursor-not-allowed',
      hover: !disabled ? 'hover:bg-blue-400' : null
    },
    dark: {
      textColor: 'text-white',
      placeholder: 'text-gray',
      color: !disabled
        ? 'bg-textinput-dark text-white focus:border-blue-400'
        : 'bg-gray text-white cursor-not-allowed',
      hover: !disabled ? 'hover:bg-blue-400' : null
    },
    form: {
      textColor: 'text-black',
      placeholder: 'text-white',
      color: !disabled
        ? 'bg-white text-sm text-black border border-gray focus:border-blue-400'
        : 'bg-gray text-white cursor-not-allowed',
      hover: !disabled ? 'hover:bg-blue-400' : null
    }
  }

  const [content, setContent] = useState(value ?? '')
  return (
    <div className="flex flex-col gap-2 w-full">
      <div className="flex w-full flex-col">
        <div className={`${options[theme].textColor} text-sm font-extrabold`}>
          {title} {required && <span className="text-red-400">*</span>}
        </div>
        {size == 'lg' ? (
          <div className="flex flex-col">
            <textarea
              className={`shadow appearance-none ${options[theme].color}  border border-gray  w-full py-2 px-3 leading-tight rounded-md focus:outline-none focus:shadow-outline`}
              rows={6}
              placeholder={placeholder ?? ''}
              value={value || ''}
              disabled={disabled}
              onChange={e => {
                onChange(e.currentTarget.value)
                setContent(e.currentTarget.value)
              }}
            />
          </div>
        ) : (
          <div className="relative">
            <div className="flex flex-col gap-2">
              <input
                className={`shadow appearance-none ${options[theme].color} rounded-md  border border-gray h-12 w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline`}
                type={`${isNumber ? 'number' : 'text'}`}
                placeholder={type === 'search' ? 'search' : placeholder ?? ''}
                value={value || ''}
                disabled={disabled}
                onChange={e => {
                  onChange(e.currentTarget.value.toString())
                  setContent(e.currentTarget.value.toString())
                }}
              />
            </div>
            {icon && (
              <FontAwesomeIcon icon={icon} className="absolute right-4 top-4" color="gray" />
            )}
            {type === 'search' && (
              <FontAwesomeIcon icon={faSearch} className="absolute right-4 top-4" color="gray" />
            )}
          </div>
        )}
      </div>
      {showErrorMessage && (
        <div className="text-red-400 text-sm italic w-full break-normal ">{showErrorMessage}</div>
      )}
      {showContent && (
        <div className="text-white text-sm italic w-full break-normal">{content}</div>
      )}
    </div>
  )
}

export default InputComponent
