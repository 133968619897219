import React, { useEffect, useState } from 'react'
import TableContentWrapper from '../../../controls/tableContentWrapper/tableContentWrapper'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getGroups, getPrograms } from '../../../asyncActions'
import { get_recruitee } from '../../../api/calls/recruitment/get_recruitee'
import { get_interview } from '../../../api/calls/get_interview'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { formatDate, toTitleCase } from '../../../utils'
import SelectDate from './selectDate'
import CommentComponent from '../../interview/components/comment'
import { save_recruitee } from '../../../api/calls/recruitment/save_recruitee'
import NotificationControl from '../../../controls/notification/notification'
import { selectNotificationState } from '../../notifications/selectors'
import ButtonComponent from '../../customComponents/button'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import DropdownButtonComponent from '../../customComponents/dropdownButton'
import moment from 'moment'
import { setAlert } from '../../../asyncActions/alert'
import { enroll_recruitee } from '../../../api/calls/recruitment/enroll_recruitee'
import { get_configurations } from '../../../api/calls/configurations/get_configurations'
import SendSMSPopUp from './sendSms'

const keys = [
  'open_day',
  'oi_workshop',
  'interview',
  'tech_interview',
  'green_light_survey',
  'shadow_week'
]

const columns = [
  { display: 'Description', key: 'name' },
  { display: 'Status', key: 'status' },
  { display: 'Date', key: 'date' },
  { display: 'Comment', key: 'comment' }
]

//--------methods--------
const getCircleStyle = status => {
  return {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    backgroundColor: status == 'yes' ? 'green' : status == 'no' ? 'red' : 'orange',
    display: 'inline-block',
    marginRight: '5px'
  }
}

const RecruitmentStatusTable = ({
  tableTitle,
  programsState,
  learner,
  onFinished,
  onSuccess,
  recruits
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [index, setIndex] = useState()
  const [formValues, setFormValues] = useState({})
  const [interview, setInterview] = useState({})
  const [selectedProgram, setSelectedProgram] = useState({})
  const [data, setData] = useState([])
  const [recruitName, setRecruitName] = useState('')
  const [showComment, setShowComment] = useState(false)
  const [enableEnrol, setEnableEnrol] = useState(false)
  const [showCalendar, setShowCalendar] = useState(false)
  const [showDatePopUp, setShowDatePopUp] = useState(false)
  const notificationData = useSelector(selectNotificationState)
  const [sms, setSms] = useState({})
  const [showSmsPopUp, setShowSmsPopUp] = useState(false)
  const [recruitmentDescription, setRecruitmentDescription] = useState({})
  const [recruitId, setRecruitId] = useState(learner?._id)
  const [isSaved, setIsSaved] = useState(false)
  const [configurationsLoaded, setConfigurationsLoaded] = useState(false)

  console.log('learner', learner)

  useEffect(() => {
    setRecruitName(`${learner?.user_name} ${learner?.user_surname}`)
    if (!configurationsLoaded) {
      _getConfigurations()
    }
    if (!programsState.programsLoaded) {
      dispatch(getPrograms())
    }
    if (learner) {
      const values = keys.map(key => ({ name: key, ...learner[key] }))
      setFormValues(learner)
      setData(values)
      get_interview(recruitId).then(e => {
        if (e.data.success) {
          setInterview(e.data.content)
        }
      })
    }
    if (formValues?.program_id != null) {
      setEnableEnrol(true)
    }
    console.log('formValues', formValues)
  }, [learner, programsState.programsLoaded, formValues?.program_id])

  const _onFormValueChange = v => {
    setFormValues(prev => ({ ...prev, ...v }))
  }

  const _onDateSelection = value => {
    const date = moment(value).format('YYYY-MM-DD')
    _onFormValueChange({ [data[index].name]: { ...formValues[data[index].name], date } })
  }

  const _onComment = value => {
    _onFormValueChange({ [data[index].name]: { ...formValues[data[index].name], comment: value } })
  }

  const _onSave = () => {
    dispatch(setAlert({ success: false, show: false }))
    save_recruitee(formValues).then(res => {
      const alertMessage = res.data.success
        ? 'Recruitment statuses successfully updated.'
        : 'Failure'
      dispatch(setAlert({ success: res.data.success, show: true, message: alertMessage }))
      setIsSaved(true)
    })
  }

  const _onEnrolmentDateSelection = date => {
    dispatch(setAlert({ show: false, success: false }))
    if (date.length > 0) {
      _onEnrol(date)
    }
  }

  const _onEnrol = date => {
    if (!selectedProgram?._id) {
      dispatch(setAlert({ show: true, success: false, message: 'Please select programme.' }))
      return
    }
    const data = {
      recruit_id: recruitId,
      program_id: selectedProgram?._id,
      program_start_date: date
    }
    enroll_recruitee(data)
      .then(response => {
        const message = response.data.success ? 'Enrollment successful.' : 'Already enrolled.'
        dispatch(setAlert({ show: true, success: response.data.success, message }))
        _onNextInLineChange()
        const index = recruits.findIndex(e => e._id === recruitId)
        onSuccess(recruits[index])
      })
      .catch(error => {})
  }

  const _onNextInLineChange = () => {
    const index = recruits.findIndex(e => e._id === recruitId)
    if (index > -1) {
      if (index + 1 < recruits.length) {
        const nextRecruit = recruits[index + 1]
        setRecruitId(nextRecruit?._id)
        setRecruitName(`${nextRecruit?.user.user_name} ${nextRecruit?.user.user_surname}`)
        get_recruitee(nextRecruit?._id).then(e => {
          if (e.data.success) {
            const values = keys.map(key => ({ name: key, ...e.data.content[key] }))
            setFormValues(e.data.content)
            setData(values)
          }
        })
      } else {
        onFinished()
      }
    } else {
      onFinished()
    }
  }

  const _getConfigurations = () => {
    get_configurations().then(res => {
      if (res.data.success) {
        setSms(res.data.content)
        if (['yes', 'no'].includes(res.data.content.status)) {
          setShowSmsPopUp(true)
        }
        setConfigurationsLoaded(true)
      }
    })
  }

  useEffect(() => {
    console.log('formValues', formValues)
  }, [formValues])

  return (
    <TableContentWrapper>
      <div className="flex flex-col gap-4">
        <div className="flex justify-between">
          <div className="flex-auto text-xl font-bold">{`${tableTitle} - ${recruitName}`}</div>
          <div className="flex justify-end items-end">
            <div className="flex-none mr-4 w-52">
              <DropdownButtonComponent
                placeholder="Select Programme"
                displayKey="program_name"
                onClick={v => {
                  setSelectedProgram(v)
                  setFormValues({ ...formValues, program_id: v?._id })
                }}
                dropdownItems={programsState?.programs}
                selectedItem={
                  programsState?.programs?.find(x => x?._id == selectedProgram?._id)?.program_name
                }
              />
            </div>
            <div className="flex-none mr-4">
              <ButtonComponent
                title="Go to Learner Profile"
                onClick={v => {
                  history.push(`/dashboard/learner-info/${recruitId}/RECRUITER-ACC`)
                }}
              />
            </div>
          </div>
        </div>

        <Table className="flex min-w-full">
          <TableHead className="bg-main text-lg">
            <TableRow>
              {columns.map(col => (
                <TableCell className="text-gray-600 uppercase text-sm leading-normal">
                  {col.display}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="text-gray-600 text-sm font-normal">
            {data.map((item, idx) => {
              return (
                <TableRow
                  key={idx}
                  className={
                    'border-b border-gray-200 hover:bg-gray-100 border-dark font-light h-8'
                  }
                >
                  <TableCell className="text-gray-600 text-xs leading-normal font-light">
                    <div className="flex italic flex-col">{toTitleCase(item?.name)}</div>
                  </TableCell>
                  <TableCell className="text-gray-600 text-xs leading-normal font-light">
                    <div>
                      <select
                        value={'Select Status'}
                        onChange={e => {
                          _onFormValueChange({
                            [data[idx].name]: {
                              ...formValues[data[idx].name],
                              status: e.target.value
                            }
                          })
                          setSms({ ...sms, status: e.target.value })
                          setRecruitmentDescription(item)
                          if (['yes', 'no'].includes(e.target.value)) {
                            setShowSmsPopUp(true)
                          }
                        }}
                      >
                        <option value="">Select Status</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                        <option value="maybe">Maybe</option>
                      </select>
                      <br />
                      {formValues[data[idx].name]?.status && (
                        <div className="py-2">
                          <div style={getCircleStyle(formValues[data[idx].name]?.status)}></div>
                          <span>{toTitleCase(formValues[data[idx].name]?.status)}</span>
                        </div>
                      )}
                    </div>
                  </TableCell>
                  <TableCell className="text-gray-600 text-xs leading-normal font-light">
                    <ButtonComponent
                      style="bg-buttonColor"
                      title={
                        formValues[data[idx].name]?.date?.length > 0
                          ? formatDate(formValues[data[idx].name]?.date)
                          : 'Select Date'
                      }
                      // isBusy='false'
                      type="secondary"
                      onClick={v => {
                        setIndex(idx)
                        setShowCalendar(true)
                      }}
                    />
                  </TableCell>

                  <TableCell className="text-gray-600 text-xs leading-normal font-light">
                    <ButtonComponent
                      style="bg-buttonColor"
                      title={
                        data[idx].name == 'interview'
                          ? formValues.interview
                            ? 'Conduct Interview'
                            : 'Interview Conducted'
                          : 'Comment'
                      }
                      // isBusy='false'
                      type="secondary"
                      onClick={v => {
                        setIndex(idx)
                        if (data[idx].name == 'interview') {
                          history.push(`/dashboard/interview/${recruitId}`)
                        }
                        setShowComment(true)
                      }}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
      <div className="flex gap-2 justify-center items-center py-8">
        <ButtonComponent
          title="Save"
          icon={faSave}
          isBusy={false}
          type="primary"
          onClick={() => {
            _onSave()
          }}
        />
        {isSaved && (
          <ButtonComponent
            title="Enroll"
            isBusy={false}
            disabled={!enableEnrol}
            type="primary"
            onClick={() => {
              setShowDatePopUp(true)
            }}
          />
        )}
      </div>
      <CommentComponent
        open={showComment}
        setShow={setShowComment}
        onClick={_onComment}
        // data={record[title]?.comment}
        data={''}
      />
      <SelectDate open={showCalendar} setShow={setShowCalendar} onClick={_onDateSelection} />
      <SelectDate
        open={showDatePopUp}
        setShow={setShowDatePopUp}
        onClick={_onEnrolmentDateSelection}
      />
      <SendSMSPopUp
        show={showSmsPopUp}
        close={setShowSmsPopUp}
        onSmsSuccess={v => {
          setShowSmsPopUp(false)
          save_recruitee(formValues)
        }}
        smsBody={{
          message:
            sms?.status != null
              ? sms.status == 'yes'
                ? sms.yes_sms
                : sms.status == 'no'
                ? sms.no_sms
                : ''
              : '',
          cell_number: learner?.user_cell_number
        }}
        recruitmentDetails={{
          full_name: `${learner?.user_name} ${learner?.user_surname}`,
          description: toTitleCase(recruitmentDescription?.name ?? '')
          //   date: formatDate(formValues[recruitmentDescription?.name]?.date, false, false, false)
        }}
      />
      <NotificationControl notificationData={notificationData} />
    </TableContentWrapper>
  )
}

const mapStateToProps = state => {
  return {
    mainState: state.mainState,
    userState: state.userState,
    learnerState: state.learnerState,
    programsState: state.programsState
  }
}

export default connect(mapStateToProps)(RecruitmentStatusTable)
