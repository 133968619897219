import { Paper } from '@material-ui/core'
import React, { useEffect } from 'react'
import TextFieldComponent from '../../../UIComponents/components/textfield/textfiled'
import { contactValidationData, validateForm } from './constants'

const ContactInformation = ({ onChange = () => {}, formData = {}, onValidate = e => {} }) => {
  const [formValues, setFormValues] = React.useState(formData)

  const handleChange = e => {
    setFormValues({ ...formValues, ...e })
  }

  useEffect(() => {
    onChange(formValues)
    const valid = validateForm(contactValidationData, formValues)
    onValidate(valid)
  }, [formValues])

  return (
    <div className="flex flex-col gap-4">
      <Paper className="p-4 flex flex-col gap-4">
        <div className="font-sans font-extrabold text-2xl">Contact Information</div>
        <div className="grid grid-cols-2 gap-4">
          <TextFieldComponent
            label="Email (Optional)"
            value={formValues?.email}
            onChange={e => {
              handleChange({ email: e })
            }}
            size="medium"
            helperText={undefined}
          />
          <TextFieldComponent
            label="Phone Number"
            value={formValues?.phone_number}
            onChange={e => {
              handleChange({ phone_number: e })
            }}
            size="medium"
            type="number"
            required={true}
            helperText={undefined}
          />
        </div>
      </Paper>
    </div>
  )
}

export default ContactInformation
