import React, { useEffect, useState } from 'react'

import { connect, useDispatch } from 'react-redux'

import { editLearnerState } from '../../../actions/learner'
import { save_document } from '../../../api/calls/documents/save_document'
import { upload_file } from '../../../api/calls/upload_file'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import BackDropComponent from '../../UIComponents/components/form/backdrop'
import FileUploadButton from '../../UIComponents/components/buttons/fileUploadButton'
import TextFieldComponent from '../../UIComponents/components/textfield/textfiled'
import ButtonComponentContained from '../../UIComponents/components/buttons/buttonContained'
import { validateForm } from '../../studentApplication/form/components/constants'

const validationData = [
  {
    name: 'name',
    required: true
  },
  {
    name: 'description',
    required: true
  },
  {
    name: 'document',
    required: true
  }
]

const AddDocumentComponent = ({ open = false, setShow, user = {}, onSuccess = () => {} }) => {
  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState(false)
  const [canSave, setCanSave] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formValues, setFormValues] = useState({})

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  useEffect(() => {
    const valid = validateForm(validationData, formValues)
    setCanSave(valid)
  }, [formValues])

  const onFormValueChange = v => {
    setFormValues({
      ...formValues,
      ...v
    })
  }

  return (
    <BackDropComponent
      isOpen={open}
      onBackDropClose={() => setShow(false)}
      title={'Upload a Document'}
      description={'Please fill in all required fields to upload a document.'}
    >
      <div className="flex flex-col gap-2">
        <TextFieldComponent
          required={true}
          label="Document Name"
          value={formValues?.name}
          onChange={v =>
            onFormValueChange({
              name: v
            })
          }
          helperText={undefined}
        />

        <TextFieldComponent
          required={true}
          label="Document Description"
          value={formValues?.description}
          onChange={v =>
            onFormValueChange({
              description: v
            })
          }
          helperText={undefined}
        />

        <FileUploadButton
          required={true}
          onChange={v => {
            upload_file({ file: v, name: v.name }).then(res => {
              if (res.data.success) {
                onFormValueChange({
                  document: res.data.content.location,
                  etag: res.data.content.etag
                })
              }
            })
          }}
        />
        <hr />

        <div className="justify-end flex gap-4">
          <ButtonComponentContained
            title={'Save'}
            startIcon={faSave}
            disabled={!canSave}
            loading={loading}
            onClick={v => {
              setLoading(true)
              save_document({
                ...formValues,
                user_id: user._id
              }).then(res => {
                if (res.data.success) {
                  setShow(false)
                  let _documents = user.documents ? user.documents : []
                  user.documents = [formValues, ..._documents]
                  dispatch(editLearnerState({ ...user }))
                  setFormValues({})
                }

                onSuccess()
                setLoading(false)
              })
            }}
            endIcon={undefined}
            children={undefined}
          />
        </div>
      </div>
    </BackDropComponent>
  )
}

const mapStateToProps = state => {
  return {
    mainState: state.mainState,
    userState: state.userState
  }
}

export default connect(mapStateToProps)(AddDocumentComponent)
