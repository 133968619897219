import React, { useEffect, useState } from 'react'
import InputComponent from '../../customComponents/input'
import ButtonComponent from '../../customComponents/button'
import PopupControl from '../../../controls/popup/popup'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import { send_sms } from '../../../api/calls/recruitment/send_sms'
import { useDispatch } from 'react-redux'
import { setAlert } from '../../../asyncActions/alert'

const SendSMSDialog = ({ show, setShow, userData }) => {
  const [smsData, setSmsData] = useState()
  const [canSave, setCanSave] = useState(false)
  const dispatch = useDispatch()

  const _validate = () => {
    if (smsData?.cell_number && smsData.message) {
      setCanSave(true)
    }
  }

  const _sendSms = () => {
    //api call...
    //write what operation should take place here.
    send_sms(smsData).then(response => {
      if (response.data.success) {
        dispatch(
          setAlert({
            show: true,
            success: true,
            message: 'SMS successfully sent.'
          })
        )
        setShow(false)
        setSmsData()
      }
    })
  }

  useEffect(() => {
    _validate()
  }, [smsData])

  useEffect(() => {
    if (userData) {
      setSmsData({
        cell_number: userData?.user_cell_number
      })
    }
  }, [userData])

  return (
    <div>
      <PopupControl
        title="Send message via SMS"
        isOpen={show}
        close={{
          showClose: true,
          onClose: () => {
            setShow(false)
          }
        }}
      >
        <div className="w-full flex flex-col gap-4">
          <InputComponent
            title="Type your message here..."
            theme="dark"
            size="lg"
            value={smsData?.message}
            onChange={e =>
              setSmsData({
                ...smsData,
                message: e
              })
            }
          />

          <ButtonComponent
            title="Save"
            icon={faSave}
            isBusy={false}
            type="primary"
            disabled={!canSave}
            onClick={_ => {
              _sendSms(smsData)
            }}
          />
        </div>
      </PopupControl>
    </div>
  )
}

export default SendSMSDialog
