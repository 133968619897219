import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { download_register_historical_data } from '../../../api/calls/attendance'
import generatePDF from 'react-to-pdf'
import ButtonComponent from '../../customComponents/button'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'

const register = {
  _id: '',
  date: '',
  name: '',
  level_type: '',
  number_of_registers: '',
  program: {},
  venue: {},
  facililator: {},
  interaction: {},
  group: {}
}

const tableColumns = [
  'Name',
  'Surname',
  'Contact',
  'Attendance Date',
  'OI Rating',
  'Attendance Status',
  'Day Type',
  'Comment'
]

const DownloadableRegister = () => {
  const { attendance_id } = useParams()
  const [learners, setLearners] = useState([])
  const [data, setData] = useState(register)
  const targetRef = useRef()

  useEffect(() => {
    download_register_historical_data(attendance_id).then(e => {
      if (e.data.success) {
        console.log('e.data.content', e.data)
        setLearners(e.data.content)
      }
    })
  }, [])

  useEffect(() => {
    if (learners.length > 0) {
      setData(learners[0])
    }
  }, [learners])

  return (
    <div className="flex flex-col gap-4">
      <div className="w-96">
        <ButtonComponent
          title="Download as PDF"
          icon={faSave}
          onClick={() => {
            generatePDF(targetRef, {
              filename: `${data?.name} register - ${data?.program?.program_name}.pdf`
            })
          }}
          isBusy={false}
          type="primary"
        />
      </div>
      <div className="flex flex-col gap-4 bg-white shadow-md p-4" ref={targetRef}>
        <div className="flex flex-col w-full">
          <div className="font-semibold pb-2 border-b border-gray">GENERAL INFORMATION</div>
          <div className="flex flex-col">
            <div className="flex py-2 flex-col">
              <div className="font-extrabold font-sans">Name of Register:</div>
              <div className="font-extrabold text-sm">{data?.name ?? '-'}</div>
            </div>
            <div className="flex py-2 flex-col">
              <div className="font-extrabold font-sans">Date of Register:</div>
              <div className="font-extrabold text-sm">
                {new Date(data?.date).toLocaleDateString()}
              </div>
            </div>
            <div className="flex py-2 flex-col">
              <div className="font-extrabold font-sans">Programme:</div>
              <div className="font-extrabold text-sm">{data?.program?.program_name ?? '-'}</div>
            </div>
            <div className="flex py-2 flex-col">
              <div className="font-extrabold font-sans">Group:</div>
              <div className="font-extrabold text-sm">{data?.group?.name ?? '-'}</div>
            </div>
            <div className="flex pb-2 flex-col">
              <div className="font-extrabold font-sans">Venue:</div>
              <div className="font-extrabold text-sm">{data?.venue?.name ?? '-'}</div>
            </div>
            <div className="flex pb-2 flex-col">
              <div className="font-extrabold font-sans">Level Type:</div>
              <div className="font-extrabold text-sm">{data?.level_type ?? '-'}</div>
            </div>
            <div className="flex pb-2 flex-col">
              <div className="font-extrabold font-sans">Number of Registers:</div>
              <div className="font-extrabold text-sm">{data?.number_of_registers ?? '-'}</div>
            </div>
            {/* <div className="flex pb-2 flex-col">
              <div className="font-extrabold font-sans">Warning Letter:</div>
              <div className="font-extrabold text-sm">{data?.warning_letter ?? '-'}</div>
            </div>
            <div className="flex pb-2 flex-col">
              <div className="font-extrabold font-sans">Final Warning Letter:</div>
              <div className="font-extrabold text-sm">{data?.final_warning_letter ?? '-'}</div>
            </div>
            <div className="flex pb-2 flex-col">
              <div className="font-extrabold font-sans">Dismissal Letter:</div>
              <div className="font-extrabold text-sm">{data?.dismissal_letter ?? '-'}</div>
            </div> */}
          </div>
        </div>

        <div className="font-semibold pb-2 border-b border-gray">LEARNER ATTENDANCE</div>
        <Table className="flex min-w-full">
          <TableHead className="bg-main text-lg">
            <TableRow>
              {tableColumns.map(col => (
                <TableCell className="text-gray-600 uppercase text-sm leading-normal">
                  {col}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="text-gray-600 text-sm font-normal">
            {learners.map((item, idx) => {
              return (
                <TableRow
                  key={idx}
                  className={
                    'border-b border-gray-200 hover:bg-gray-100 border-dark font-light h-8'
                  }
                >
                  <TableCell className="text-gray-600 text-xs leading-normal font-light">
                    <div className="flex flex-col">{item?.learner?.user_name}</div>
                  </TableCell>
                  <TableCell className="text-gray-600 text-xs leading-normal font-light">
                    <div className="flex flex-col">{item?.learner?.user_surname}</div>
                  </TableCell>
                  <TableCell className="text-gray-600 text-xs leading-normal font-light">
                    <div className="flex flex-col">{item?.learner?.user_cell_number}</div>
                  </TableCell>
                  <TableCell className="text-gray-600 text-xs leading-normal font-light">
                    <div className="flex flex-col">{new Date(data?.date).toLocaleDateString()}</div>
                  </TableCell>
                  <TableCell className="text-gray-600 text-xs leading-normal font-light">
                    <div className="flex flex-col">
                      {item?.attendance?.business_level != null
                        ? item?.attendance?.business_level
                        : item?.attendance?.human_level != null
                        ? item?.attendance?.human_level
                        : item?.attendance?.technical_level != null
                        ? item?.attendance?.technical_level
                        : '-'}
                    </div>
                  </TableCell>
                  <TableCell className="text-gray-600 text-xs leading-normal font-light">
                    <div className="flex flex-col">{item?.attendance?.attendance_status}</div>
                  </TableCell>
                  <TableCell className="text-gray-600 text-xs leading-normal font-light">
                    <div className="flex flex-col">{item?.attendance?.day_type}</div>
                  </TableCell>
                  <TableCell className="text-gray-600 text-xs leading-normal font-light">
                    <div className="flex flex-col">{item?.attendance?.comment}</div>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default DownloadableRegister
