import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { editEntityState, updateEntityState } from '../../../actions/entities'
import { editFacilitatorState, updateFacilitatorState } from '../../../actions/facilitator'
import { editLearnerState, updateLearnerState } from '../../../actions/learner'
import { editRecruiterState, updateRecruiterState } from '../../../actions/recruiter'
import { upload_file } from '../../../api/calls/upload_file'
import { addUser, getPrograms, setNotification, updateUser } from '../../../asyncActions'
import TabControl from '../../../controls/tab/tab'
import { initialUserValues } from '../constants'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import { get_groups } from '../../../api/calls/studentApplications/get_groups'
import { get_all_tasks } from '../../../api/calls'
import BackDropComponent from '../../UIComponents/components/form/backdrop'
import TextFieldComponent from '../../UIComponents/components/textfield/textfiled'
import SingleSelectComponent from '../../UIComponents/components/select/singleSelect'
import FileUploadButton from '../../UIComponents/components/buttons/fileUploadButton'
import ButtonComponentContained from '../../UIComponents/components/buttons/buttonContained'
import RadioButtonComponent from '../../UIComponents/components/radio/radioButtton'
import CountrySelect from '../../UIComponents/components/autoselect/countrySelectAutocomplete'
import ButtonComponentOutlined from '../../UIComponents/components/buttons/buttonOutlined'
import { selectPrograms } from '../../programs/selectors'
import { validateForm } from '../../studentApplication/form/components/constants'

const validationData = [
  { name: 'user_name', required: true },
  { name: 'user_surname', required: true },
  { name: 'id_number', required: true },
  { name: 'user_email', required: true },
  { name: 'user_cell_number', required: true },
  { name: 'training_provider', required: true },
  { name: 'municipality', required: true },
  { name: 'age', required: true },
  { name: 'race', required: true },
  { name: 'gender', required: true },
  // { name: 'group_id', required: true },
  // { name: 'event_id', required: false },
  { name: 'status_code', required: true },
  { name: 'user_password', required: true }
]

const AddUserComponent = ({
  open = false,
  setShow,
  type = '',
  user = {},
  edit = false,
  onsuccess = () => {}
}) => {
  console.log('is editing...', edit)

  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState(initialUserValues)
  const [userType, setUserType] = useState('User')
  const [isLoading, setIsLoading] = useState(false)
  const [groups, setGroups] = useState([])
  const [events, setEvents] = useState([])
  const programs = useSelector(selectPrograms)
  const [canSave, setCanSave] = useState(false)

  const statuses = [
    { name: 'Active', value: 'ACTIVE', id: '1' },
    { name: 'Inactive', value: 'INACTIVE', id: '2' },
    { name: 'In-Review', value: 'IN-REVIEW', id: '3' }
  ]

  const _onInputChange = value => {
    setFormValues({ ...formValues, ...value })
  }

  useEffect(() => {
    if (edit) {
      setFormValues(user)
    } else {
      //reset form values
      delete formValues._id
      setFormValues({ ...initialUserValues, type_code: 'FACILITATOR-ACC' })
    }

    //fetch groups
    get_groups().then(e => {
      if (e.data.success) {
        setGroups(e.data.content)
      }
    })

    //fetch events
    get_all_tasks().then(e => {
      if (e.data.success) {
        console.log('get_all_tasks response:', e.data.content)
        setEvents(e.data.content)
      }
    })

    dispatch(getPrograms())
  }, [user, edit])

  useEffect(() => {
    console.log('events', events)
  }, [events])

  useEffect(() => {
    console.log('formValues', formValues)
    const valid = validateForm(validationData, formValues)
    setCanSave(valid)
  }, [formValues])

  useEffect(() => {
    console.log('programs', programs)
  }, [programs])

  useEffect(() => {
    if (type) {
      switch (type) {
        case 'RECRUITER-ACC':
          return setUserType('Recruitment')
        case 'LEARNER-ACC':
          return setUserType('Learner')
        case 'FACILITATOR-ACC':
          return setUserType('Facilitator')
        case 'ENTITY-ACC':
          return setUserType('entity')
      }
    }
  }, [open, user])

  const _saveUser = async () => {
    if (edit) {
      formValues.user_id = formValues._id
      dispatch(updateUser(formValues))
      editData(formValues)
      setShow(false)
      setFormValues(initialUserValues)
    } else {
      dispatch(addUser(formValues))
      addData(formValues)
      setShow(false)
      setFormValues(initialUserValues)
    }
  }

  const editData = data => {
    switch (type) {
      case 'RECRUITER-ACC':
        return dispatch(editRecruiterState(data))
      case 'LEARNER-ACC':
        return dispatch(editLearnerState(data))
      case 'FACILITATOR-ACC':
        return dispatch(editFacilitatorState(data))
      case 'ENTITY-ACC':
        return dispatch(editEntityState(data))
    }
  }

  const addData = data => {
    switch (type) {
      case 'RECRUITER-ACC':
        return dispatch(updateRecruiterState(data))
      case 'LEARNER-ACC':
        return dispatch(updateLearnerState(data))
      case 'FACILITATOR-ACC':
        return dispatch(updateFacilitatorState(data))
      case 'ENTITY-ACC':
        return dispatch(updateEntityState(data))
    }
  }

  const disabilityOptions = [
    {
      name: 'Yes',
      value: true
    },
    {
      name: 'No',
      value: false
    }
  ]

  return (
    <BackDropComponent isOpen={open} title={`${user._id ? 'Update' : 'Create'} account`}>
      <div className="flex flex-col gap-4">
        <TabControl titles={['General', 'Upload Documents']}>
          <div className="flex flex-row gap-4">
            <div className="w-full flex flex-col gap-2">
              <div className="grid grid-cols-3 gap-2">
                <TextFieldComponent
                  label="Name"
                  required={true}
                  value={formValues.user_name}
                  onChange={v =>
                    _onInputChange({
                      user_name: v
                    })
                  }
                  helperText={undefined}
                />

                <TextFieldComponent
                  label="Surname"
                  required={true}
                  value={formValues.user_surname}
                  onChange={v =>
                    _onInputChange({
                      user_surname: v
                    })
                  }
                  helperText={undefined}
                />
                <TextFieldComponent
                  label="ID Number"
                  required={true}
                  type="number"
                  value={formValues.id_number}
                  onChange={v =>
                    _onInputChange({
                      id_number: v
                    })
                  }
                  helperText={undefined}
                />
              </div>

              <div className="grid grid-cols-2 gap-2">
                <TextFieldComponent
                  label="Email"
                  required={true}
                  value={formValues.user_email}
                  onChange={v =>
                    _onInputChange({
                      user_email: v
                    })
                  }
                  helperText={undefined}
                />

                <TextFieldComponent
                  label="Mobile Number"
                  required={true}
                  type="number"
                  value={formValues.user_cell_number}
                  onChange={v =>
                    _onInputChange({
                      user_cell_number: v
                    })
                  }
                  helperText={undefined}
                />
              </div>

              <TextFieldComponent
                label="Training Provider"
                required={true}
                value={formValues?.training_provider}
                onChange={training_provider => {
                  _onInputChange({ training_provider })
                }}
                helperText={undefined}
              />

              <TextFieldComponent
                label="Municipality"
                required={true}
                value={formValues?.municipality}
                onChange={municipality => {
                  _onInputChange({ municipality })
                }}
                helperText={undefined}
              />

              <div className="grid grid-cols-2 gap-2">
                <TextFieldComponent
                  label="Age"
                  required={true}
                  type={'number'}
                  value={formValues?.age}
                  onChange={age => {
                    _onInputChange({ age })
                  }}
                  helperText={undefined}
                />

                <SingleSelectComponent
                  title="Select Race"
                  required={true}
                  displayKey="name"
                  data={[
                    {
                      name: 'Black',
                      value: 'Black'
                    },
                    {
                      name: 'Coloured',
                      value: 'Coloured'
                    },
                    {
                      name: 'Indian',
                      value: 'Indian'
                    },
                    {
                      name: 'White',
                      value: 'White'
                    },
                    {
                      name: 'Asian',
                      value: 'Asian'
                    }
                  ]}
                  value={formValues.race}
                  onClick={item => {
                    _onInputChange({ race: item.value })
                  }}
                />
              </div>

              <div className="flex w-full gap-4">
                <RadioButtonComponent
                  title={'Select Gender'}
                  displayKey={'label'}
                  required={true}
                  buttons={[
                    { label: 'Male', value: 'Male' },
                    { label: 'Female', value: 'Female' }
                  ]}
                  onClick={e => {
                    _onInputChange({ gender: e.value })
                  }}
                  selectedValue={formValues?.gender}
                />
              </div>

              <div className="flex flex-col gap-2">
                <SingleSelectComponent
                  title="Disability"
                  required={true}
                  displayKey="name"
                  data={disabilityOptions}
                  value={formValues.disabled}
                  onClick={item => {
                    _onInputChange({ disabled: item.value })
                  }}
                />
                {formValues.disabled && (
                  <TextFieldComponent
                    label="Add disabilities (sep. by comma)"
                    multiline={true}
                    value={formValues?.disability}
                    onChange={disability => {
                      _onInputChange({ disability })
                    }}
                  />
                )}
              </div>

              {edit === false || formValues.country?.toString().length < 1 ? (
                <CountrySelect
                  title="Select Nationality"
                  required={true}
                  value={formValues.country}
                  onChange={e => {
                    console.log('e', e)
                    _onInputChange({ country: e })
                  }}
                />
              ) : null}

              {!edit && (
                <>
                  <div className="flex flex-row gap-2">
                    <SingleSelectComponent
                      title="Select Programme"
                      errorMessage={'Programmes Not Found'}
                      required={true}
                      displayKey="program_name"
                      data={programs}
                      onClick={item => {
                        console.log('item', item)
                        _onInputChange({ program_ids: [item._id] })
                      }}
                      color={undefined}
                    />
                    {/* <SingleSelectComponent
                      title="Select Group"
                      errorMessage={'Groups Not Found'}
                      required={true}
                      displayKey="name"
                      data={groups}
                      onClick={item => {
                        console.log('item', item)
                        _onInputChange({ group_id: item?._id })
                      }}
                      color={undefined}
                    /> */}
                    <SingleSelectComponent
                      title={'Select Event'}
                      errorMessage={'Events Not Found'}
                      required={true}
                      displayKey="event_name"
                      data={events.filter(e => e?.type?.toString().toUpperCase() == 'OPEN DAY')}
                      onClick={item => {
                        _onInputChange({ event_id: item?._id })
                      }}
                      color={undefined}
                    />
                  </div>
                </>
              )}

              <SingleSelectComponent
                title="Select Current Status"
                errorMessage={'Status Codes Not Found'}
                required={true}
                displayKey="name"
                data={statuses}
                value={formValues?.status_code}
                onClick={item => {
                  _onInputChange({
                    status_code: item.value
                  })
                }}
              />

              {formValues.status_code == 'INACTIVE' && (
                <TextFieldComponent
                  size="lg"
                  label="Reason for Inactive"
                  value={formValues.inactive_reason}
                  onChange={v =>
                    _onInputChange({
                      inactive_reason: v
                    })
                  }
                />
              )}

              {userType === 'entity' ? (
                <div className="grid grid-cols-2 gap-2">
                  <TextFieldComponent
                    label="Business Name"
                    required={true}
                    value={formValues.entity.business_name}
                    onChange={v =>
                      _onInputChange({
                        entity: { ...formValues.entity, business_name: v }
                      })
                    }
                  />

                  <TextFieldComponent
                    label="Owner"
                    required={true}
                    value={formValues.entity.owner}
                    onChange={v =>
                      _onInputChange({
                        entity: { ...formValues.entity, owner: v }
                      })
                    }
                  />
                </div>
              ) : null}

              <TextFieldComponent
                label="Password"
                required={true}
                value={formValues.user_password}
                onChange={v =>
                  _onInputChange({
                    user_password: v
                  })
                }
              />
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <FileUploadButton
              title={'Document 1'}
              onChange={v => {
                setIsLoading(true)
                upload_file({ file: v, name: v.name }).then(res => {
                  if (res.data.success) {
                    _onInputChange({
                      etag: res.data.content.etag,
                      document_1: res.data.content.location
                    })
                  }
                  setIsLoading(false)
                })
              }}
            />
            <FileUploadButton
              title={'Document 2'}
              onChange={v => {
                setIsLoading(true)
                upload_file({ file: v, name: v.name }).then(res => {
                  if (res.data.success) {
                    _onInputChange({
                      etag: res.data.content.etag,
                      document_2: res.data.content.location
                    })
                  }
                  setIsLoading(false)
                })
              }}
            />
            <FileUploadButton
              title={'Document 3'}
              onChange={v => {
                setIsLoading(true)
                upload_file({ file: v, name: v.name }).then(res => {
                  if (res.data.success) {
                    _onInputChange({
                      etag: res.data.content.etag,
                      document_3: res.data.content.location
                    })
                  }
                  setIsLoading(false)
                })
              }}
            />
          </div>
        </TabControl>
        <div className="flex flex-row justify-end gap-4">
          <ButtonComponentOutlined
            title="Cancel"
            bgColor="red"
            onClick={() => {
              setShow(false)
            }}
          />
          <ButtonComponentContained
            title={`${user._id ? 'Update' : 'Create'} account`}
            loading={isLoading}
            startIcon={faSave}
            disabled={!canSave || isLoading}
            onClick={_saveUser}
          />
        </div>
      </div>
    </BackDropComponent>
  )
}

export default AddUserComponent
