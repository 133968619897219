import React, { useState } from 'react'
import { useEffect } from 'react'
import { AttendanceTypes, dayTypes, OILevel } from './contants'
import DropdownButtonComponent from '../../customComponents/dropdownButton'
import CheckBoxControl from '../../../controls/checkbox/checkbox'

const Attendance = ({ record, showDayType = true, handleLearner, formValues }) => {
  const [status, setStatus] = useState('')
  const [oiLevel, setOILevel] = useState({})
  const [dayType, setDayTypes] = useState({})
  const [attenadanceValues, setAttendanceValues] = useState()

  useEffect(() => {
    if (status && oiLevel && dayType) {
      setAttendanceValues({
        user_id: record._id,
        program_id: formValues.program_id,
        venue_id: formValues.venue_id,
        attendance: {
          user_id: record._id,
          attendance_status: status,
          day_type: dayType,
          oi_level: oiLevel.oi_level,
          attendance_date: formValues.date
        }
      })
    }
  }, [status, oiLevel, dayType])

  useEffect(() => {
    if (status && Object.keys(oiLevel).length !== 0) {
      handleLearner(attenadanceValues)
    }
  }, [attenadanceValues])

  return (
    <>
      {showDayType && (
        <td className="w-full">
          <DropdownButtonComponent
            placeholder="Day type"
            type="primary"
            theme="form"
            displayKey="name"
            dropdownItems={dayTypes}
            selectedItem={dayTypes.find(x => dayType == x.id)?.name}
            onClick={item => setDayTypes(item.name)}
          />
        </td>
      )}
      <td className="w-full">
        <DropdownButtonComponent
          placeholder="Attendance"
          type="primary"
          theme="form"
          displayKey="name"
          buttonBg={
            status === 'Present'
              ? ' bg-main-green'
              : status === 'Late'
              ? 'bg-yellow-400'
              : status === 'Absent'
              ? 'bg-red-600'
              : 'bg-theme'
          }
          dropdownItems={AttendanceTypes}
          selectedItem={AttendanceTypes.find(x => status == x.id)?.name}
          onClick={item => setStatus(item.id)}
        />
      </td>
      <td className="w-full">
        <DropdownButtonComponent
          placeholder="Select Level"
          type="primary"
          theme="form"
          displayKey="level"
          dropdownItems={OILevel}
          selectedItem={OILevel.find(x => oiLevel.oi_level == x.level)?.level}
          onClick={item =>
            setOILevel({
              oi_level: item.level
            })
          }
        />
      </td>
    </>
  )
}

export default Attendance