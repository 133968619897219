import moment from 'moment'

export function isValidData(e) {
  if (
    !e.data ||
    e.data.target == 'onboardingcomplete' ||
    e.data.target == 'inpage' ||
    e.data.type == 'INIT_INSTANCE' ||
    e.data.type == 'webpackOk' ||
    e.data.type == 'webpackStillOk' ||
    (e.data.source &&
      (e.data.source == 'react-devtools-bridge' ||
        e.data.source == 'react-devtools-inject-backend' ||
        e.data.source == 'react-devtools-content-script' ||
        e.data.source == 'react-devtools-detector'))
  ) {
    return false
  }

  return true
}

export function isDevMode() {
  return process.env.NODE_ENV == 'development'
}

export function isUATMode() {
  return process.env.NODE_ENV == 'uat'
}

export function isProuctionMode() {
  return process.env.NODE_ENV == 'production'
}

export function getISODate(date) {
  if (date) {
    return moment(date).toISOString(true)
  } else {
    return moment().toISOString(true)
  }
}

export function isoToDate(date) {
  if (date) {
    return new Date(date)
  } else {
    return undefined
  }
}

export function getLocalISODate(date) {
  if (date) {
    return moment(date).local().toISOString(true)
  } else {
    return ''
  }
}

export function formatDate(date, unix = false, toLocal = true, time = true) {
  // console.log("toLocal", toLocal);
  if (date) {
    if (unix) {
      // return moment(toLocal ? getLocalISODate(date) : date).format('Do MMM YYYY, h:mm:ss A');
      if (!time) return moment.unix(date).format('Do MMM YYYY')
      else return moment.unix(date).format('Do MMM YYYY, HH:mm:ss A')
    }

    if (!time) return moment(toLocal ? getLocalISODate(date) : date).format('Do MMM YYYY')
    else return moment(toLocal ? getLocalISODate(date) : date).format('Do MMM YYYY, HH:mm:ss A')
  } else {
    return ''
  }
}

export function arrayMove(arr, fromIndex, toIndex) {
  let element = arr[fromIndex]
  arr.splice(fromIndex, 1)
  arr.splice(toIndex, 0, element)
}

export function capitalize(s) {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const getToken = () => {
  return localStorage.getItem('token')
}

export const getStringFromField = string => {
  return capitalize(string.replaceAll('_', ' '))
}

export const getArrayByObjKey = (data, key) => {
  let arr = []
  data?.map(item => {
    arr.push(item[key])
  })
  return arr
}

export const getFieldFromString = string => {
  return string.replaceAll(' ', '_').toLowerCase()
}

export const objectKeysAsField = obj => {
  return Object.keys(obj).reduce((acc, key) => {
    acc[getFieldFromString(key)] = obj[key]
    return acc
  }, {})
}

export const getJoinedNamesFromObj = (data, lookup) => {
  const itemNames = []
  data.map(_ => {
    if (lookup?.find(el => el == _.id)) itemNames.push(_.name)
  })
  return itemNames.join(',')
}

export const getObjectsByIdName = (data, lookup) => {
  const itemNames = []
  //TODO: refactor
  data.map(_ => {
    if (lookup?.find(el => el == _.id)) itemNames.push({ id: _.id, name: _.user_name })
  })
  return itemNames
}

export function toTitleCase(str) {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}


export const convertDateTimeFromISO = datetime => {
  let dt
  let date
  let time
  dt = moment(datetime)
  date = dt.format('YYYY-MM-DD')
  time = dt.format('HH:mm')

  return { date: date, time: time }
}
