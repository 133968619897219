import React, { useEffect, useState } from 'react'
import BackDropComponent from '../../UIComponents/components/form/backdrop'
import { Avatar, Button } from '@material-ui/core'
import ButtonComponentOutlined from '../../UIComponents/components/buttons/buttonOutlined'
import ButtonComponentContained from '../../UIComponents/components/buttons/buttonContained'
import { faPen, faSave } from '@fortawesome/pro-light-svg-icons'
import TextFieldComponent from '../../UIComponents/components/textfield/textfiled'
import { useDispatch } from 'react-redux'
import DatePickerComponent from '../../UIComponents/components/calendar/date'
import AddPhotoModal from '../modals/addPhotoModal'
import { validateForm } from '../../studentApplication/form/components/constants'
import { saveGreenlightSurvey } from '../../../asyncActions'

const validationData = [
  {
    name: 'name',
    required: true
  },
  {
    name: 'identity_card',
    required: true
  },
  {
    name: 'survey_timeline',
    required: true
  },
  {
    name: 'date',
    required: true
  },
  {
    name: 'photo',
    required: true
  },
  {
    name: 'narrative',
    required: true
  },
  {
    name: 'office',
    required: true
  }
]

const CreateGreenlightSurveyComponent = ({
  isEdit = false,
  setShow,
  open = false,
  data,
  onSuccess
}) => {
  const [formValues, setFormValues] = useState({
    name: '',
    identity_card: '',
    survey_timeline: '',
    date: '',
    photo: '',
    narrative: '',
    office: ''
  })
  const [showAddPhoto, setShowAddPhoto] = useState(false)
  const [canSave, setCanSave] = useState(false)
  const [loading, setLoading] = useState(false)

  const onFormValueChange = value => {
    setFormValues({ ...formValues, ...value })
  }

  useEffect(() => {
    if (isEdit) {
      setFormValues({
        ...data,
        id: data._id
      })
    } else {
      setFormValues({
        name: '',
        identity_card: '',
        survey_timeline: '',
        date: '',
        photo: '',
        narrative: '',
        office: ''
      })
    }
  }, [data, isEdit])

  const dispatch = useDispatch()

  const _submit = () => {
    dispatch(saveGreenlightSurvey(formValues))
    onSuccess()
    setShow(false)
    setLoading(false)
  }

  const _toggleAddPhoto = () => {
    setShowAddPhoto(!showAddPhoto)
  }

  useEffect(() => {
    console.log(formValues)
    const valid = validateForm(validationData, formValues)
    setCanSave(valid)
  }, [formValues])

  return (
    <BackDropComponent
      isOpen={open}
      title={isEdit ? 'Update Greenlight Survey' : 'Create Greenlight Survey'}
      description="Please fill in the form below to create a new Greenlight Survey."
      onBackDropClose={() => setShow(false)}
    >
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2">
          <div className="grid grid-cols-2 gap-2">
            <TextFieldComponent
              label="Name"
              value={formValues?.name}
              onChange={e => onFormValueChange({ name: e })}
              helperText={undefined}
              required={true}
            />
            <TextFieldComponent
              label="Identity Card"
              value={formValues?.identity_card}
              onChange={e => onFormValueChange({ identity_card: e })}
              helperText={undefined}
              required={true}
            />
          </div>
          <div className="grid grid-cols-2 gap-2">
            <TextFieldComponent
              label="Survey Timeline"
              value={formValues?.survey_timeline}
              onChange={e => onFormValueChange({ survey_timeline: e })}
              helperText={undefined}
              required={true}
            />
            <TextFieldComponent
              label="Office"
              value={formValues?.office}
              onChange={e => onFormValueChange({ office: e })}
              helperText={undefined}
              required={true}
            />
          </div>
          <DatePickerComponent
            title="Date"
            value={formValues?.date}
            onChange={e => onFormValueChange({ date: e })}
            required={true}
          />
          <Avatar src={formValues?.photo} onClick={_toggleAddPhoto} className="h-44 w-44 bg-red" />

          <TextFieldComponent
            label="Narrative"
            multiline={true}
            value={formValues.narrative}
            onChange={e => onFormValueChange({ narrative: e })}
            helperText={undefined}
            required={true}
          />
        </div>
        <div className="pt-4 flex justify-end items-center gap-2">
          <ButtonComponentOutlined
            title={'Cancel'}
            bgColor="red"
            onClick={() => {
              setShow(false)
            }}
            startIcon={faPen}
            endIcon={undefined}
            children={undefined}
          />
          <ButtonComponentContained
            title={isEdit ? 'Update Greenlight Survey' : 'Create Greenlight Survey'}
            onClick={() => {
              setLoading(true)
              _submit()
            }}
            startIcon={faSave}
            endIcon={undefined}
            children={undefined}
            disabled={!canSave || loading}
            loading={loading}
          />
        </div>
      </div>
      <AddPhotoModal
        onSuccess={e => {
          setFormValues({ ...formValues, photo: e })
        }}
        open={showAddPhoto}
        setShow={_toggleAddPhoto}
      />
    </BackDropComponent>
  )
}

export default CreateGreenlightSurveyComponent
