import React, { useEffect, useState } from 'react'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { getAllCurriculums } from '../../../asyncActions'
import { getLearners } from '../../../asyncActions/learners'
import { getPrograms, getProgramUsers } from '../../../asyncActions/programs'
import { DATE_FORMAT } from '../../../constants/constants'
import TitleCardControl from '../../../controls/cards/titlecard'
import NotificationControl from '../../../controls/notification/notification'
import AddLearnerComponent from '../../learner/components/addLearner'
import { selectNotificationState } from '../../notifications/selectors'
import {
  selectCurriculumByProgramId,
  selectCurriculumsLoaded
} from '../../programmeCurriculum/selectors'
import CurriculumTable from './currriculumTableWidget'
import { MyTableControl } from '../../../controls/myTableControl/myTableControl'
import ButtonComponent from '../../customComponents/button'
import HistoricalRegisters from './historicalRegisters'
import { get_registers } from '../../../api/calls'
import { get_program_information } from '../../../api/calls/programs/program_information'
import CreateProgrammeComponent from './addProgram'
import { faEdit, faEye, faPen } from '@fortawesome/pro-light-svg-icons'
import CardControl from '../../learner/components/card'
import AddProgramTarget from './addProgramTarget'
import ButtonComponentContained from '../../UIComponents/components/buttons/buttonContained'
import TableComponent from '../../UIComponents/components/tables/table'

const ProgramDetailsComponent = ({ learnerState, programsState }) => {
  const history = useHistory()
  const notificationData = useSelector(selectNotificationState)
  const curriculumsLoaded = useSelector(selectCurriculumsLoaded)
  const [showAddLearner, setShowAddLearner] = useState(false)
  const [init, setInit] = useState(true)
  const [learnerInit, setLearnerInit] = useState(true)
  const [selectedProgram, setSelectedProgram] = useState({})
  const curriculums = useSelector(selectCurriculumByProgramId)(selectedProgram?._id)
  const [learners, setLearners] = useState([])
  const [registers, setRegisters] = useState([])
  const [programInformation, setProgramInformation] = useState({})
  const [showEditProgramPopUp, setShowEditProgramPopUp] = useState(false)
  const { id } = useParams()
  const dispatch = useDispatch()
  const [addTarget, setAddTarget] = useState(false)

  useEffect(() => {
    get_registers(id).then(v => {
      if (v.data.success) {
        setRegisters(v.data.content)
      }
    })
    get_program_information(id).then(res => {
      if (res.data.success) {
        setProgramInformation(res.data.content)
      }
    })
  }, [])

  useEffect(() => {
    if (!curriculumsLoaded) {
      dispatch(getAllCurriculums())
    }
    if (!programsState.programsLoaded) {
      dispatch(getPrograms())
    }
    if (init && learnerState?.learners.length == 0) {
      dispatch(getLearners())
      setInit(false)
    }

    let _program = programsState?.programs.find(x => x._id === id)
    if (_program) {
      setSelectedProgram(_program)
      if (_program?.learners) {
        setLearners(_program.learners)
      }
    }

    if (
      learnerInit &&
      selectedProgram._id &&
      (!selectedProgram?.learners || selectedProgram?.learners.length == 0)
    ) {
      dispatch(getProgramUsers(selectedProgram))
      setLearnerInit(false)
    }
  }, [learnerState.learners, programsState, selectedProgram])

  useEffect(() => {
    console.log('programInformation', programInformation)
  }, [programInformation])

  return (
    <div className="flex flex-col gap-4 bg-white p-4">
      <div className="">
        <Link
          to="/dashboard/programs"
          className="mt-4 mb-4 text-green hover:underline focus:outline-none cursor-pointer"
        >
          <FontAwesomeIcon className="text-sm" icon={faArrowLeft} /> Back to all Programmes
        </Link>
      </div>
      <div className="flex flex-row justify-between">
        <div className="flex-auto text-3xl font-bold">
          Programme - {selectedProgram.program_name}
        </div>

        <div className="flex gap-2">
          <ButtonComponentContained
            title="Take Attendance"
            bgColor="red"
            loading={false}
            startIcon={faPen}
            onClick={v => {
              history.push(`/dashboard/remedial-action/${selectedProgram._id}`)
            }}
            endIcon={undefined}
            children={undefined}
          />
          <ButtonComponentContained
            title="Edit"
            loading={false}
            startIcon={faEdit}
            onClick={v => {
              setShowEditProgramPopUp(true)
            }}
          />
          <ButtonComponentContained
            title="Add a Target"
            loading={false}
            startIcon={faPen}
            onClick={v => {
              setAddTarget(true)
            }}
          />
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <div className="flex gap-4">
          <div className="flex flex-1 flex-col gap-2">
            <TitleCardControl height={'h-50 bg-main'}>
              <div className="flex text-sm items-center">
                <img
                  src={
                    selectedProgram?.location ?? 'https://via.placeholder.com/400x400?text=no+image'
                  }
                  className="object-cover h-48 w-48 rounded-full shadow-sm border-4 border-white"
                />

                <div className="mx-5">
                  <p className="text-lg text-black font-extrabold">
                    {selectedProgram.program_name}
                  </p>
                  <p className="text-gray">{selectedProgram.program_description}</p>
                  <div className="flex flex-col my-4 text-sm text-gray">
                    <p>Start date: {moment(selectedProgram.start_date).format(DATE_FORMAT)}</p>
                    <p>End date: {moment(selectedProgram.end_date).format(DATE_FORMAT)}</p>
                  </div>
                </div>
              </div>
            </TitleCardControl>

            <TableComponent
              search={true}
              title="Learners"
              rows={5}
              displayKeys={[
                { value: 'user_name', label: 'Name' },
                { value: 'user_surname', label: 'Surname' },
                { value: 'user_email', label: 'Email' }
              ]}
              data={learners ?? []}
              onRowClick={item => {
                history.push(`/dashboard/learner-info/${item._id}/LEARNER-ACC`)
              }}
              actions={[
                {
                  label: 'View',
                  action: item => {
                    history.push(`/dashboard/learner-info/${item._id}/LEARNER-ACC`)
                  },
                  icon: faEye // Assuming you have an icon for view action
                }
              ]}
            />

            <CurriculumTable curriculums={curriculums} programId={selectedProgram._id} />
            <HistoricalRegisters data={registers} />
          </div>
          <div className="flex flex-col gap-1" style={{ width: '20%' }}>
            <CardControl
              style="rounded-2xl shadow-xs  border border-homeBorder "
              title={'PROGRAMME INFORMATION'}
            >
              <div className="flex flex-col">
                <div className="flex py-2 flex-col">
                  <div className="font-extrabold font-sans text-xs italic">
                    Attendance Percentage
                  </div>
                  <div className="font-extrabold text-sm">
                    {programInformation?.attendance_percentage ?? '-'}%
                  </div>
                </div>
                <div className="flex pb-2 flex-col">
                  <div className="font-extrabold font-sans text-xs italic">Human OI Average</div>
                  <div className="font-extrabold text-sm">
                    {programInformation?.human_oi ?? '-'}
                  </div>
                </div>

                <div className="flex pb-2 flex-col">
                  <div className="font-extrabold font-sans text-xs italic">Sales in units</div>
                  <div className="font-extrabold text-sm">
                    {programInformation?.sales_in_units ?? '-'}
                  </div>
                </div>
                <div className="flex pb-2 flex-col">
                  <div className="font-extrabold font-sans text-xs italic">Sales (R)</div>
                  <div className="font-extrabold text-sm">
                    R{programInformation?.sales_in_rand ?? '-'}
                  </div>
                </div>

                <div className="flex pb-2 flex-col">
                  <div className="font-extrabold font-sans text-xs italic">Value Exchange Days</div>
                  <div className="font-extrabold text-sm">
                    {programInformation.value_exchange_days ?? '-'}
                  </div>
                </div>

                <div className="flex pb-2 flex-col">
                  <div className="font-extrabold font-sans text-xs italic">Loan % Paid</div>
                  <div className="font-extrabold text-sm">
                    {programInformation.loan_percentage ?? '-'}
                  </div>
                </div>

                <div className="flex pb-2 flex-col">
                  <div className="font-extrabold font-sans text-xs italic">Startup Loan Amount</div>
                  <div className="font-extrabold text-sm">
                    {programInformation?.startup_loan_amount
                      ? `R${programInformation.startup_loan_amount}`
                      : '-'}
                  </div>
                </div>

                <div className="flex pb-2 flex-col">
                  <div className="font-extrabold font-sans text-xs italic">Programme Targets</div>
                  <div className="font-extrabold text-sm">
                    {!programInformation?.program_targets
                      ? 'No targets set'
                      : programInformation?.program_targets?.map(e => (
                          <div className="flex flex-col">
                            {e.target_name} : {`R${e.target_value}`}
                          </div>
                        ))}
                  </div>
                </div>
              </div>
            </CardControl>
          </div>
        </div>
      </div>

      <CreateProgrammeComponent
        open={showEditProgramPopUp}
        program={selectedProgram}
        setShow={setShowEditProgramPopUp}
      />
      <AddProgramTarget
        program_id={selectedProgram._id}
        open={addTarget}
        setShow={setAddTarget}
        onSuccess={() => {
          get_program_information(id).then(res => {
            if (res.data.success) {
              setProgramInformation(res.data.content)
            }
          })
        }}
      />

      <NotificationControl notificationData={notificationData} />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    learnerState: state.learnerState,
    mainState: state.mainState,
    userState: state.userState,
    programsState: state.programsState
  }
}

export default connect(mapStateToProps)(ProgramDetailsComponent)
