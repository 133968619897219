import { faPen, faSave } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Paper from '@mui/material/Paper'
import React, { useState, useEffect } from 'react'
import Cookies from 'universal-cookie'
import { get_all_programs, get_facilitator_programs } from '../../api/calls'
import { add_facilitator_to_program } from '../../api/calls/accessRights/add_facilitator_to_programs'
import { get_facilitators } from '../../api/calls/accessRights/get_facilitators'
import AccordionControl from '../../controls/accordion/accordion'
import PopupControl from '../../controls/popup/popup'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import ButtonComponent from '../customComponents/button'
import DropdownButtonComponent from '../customComponents/dropdownButton'
import { MultiSelectDropdown } from '../customComponents/multiselect/multiselect'
import { InductionTableCell, InductionTableHeader, InductionTableRow } from '../induction/constants'
import CreateEventCategory from './components/createEventCategory'
import CreateSms from './components/createSms'

const tableColumns = [
  { key: 'name', display: 'Name' },
  { key: 'surname', display: 'Surname' },
  { key: 'programs', display: 'Programmes' }
]

const ConfigurationsComponent = () => {
  //get user access rights
  const cookies = new Cookies()
  const cookieJSON = cookies.get('veb-user-cookie') || {}
  const [facilitatorProgrammes, setFacilitatorProgrammes] = useState([])
  const [tableData, setTableData] = useState([])
  const [facilitators, setFacilitators] = useState([])
  const [open, setOpen] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [programmes, setProgrammes] = useState([])
  const [formValues, setFormValues] = useState({})
  const [edit, setEdit] = useState(false)
  const [selectedFacilitator, setSelectedFacilitator] = useState()

  const _getAllPrograms = async () => {
    await get_all_programs().then(e => {
      if (e.data.success) {
        const data = e.data.content.map(item => {
          return {
            ...item,
            name: item.program_name
          }
        })
        setProgrammes(data)
      }
    })
  }

  const _onInputChange = value => {
    setFormValues({ ...formValues, ...value })
  }

  const _onSave = async payload => {
    setIsBusy(true)
    const response = await add_facilitator_to_program(payload)
    if (response.data.success) {
      _getAccessRights()
      setFormValues({})
    }

    setIsBusy(false)
    setOpen(false)
  }

  const _getAccessRights = async () => {
    const response = await get_facilitator_programs()
    if (response.data.success) {
      setFacilitatorProgrammes(response.data.content)
    }
  }

  const getFacilitators = async () => {
    if (facilitators.length > 0) {
      return
    }
    const response = await get_facilitators()
    if (response.data.success) {
      const data = response.data.content.map(item => {
        return {
          ...item,
          full_name: `${item.user_name} ${item.user_surname}`
        }
      })

      setFacilitators(data)
    }
  }

  useEffect(() => {
    _getAccessRights()
    _getAllPrograms()
  }, [])

  useEffect(() => {
    console.log('selectedFacilitator', selectedFacilitator)
  }, [selectedFacilitator])

  useEffect(() => {
    console.log('facilitatorProgrammes', facilitatorProgrammes)
    if (facilitatorProgrammes.length > 0) {
      setTableData(
        facilitatorProgrammes.map(item => {
          return {
            _id: item.facilitator_id._id,
            name: item.facilitator_id.user_name,
            surname: item.facilitator_id.user_surname,
            full_name: `${item.facilitator_id.user_name} ${item.facilitator_id.user_surname}`,
            programs: item.program_ids
          }
        })
      )
    }
  }, [facilitatorProgrammes])

  return (
    <div className="flex flex-col">
      <TableContentWrapper>
        <div className="w-full ">
          <div className="flex w-full flex-col gap-8">
            <AccordionControl
              content={[
                {
                  title: 'Recruitment SMS Configurations',
                  subTitle:
                    'Configure the SMS that is sent to learners when they are accepted or rejected from a recruitment',
                  content: <CreateSms />
                },
                {
                  title: 'Event Category Configurations',
                  subTitle: 'Configure the categories for events',
                  content: <CreateEventCategory />
                }
              ]}
            />
          </div>
        </div>
      </TableContentWrapper>

      {cookieJSON.type === 'ADMIN-ACC' && (
        <TableContentWrapper>
          <div className="flex flex-col gap-2">
            <div
              className="flex flex-row gap-2"
              onClick={() => {
                console.log('clicked')
                setOpen(true)
                getFacilitators()
              }}
            >
              <FontAwesomeIcon className="cursor-pointer" size="lg" icon={faPen} />
              <div className="cursor-pointer">Add facilitator to program</div>
            </div>

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className="bg-black text-white">
                  <InductionTableHeader>
                    {tableColumns.map((column, idx) => (
                      <InductionTableCell align="left">{column.display}</InductionTableCell>
                    ))}
                    <InductionTableCell align="left">{'Action'}</InductionTableCell>
                  </InductionTableHeader>
                </TableHead>
                <TableBody>
                  {facilitatorProgrammes.length == 0 && (
                    <InductionTableRow>
                      <InductionTableCell align="left" colSpan={tableColumns.length}>
                        No data available
                      </InductionTableCell>
                    </InductionTableRow>
                  )}
                  {tableData.map(row => (
                    <InductionTableRow>
                      {tableColumns.map(column => (
                        <InductionTableCell align="left">
                          {Array.isArray(row[column.key])
                            ? row[column.key].map((item, idx) => (
                                <div key={idx}>{item.program_name}</div>
                              ))
                            : row[column.key]}
                        </InductionTableCell>
                      ))}
                      <div
                        className="flex flex-row gap-2"
                        onClick={() => {
                          console.log('clicked')
                          setOpen(true)
                          setEdit(true)
                          getFacilitators()
                          setSelectedFacilitator(row)
                          _onInputChange({
                            facilitator_id: row._id,
                            program_ids: row.programs.map(e => {
                              return e._id
                            })
                          })
                        }}
                      >
                        <FontAwesomeIcon className="cursor-pointer" size="lg" icon={faPen} />
                        <div className="cursor-pointer">edit</div>
                      </div>
                    </InductionTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </TableContentWrapper>
      )}

      <PopupControl
        title={'Facilitator Permissions'}
        isOpen={open}
        showEdit={edit}
        onEdit={() => {
          console.log('edit')
        }}
        close={{
          showClose: true,
          onClose: () => {
            setOpen(false)
          }
        }}
      >
        <div className="flex flex-col gap-8 w-72 ">
          <div className="flex w-full flex-col gap-2">
            <DropdownButtonComponent
              disabled={edit}
              placeholder={'Select Facilitator'}
              theme="light"
              displayKey="full_name"
              dropdownItems={facilitators.filter(e => {
                return !tableData.find(x => x._id == e._id)
              })} //filter out facilitators that are already in the list
              selectedItem={
                edit
                  ? selectedFacilitator?.full_name
                  : facilitators.find(x => x._id == formValues?.facilitator_id)?.full_name
              }
              onClick={item => {
                _onInputChange({ facilitator_id: item._id })
              }}
            />

            <MultiSelectDropdown
              displayKey={'program_name'}
              title={'Please select programmes'}
              options={programmes}
              theme={'light'}
              selected={formValues?.program_ids}
              onSelect={e => {
                console.log('selected options', e)
                if (e.length > 0) {
                  _onInputChange({
                    program_ids: e.map(e => {
                      return e._id
                    })
                  })
                } else {
                  delete formValues?.program_ids
                  _onInputChange({ ...formValues })
                }
              }}
            />
          </div>

          <ButtonComponent
            theme="dark"
            onClick={() => {
              _onSave(formValues)
            }}
            icon={faSave}
          >
            Save
          </ButtonComponent>
        </div>
      </PopupControl>
    </div>
  )
}

export default ConfigurationsComponent
