import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const BreadCrumbs = ({ data = {}, onClick = () => {}, enabled = false, selected = false }) => {
  return (
    <div className="flex flex-row gap-2 items-center pb-4">
      {/* <FontAwesomeIcon icon={faArrowLeft} /> */}
      <div className={`${selected ? 'bg-green hover:bg-green' : 'bg-gray'} p-2 rounded-full`}>
        <button
          onClick={onClick}
          disabled={!enabled}
          className="text-black hover:text-white flex flex-row gap-1 items-center"
        >
          <FontAwesomeIcon icon={data.icon} />
          <span>{data.label}</span>
        </button>
      </div>
      /
    </div>
  )
}

export default BreadCrumbs
