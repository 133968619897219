import React, { useEffect, useState } from 'react'

import { faArrowLeft, faUser, faUserGroup } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import {
  getFacilitators,
  getFacilitatorsPrograms,
  getFacilitatorTeachingDays,
  getFacilitatorTransactions,
  saveBilling
} from '../../../asyncActions'
import { getModules } from '../../../asyncActions/modules'
import BreadCrumb from '../../../controls/breadcrumb/breadcrumb'
import ButtonControl from '../../../controls/button/button'
import TitleCardControl from '../../../controls/cards/titlecard'
import EntityTable from '../../../controls/entityTable/entityTable'
import TableContentWrapper from '../../../controls/tableContentWrapper/tableContentWrapper'
import { selectSelectedUser } from '../../../selectors/selectors'
import { extractById } from '../../../utils/entityUtils'
import { selectModules, selectModulesLoaded } from '../../modules/selectors'
import { selectNotificationState } from '../../notifications/selectors'
import AddPaymentComponent from '../../user/components/addPayment'
import AddTransactionComponent from '../../user/components/addTransactions'
import DetailsBlock from '../../user/components/detailsBlock'
import AddDocumentComponent from '../../user/components/UploadDocuments'
import ValueExchange from '../../user/components/valueExchange'
import {
  initialRateValues,
  programColumn,
  teachingDaysColumn,
  transactionsColumn
} from '../../user/constants'
import AddRateModal from '../modals/addRate'
import { selectFacilitators, selectFacilitatorsLoaded } from '../selectors'
import { extractWithModules, getTransactionTableData } from '../utils'
import { MyTableControl } from '../../../controls/myTableControl/myTableControl'
import ButtonComponent from '../../customComponents/button'
import { Link } from '@material-ui/core'
import TableComponent from '../../UIComponents/components/tables/table'
import ButtonComponentOutlined from '../../UIComponents/components/buttons/buttonOutlined'
import moment from 'moment'
import { selectProgramsLoaded } from '../../programs/selectors'

const entityName = 'Facilitator'

const FacilitatorDetails = props => {
  const { id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const notificationData = useSelector(selectNotificationState)
  const [showAddPayment, setShowAddPayment] = useState(false)
  const [showAddRate, setShowAddRate] = useState(false)
  const [showAddDocument, setShowAddDocument] = useState(false)
  const [showAddTransaction, setShowAddTransaction] = useState(false)
  const [selectedVebProgram, setSelectedVebProgram] = useState()
  const [selectedUser, setSelectedUser] = useState()

  const facilitators = useSelector(selectFacilitators)
  const facilitatorsLoaded = useSelector(selectFacilitatorsLoaded)
  const programsLoaded = useSelector(selectProgramsLoaded)
  const facilitator = extractById(facilitators)(id)

  const modulesLoaded = useSelector(selectModulesLoaded)
  const modules = useSelector(selectModules)

  const teachingDays = facilitator?.teachingDays || []
  const teachingDayModules = extractWithModules(teachingDays, modules)

  const programs = facilitator?.programs || []
  const transactionsTableData = getTransactionTableData(facilitator?.transactions)

  const formValues = initialRateValues

  const getAllUsers = () => {
    dispatch(getFacilitators())
  }

  useEffect(() => {
    if (!modulesLoaded) dispatch(getModules())
  }, [modulesLoaded])

  useEffect(() => {
    getAllUsers()
  }, [])

  useEffect(() => {
    if (facilitator) {
      if (!facilitator?.programs) {
        dispatch(getFacilitatorsPrograms(facilitator))
      }
      if (facilitator?.programs && !facilitator?.transactions) {
        dispatch(getFacilitatorTransactions(facilitator))
      }
      if (facilitator?.transactions && !facilitator?.teachingDays) {
        dispatch(getFacilitatorTeachingDays(facilitator))
      }
    }
  }, [facilitator])

  const _onViewProgram = item => {
    history.push(`/dashboard/program-details/${item._id}`)
  }

  const _onEditRate = () => {
    setShowAddRate(!showAddRate)
  }

  const _onViewTeachingDays = () => {}

  const _onSubmitHandler = values => {
    facilitator['billingInfo'] = values
    dispatch(saveBilling(values))
  }

  const _onSelectVebProgram = item => {
    setSelectedVebProgram(item)
    dispatch(getFacilitatorTransactions(facilitator, item))
  }

  return (
    <TableContentWrapper
      title={`${entityName} - ${facilitator?.user_name} ${facilitator?.user_surname}`}
    >
      {/* <Link
        to="/dashboard/facilitators"
        className="mt-4 mb-4 text-green hover:underline focus:outline-none cursor-pointer"
      >
        <FontAwesomeIcon className="text-sm" icon={faArrowLeft} /> Back to all facilitators
      </Link> */}

      <div className="flex flex-col gap-4">
        <div className="flex gap-4">
          <div className="flex flex-1 flex-col gap-2">
            <TitleCardControl css={{ height: '150px' }}>
              <div className="flex flex-col gap-4 justify-between">
                <div className="flex flex-row gap-8">
                  <div
                    className="p-4 flex bg-indigo rounded-full justify-center"
                    style={{ height: '100px', width: '100px' }}
                  >
                    <FontAwesomeIcon
                      className="fa-4x text-white ml-8 mr-8 align-baseline"
                      icon={faUser}
                    />
                  </div>
                  <div className="flex flex-col justify-center gap-2">
                    <div className="font-extrabold text-lg font-sans">{`${facilitator?.user_name} ${facilitator?.user_surname}`}</div>
                    <div className="text-sm text-gray">{facilitator?.user_email}</div>
                  </div>
                </div>
              </div>
            </TitleCardControl>
            <TableComponent
              title="Programs"
              data={programs ?? []}
              displayKeys={programColumn}
              onRowClick={e => {
                _onViewProgram(e)
              }}
            />

            <TableComponent
              title="Teaching Days"
              data={teachingDayModules}
              displayKeys={teachingDaysColumn}
            />

            {/* <TableComponent
              title="Transaction History"
              data={
                transactionsTableData.map(item => {
                  console.log('item', item)
                  return {
                    ...item,
                    date: moment(item.date).format('LL'),
                    transaction_type: item.transaction_type,
                    payment_type: item.payment_type,
                    amount: `R${item.value.amount ?? 0}`,
                    _id: item._id,
                    user_id: item.user_id,
                    type: item.type,
                    recurring: item.recurring
                  }
                }) ?? []
              }
              displayKeys={[
                { value: 'date', date: true, label: 'Date' },
                { value: 'transaction_type', label: 'Transaction type' },
                { value: 'payment_type', label: 'Payment type' },
                { value: 'amount', label: 'Amount' }
              ]}
            >
              <div className="flex flex-row gap-2">
              </div>
            </TableComponent> */}
          </div>
        </div>
      </div>

      <AddPaymentComponent open={showAddPayment} setShow={setShowAddPayment} user={facilitator} />
      <AddTransactionComponent
        open={showAddTransaction}
        setShow={setShowAddTransaction}
        user={facilitator}
        programs={undefined}
      />
      <AddRateModal
        initialValue={facilitator?.billingInfo || formValues}
        open={showAddRate}
        setShow={setShowAddRate}
        onSuccess={_onSubmitHandler}
      />
      <AddDocumentComponent
        open={showAddDocument}
        setShow={setShowAddDocument}
        user={facilitator}
      />
    </TableContentWrapper>
  )
}

export default FacilitatorDetails
