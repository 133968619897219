import React, { useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Input
} from '@material-ui/core'
import { faArrowLeft, faArrowRight, faFilter } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TableContentWrapper from '../../../controls/tableContentWrapper/tableContentWrapper'

const ITEMS_PER_PAGE = 10
const defaultSearchValue = {
  show: false,
  search_by: ''
}
const tableColumns = [
  { key: 'name', display: 'Name' },
  { key: 'surname', display: 'Surname' },
  { key: 'january', display: 'January' },
  { key: 'february', display: 'February' },
  { key: 'march', display: 'March' },
  { key: 'april', display: 'April' },
  { key: 'may', display: 'May' },
  { key: 'june', display: 'June' },
  { key: 'july', display: 'July' },
  { key: 'august', display: 'August' },
  { key: 'september', display: 'September' },
  { key: 'octobter', display: 'October' },
  { key: 'november', display: 'November' },
  { key: 'december', display: 'December' },
  { key: 'total', display: 'Total' }
]

export const APTrackerTable = props => {
  const { title, data = [], search = defaultSearchValue, onFilterClick = null } = props

  console.log('data', data)

  const [currentPage, setCurrentPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')
  const [searchByValue, setSearchByValue] = useState({})
  let filteredData = data

  if (data.length > 0 && searchByValue != null) {
    filteredData = data?.filter(
      item =>
        searchByValue &&
        item[`${searchByValue?.key}`]?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  }

  const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE)

  const _onPageChange = page => {
    setCurrentPage(page)
  }

  useEffect(() => {
    if (search.show && tableColumns.length > 0) {
      const searchValue =
        search.search_by.length > 0
          ? tableColumns.find(e => e.key === search.search_by)
          : tableColumns[0]
      setSearchByValue(searchByValue)
    }
  }, [search, tableColumns])

  return (
    <TableContentWrapper>
      <div className="flex flex-col gap-4">
        <div className="flex justify-between">
          <div className="flex-auto text-xl font-bold">{title}</div>
        </div>
        <div className="flex gap-20">
          <div className="flex-1">
            {data.length > 0 ? (
              search.show ? (
                <div className="flex flex-col gap-2">
                  <Input
                    className="w-full"
                    placeholder={`Search by ${searchByValue?.display}`}
                    value={searchQuery}
                    onChange={event => {
                      setSearchQuery(event.target.value)
                    }}
                  />
                  <div className="text-gray text-xs leading-normal">
                    {`${
                      search.search_by < 1
                        ? ' To search by column, simply click on the column header.'
                        : ''
                    }`}
                  </div>
                  <br />
                </div>
              ) : (
                <div></div>
              )
            ) : null}
          </div>
        </div>

        <Table className="flex min-w-full">
          <TableHead className="bg-main text-lg">
            <TableRow>
              {tableColumns.map((title, i) => (
                <TableCell
                  key={i}
                  onClick={() => {
                    if (search.search_by.length < 1) {
                      if (!Array.isArray(filteredData[0][`${title.key}`])) {
                        setSearchByValue(title)
                      }
                    }
                  }}
                  className="text-gray-600 text-sm font-bold leading-normal"
                >
                  <div className="flex flex-row gap-2 items-center">
                    {title.display}
                    <div
                      onClick={() => {
                        onFilterClick(title)
                      }}
                    >
                      {title.filter && (
                        <FontAwesomeIcon
                          icon={faFilter}
                          className="w-5 h-5 text-black hover:text-green cursor-pointer"
                        />
                      )}
                    </div>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="text-gray-600 text-sm font-normal">
            {data.map((item, i) => (
              <TableRow key={i}>
                <TableCell>{item.user.name}</TableCell>
                <TableCell>{item.user.surname}</TableCell>
                {/* loop through the columns and then check if the column month is equal to the month in the item, then display the amount */}
                {/* skip the first two columns and the last column */}
                {/* add the indicator logic to the cell */}
                {tableColumns.slice(2, tableColumns.length - 1).map((column, i) => (
                  <TableCell key={i}>
                    {item.months.map(month => {
                      if (column.display === month.name) {
                        return (
                          <div
                            className={`flex items-center justify-center w-20 h-8 text-white rounded-full ${
                              month.indicator === 'RED'
                                ? 'bg-red'
                                : month.indicator === 'GREEN'
                                ? 'bg-green'
                                : 'bg-orange'
                            }`}
                          >
                            {column.display === month.name ? month.amount : 'N/A'}
                          </div>
                        )
                      }
                    })}
                  </TableCell>
                ))}

                <TableCell>{item.total}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {data && (
          <div className="flex justify-center mt-5">
            <button
              onClick={() => _onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`rounded-l-lg px-3 py-1 ${
                currentPage === 1
                  ? 'bg-gray-200 cursor-default'
                  : 'bg-main hover:bg-blue-700 cursor-pointer'
              }`}
            >
              <FontAwesomeIcon icon={faArrowLeft} className="w-5 h-5 text-black" />
            </button>
            <div className="flex items-center bg-gray-200 px-3 py-1">
              {currentPage} of {totalPages}
            </div>
            <button
              onClick={() => _onPageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className={` rounded-r-lg px-3 py-1 ${
                currentPage === totalPages
                  ? 'bg-gray-200 cursor-default'
                  : 'bg-main hover:bg-blue-700 cursor-pointer'
              }`}
            >
              <FontAwesomeIcon icon={faArrowRight} className="w-5 h-5 text-black" />
            </button>
          </div>
        )}
      </div>
    </TableContentWrapper>
  )
}
