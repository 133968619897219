import React from 'react'

import moment from 'moment'
import { useHistory } from 'react-router-dom'

import { DATE_FORMAT } from '../../../constants/constants'
import { MyTableControl } from '../../../controls/myTableControl/myTableControl'
import ButtonComponent from '../../customComponents/button'
import { useDispatch } from 'react-redux'
import { setCurriculumState } from '../../../actions'
import TableComponent from '../../UIComponents/components/tables/table'
import { faEye } from '@fortawesome/pro-light-svg-icons'
import ButtonComponentOutlined from '../../UIComponents/components/buttons/buttonOutlined'

const CurriculumTable = ({ curriculums, programId }) => {
  console.log('CurriculumTable', curriculums)
  const history = useHistory()
  const curriculumMutated = curriculums?.map(curriculum => ({
    ...curriculum,
    upload_date: moment(curriculum?.createdAt).format('LLL'),
    start_date: moment(curriculum?.start_date).format('LLL'),
    end_date: moment(curriculum?.end_date).format('LLL')
  }))

  const _onViewRow = item => {
    history.push(`/dashboard/upload-programme-curriculum/${programId}/${item._id}`)
  }
  const _onAddCurriculum = item => {
    history.push(`/dashboard/upload-programme-curriculum/${programId}`)
  }

  return (
    <div>
      <div className="bg-white shadow-md">
        <TableComponent
          search={true}
          title="Curriculum"
          displayKeys={[
            { value: 'name', label: 'Name' },
            { value: 'upload_date', label: 'Date Uploaded' },
            { value: 'start_date', label: 'Start Date' },
            { value: 'end_date', label: 'End Date' }
          ]}
          data={curriculumMutated ?? []}
          onRowClick={item => {
            history.push(`/dashboard/upload-programme-curriculum/${programId}`)
          }}
          actions={[
            {
              label: 'View',
              action: item => {
                console.log('Viewing item', item)
                history.push(`/dashboard/upload-programme-curriculum/${programId}/${item._id}`)
              },
              icon: faEye // Assuming you have an icon for view action
            }
          ]}
        >
          {/* Add any additional buttons or components here if needed */}
          <ButtonComponentOutlined
            title="Add Curriculum"
            onClick={() => history.push(`/dashboard/upload-programme-curriculum/${programId}`)}
            startIcon={undefined}
            endIcon={undefined}
            children={undefined}
          />
        </TableComponent>
      </div>
    </div>
  )
}

export default CurriculumTable
