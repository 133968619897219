import { on } from 'events'
import { add_user, update_user } from '../api/calls'
import { setNotification } from './notification'
import { getFacilitators } from './facilitators'

export const updateUser = user => {
  return dispatch => {
    update_user(user)
      .then(res => {
        if (res.data.success) {
          dispatch(
            setNotification({
              title: 'Success',
              messages: ['You have successfully updated the user.'],
              onConfirm: () => {}
            })
          )
          dispatch(getFacilitators())
        }
      })
      .catch(error => {})
  }
}

export const addUser = user => {
  return dispatch => {
    add_user(user)
      .then(res => {
        if (res.data.success) {
          dispatch(
            setNotification({
              title: 'Success',
              messages: ['You have successfully saved the user.'],
              onConfirm: () => {}
            })
          )
          dispatch(getFacilitators())
        }
      })
      .catch(error => {})
  }
}
