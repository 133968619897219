import React, { useEffect, useRef, useState } from 'react'
import { get_all_recruits } from '../../api/calls/recruitment/get_all_recruits'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import { MyTableControl } from '../../controls/myTableControl/myTableControl'
import { toTitleCase } from '../../utils'
import DropdownButtonComponent from '../customComponents/dropdownButton'
import { get_all_groups } from '../../api/calls'
import CSVDownloaderControl from '../../controls/csv_downloader'
import { faComment, faPen } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getFacilitators } from '../../asyncActions'
import {
  InductionTableHeader,
  tableColumns,
  InductionTableCell,
  InductionTableRow
} from '../induction/constants'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Paper from '@mui/material/Paper'
import ButtonComponent from '../customComponents/button'
import TableComponent from '../UIComponents/components/tables/table'
import SingleSelectComponent from '../UIComponents/components/select/singleSelect'

const displayKeys = [
  { key: 'name', display: 'Name' },
  { key: 'surname', display: 'Surname' },
  { key: 'phone_number', display: 'Phone Number' },
  { key: 'email', display: 'Email Address' },
  { key: 'group', display: 'Group' },
  { key: 'enrolled', display: 'Enrolled' },
  { key: 'open_day', display: 'Open Day', isObject: true, objectKeys: ['status', 'comment'] },
  { key: 'oi_workshop', display: 'OI Workshop', isObject: true, objectKeys: ['status', 'comment'] },
  { key: 'interview', display: 'Interview', isObject: true, objectKeys: ['status', 'comment'] },
  {
    key: 'tech_interview',
    display: 'Tech Interview',
    isObject: true,
    objectKeys: ['status', 'comment']
  },
  {
    key: 'green_light_survey',
    display: 'Green Light Survey',
    isObject: true,
    objectKeys: ['status', 'comment']
  },
  { key: 'shadow_week', display: 'Shadow Week', isObject: true, objectKeys: ['status', 'comment'] }
]

const dayTypes = [
  { key: 'open_day', display: 'Open Day' },
  { key: 'oi_workshop', display: 'OI Workshop' },
  { key: 'interview', display: 'Interview' },
  { key: 'tech_interview', display: 'Tech Interview' },
  { key: 'green_light_survey', display: 'Green Light Survey' },
  { key: 'shadow_week', display: 'Shadow Week' }
]

const RecruitmentHistory = () => {
  const [recruits, setRecruits] = useState([])
  const [filteredRecruits, setFilteredRecruits] = useState([])
  const [groups, setGroups] = useState([])
  const [selectedGroup, setSelectedGroup] = useState({})
  const [selectedRecruit, setSelectedRecruit] = useState()

  useEffect(() => {
    //get all recruits
    getRecruits()

    //get groups
    get_all_groups().then(e => {
      if (e.data.success) {
        setGroups(e.data.content)
      }
    })
  }, [])

  const getRecruits = async group_id => {
    await get_all_recruits(group_id).then(e => {
      if (e.data.success) {
        const content = e.data.content
        const recruits = content.map(recruit => {
          return {
            _id: recruit._id,
            name: toTitleCase(recruit.user_name),
            surname: toTitleCase(recruit.user_surname),
            email: recruit.user_email,
            phone_number: recruit.user_cell_number,
            enrolled: recruit.enrolled ? 'Yes' : 'No',
            group: recruit.group_name ?? '-',
            open_day: {
              status:
                toTitleCase(recruit.open_day.status) == 'No status'
                  ? '-'
                  : toTitleCase(recruit.open_day.status),
              comment: recruit.open_day.comment ?? '(No comment)'
            },
            oi_workshop: {
              status:
                toTitleCase(recruit.oi_workshop.status) == 'No status'
                  ? '-'
                  : toTitleCase(recruit.oi_workshop.status),
              comment: recruit.oi_workshop.comment ?? '(No comment)'
            },
            interview: {
              status:
                toTitleCase(recruit.interview.status) == 'No status'
                  ? '-'
                  : toTitleCase(recruit.interview.status),
              comment: recruit.interview.comment ?? '(No comment)'
            },
            tech_interview: {
              status:
                toTitleCase(recruit.tech_interview.status) == 'No status'
                  ? '-'
                  : toTitleCase(recruit.tech_interview.status),
              comment: recruit.tech_interview.comment ?? '(No comment)'
            },

            green_light_survey: {
              status:
                toTitleCase(recruit.green_light_survey.status) == 'No status'
                  ? '-'
                  : toTitleCase(recruit.green_light_survey.status),
              comment: recruit.green_light_survey.comment ?? '(No comment)'
            },

            shadow_week: {
              status:
                toTitleCase(recruit.shadow_week.status) == 'No status'
                  ? '-'
                  : toTitleCase(recruit.shadow_week.status),
              comment: recruit.shadow_week.comment ?? '(No comment)'
            }
          }
        })

        setRecruits(recruits)
        onSetFilteredRecruits(content)
      }
    })
  }

  const onSetFilteredRecruits = e => {
    const recruits = e.map(recruit => {
      return {
        _id: recruit._id,
        name: toTitleCase(recruit.user_name),
        surname: toTitleCase(recruit.user_surname),
        email: recruit.user_email,
        phone_number: recruit.user_cell_number,
        enrolled: recruit.enrolled ? 'Yes' : 'No',
        group: recruit.group_name ?? '-',
        open_day:
          toTitleCase(recruit.open_day.status) == 'No status'
            ? '-'
            : toTitleCase(recruit.open_day.status),
        open_day_comment: recruit.open_day.comment ?? '(No comment)',
        oi_workshop:
          toTitleCase(recruit.oi_workshop.status) == 'No status'
            ? '-'
            : toTitleCase(recruit.oi_workshop.status),
        oi_workshop_comment: recruit.oi_workshop.comment ?? '(No comment)',
        interview:
          toTitleCase(recruit.interview.status) == 'No status'
            ? '-'
            : toTitleCase(recruit.interview.status),
        interview_comment: recruit.interview.comment ?? '(No comment)',
        tech_interview:
          toTitleCase(recruit.tech_interview.status) == 'No status'
            ? '-'
            : toTitleCase(recruit.tech_interview.status),
        tech_interview_comment: recruit.tech_interview.comment ?? '(No comment)',
        green_light_survey:
          toTitleCase(recruit.green_light_survey.status) == 'No status'
            ? '-'
            : toTitleCase(recruit.green_light_survey.status),
        green_light_survey_comment: recruit.green_light_survey.comment ?? '(No comment)',
        shadow_week:
          toTitleCase(recruit.shadow_week.status) == 'No status'
            ? '-'
            : toTitleCase(recruit.shadow_week.status),
        shadow_week_comment: recruit.shadow_week.comment ?? '(No comment)'
      }
    })

    setFilteredRecruits(recruits)
  }

  return (
    <TableContentWrapper>
      <div className="flex flex-row gap-2">
        <div className="w-full">
          <TableComponent
            displayKeys={[
              { value: 'name', label: 'Name' },
              { value: 'surname', label: 'Surname' },
              { value: 'phone_number', label: 'Phone Number' },
              { value: 'email', label: 'Email Address' },
              { value: 'group', label: 'Group' }
            ]}
            data={recruits}
            title={'Recruitment History'}
            onRowClick={item => {
              console.log('item', item)
              setSelectedRecruit(item)
            }}
            search={true}
          >
            <div className="w-80">
              <SingleSelectComponent
                data={groups ?? []}
                displayKey={'name'}
                title={'Filter by Group'}
                errorMessage={'Groups Not Found'}
                onClick={item => {
                  setSelectedGroup(item)
                  getRecruits(item._id)
                }}
              />
            </div>

            <CSVDownloaderControl
              headers={[
                { key: 'name', label: 'Name' },
                { key: 'surname', label: 'Surname' },
                { key: 'phone_number', label: 'Phone Number' },
                { key: 'email', label: 'Email Address' },
                { key: 'group', label: 'Group' },
                { key: 'enrolled', label: 'Enrolled' },
                { key: 'open_day', label: 'Open Day' },
                { key: 'open_day_comment', label: 'Open Day Comment' },
                { key: 'oi_workshop', label: 'OI Workshop' },
                { key: 'oi_workshop_comment', label: 'OI Workshop Comment' },
                { key: 'interview', label: 'Interview' },
                { key: 'interview_comment', label: 'Interview Comment' },
                { key: 'tech_interview', label: 'Tech Interview' },
                { key: 'tech_interview_comment', label: 'Tech Interview Comment' },
                { key: 'green_light_survey', label: 'Green Light Survey' },
                { key: 'green_light_survey_comment', label: 'Green Light Survey Comment' },
                { key: 'shadow_week', label: 'Shadow Week' },
                { key: 'shadow_week_comment', label: 'Shadow Week Comment' }
              ]}
              data={filteredRecruits}
              title={`Recruits${
                selectedGroup.group_name ? `_${selectedGroup.group_name}` : '_all'
              }`}
            />
          </TableComponent>
        </div>
        {selectedRecruit && (
          <Paper sx={{ width: '30%', overflow: 'hidden' }}>
            <div className="p-8">
              <div>
                <div className="font-sans font-extrabold text-lg">{`${selectedRecruit?.name} ${selectedRecruit?.surname}`}</div>
                {dayTypes.map((day, index) => (
                  <div className="py-1">
                    <div
                      key={index}
                      className={`flex flex-col w-full border-2 ${
                        selectedRecruit?.[day.key]?.status == 'Yes'
                          ? 'border-green'
                          : selectedRecruit?.[day.key]?.status == 'No'
                          ? 'border-red'
                          : selectedRecruit?.[day.key]?.status == 'Maybe'
                          ? 'border-orange'
                          : 'border-gray'
                      } p-2 rounded-md`}
                    >
                      <div className="font-sans font-extrabold text-md">{day.display}</div>
                      <div className="font-sans font-extrabold text-sm">
                        {selectedRecruit?.[day.key]?.status}
                      </div>
                      <div className="flex gap-2 items-center">
                        <FontAwesomeIcon icon={faComment} />
                        <div className="font-sans font-bold text-sm">
                          {selectedRecruit?.[day.key]?.comment ?? '(No comment)'}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Paper>
        )}
      </div>
    </TableContentWrapper>
  )
}

export default RecruitmentHistory
