import React from 'react'
import CountrySelect from './components/autoselect/countrySelectAutocomplete'
import ButtonComponentContained from './components/buttons/buttonContained'
import ButtonComponentOutlined from './components/buttons/buttonOutlined'
import FileUploadButton from './components/buttons/fileUploadButton'
import CheckboxComponent from './components/checkbox/checkbox'
import RadioButtonComponent from './components/radio/radioButtton'
import MultipleSelectComponent from './components/select/multiSelect'
import SingleSelectComponent from './components/select/singleSelect'
import GroupDashboardTable from './components/tables/groupDashboardTable'

const UIComponents = () => {
  return (
    <div className="grid grid-cols-1 p-12 gap-4">
      <CountrySelect
        onChange={v => {
          console.log(v)
        }}
      />
      <ButtonComponentContained
        disabled={true}
        onClick={() => {
          console.log('ButtonComponentContained clicked')
        }}
      />
      <ButtonComponentOutlined
        onClick={() => {
          console.log('ButtonComponentOutlined clicked')
        }}
      />
      <FileUploadButton />
      <CheckboxComponent
        required={true}
        onClick={e => {
          console.log('CheckboxComponent clicked', e)
        }}
        onChange={e => {
          console.log('CheckboxComponent changed', e)
        }}
      />
      <RadioButtonComponent
        title={'Group Names'}
        buttons={[
          { value: 'Seedlings', label: 'Seedlings' },
          { value: 'Saplings', label: 'Saplings' }
        ]}
        onClick={e => {
          console.log('RadioButtonComponent clicked', e)
        }}
      />

      <SingleSelectComponent
        title={'Select Target'}
        errorMessage={'Targets Not Found'}
        onChange={v => {
          console.log(v)
        }}
        displayKey={'label'}
        data={[
          { _id: 'A1', value: 'Seedlings', label: 'Seedlings' },
          { _id: '2', value: 'Saplings', label: 'Saplings' },
          { _id: '3', value: 'Kellogs', label: 'Kellogs' }
        ]}
      />

      <MultipleSelectComponent
        data={[
          { _id: 'A1', value: 'Seedlings', label: 'Seedlings' },
          { _id: '2', value: 'Saplings', label: 'Saplings' },
          { _id: '3', value: 'Kellogs', label: 'Kellogs' }
        ]}
        onChipClick={e => {
          console.log('onChipClick', e)
        }}
        onMenuItemClick={e => {
          console.log('onMenuItemClick', e)
        }}
      />
      <GroupDashboardTable />
    </div>
  )
}

export default UIComponents
