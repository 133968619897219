export const facilitatorsColumn = [
  { key: 'user_name', display: 'Name' },
  { key: 'user_surname', display: 'Surname' },
  { key: 'user_cell_number', display: 'Phone Number' },
  { key: 'user_email', display: 'Email Address' },
  { key: 'status_code', display: 'Status' }
]

export const programColumn = [
  { value: 'program_name', label: 'Name' },
  { value: 'start_date', date: true, label: 'Start Date' },
  { value: 'end_date', date: true, label: 'End Date' }
]

export const attendanceColumn = [
  { key: 'program_name', display: 'Name' },
  { key: 'program_year', display: 'Year' },
  { key: 'program_quarters', display: 'Quarter (Defined by Date on Programme Creation)' },
  { key: 'human_oi', display: 'Avg Human OI' },
  //   { key: 'business_oi', display: 'Avg Business OI' },
  { key: 'technical_oi', display: 'Avg Technical OI' }
]

export const transactionsColumn = [
  { key: 'date', date: true, display: 'Date' },
  { key: 'transaction_type', display: 'Transaction type' },
  { key: 'payment_type', display: 'Payment type' },
  { key: 'amount', display: 'Amount' }
]

export const paymentColumn = [
  { key: 'amount', display: 'Amount' },
  { key: 'date', date: true, display: 'Payment date' },
  { key: 'document', link: 'View', display: 'Proof' }
]

export const documentsColumn = [
  { key: 'name', display: 'Name' },
  { key: 'description', display: 'Description' },
  { key: 'document', link: 'View', display: 'Document' }
]

export const teachingDaysColumn = [
  { values: 'module_name', label: 'Name' },
  { values: 'description', label: 'Description' },
  { values: 'createdAt', date: true, label: 'Date' }
]

/* ----------------------------------------------------- */

export const userValidationConfig = [
  { field: 'user_name', types: ['required'] },
  { field: 'user_surname', types: ['required'] },
  { field: 'user_email', types: ['required'] },
  { field: 'user_cell_number', types: ['required'] },
  { field: 'user_password', types: ['required'] },
  { field: 'type_code', types: ['required'] },
  { field: 'entity', types: ['required'] }
]

export const initialUserValues = {
  user_name: '',
  user_surname: '',
  user_email: '',
  user_cell_number: '',
  status_code: '',
  user_password: '',
  type_code: '',
  entity: {},
  group_id: '',
  event_id: '',
  address: {},
  training_provider: '',
  municipality: '',
  id_number: '',
  gender: '',
  race: '',
  disabled: false,
  disability: '',
  nationality: '',
  country: '',
  age: ''
}

export const initialRateValues = {
  rate: '',
  per: ''
}

export const rateValidationConfig = [
  { field: 'rate', types: ['required'] },
  { field: 'per', types: ['required'] },
  { field: 'user_id', types: ['required'] }
]

export const ratePerItems = [
  { name: 'Hour', id: 'Hour' },
  { name: 'Week', id: 'Week' },
  { name: 'Day', id: 'Day' }
]

export const transactionValidationConfig = [
  { field: 'user_id', types: ['required'] },
  { field: 'type', types: ['required'] },
  { field: 'transaction_type', types: ['required'] },
  { field: 'program_id', types: ['required'] },
  { field: 'value', types: ['required'] },
  { field: 'recurring_start', types: ['optional'] },
  { field: 'date', types: ['required'] }
]

export const initialTransactionValues = {
  user_id: '',
  type: '',
  transaction_type: '',
  program_id: '',
  recurring_start: '',
  value: {},
  date: ''
}

/* ----------------------------------------------------- */

export const actionButtonItems = [{ name: 'Resolve', id: 1 }]

export const transactionTypes = [
  { id: 'FINE', name: 'Fines' },
  { id: 'LOAN', name: 'Loans' },
  { id: 'STIPEND', name: 'Stipends' },
  { id: 'LOAN_REPAYMENT', name: 'Loan Repayments' },
  { id: 'WITHDRAWAL', name: 'Withdrawals' },
  { id: 'FINE_REPAYMENT', name: 'Fine Repayments' },
  { id: 'SEEDLINGS', name: 'Seedlings' },
  { id: 'YARN', name: 'Yarn' },
  { id: 'SQM', name: 'Square Meters' }
]

export const paymentTransactionTypes = [
  { id: 'CREDIT', name: 'Credit' },
  { id: 'DEBIT', name: 'Debit' }
]

export const paymentTypes = [
  { id: 'WITHDRAWABLE', name: 'Withdrawable' },
  // { id: 'NON-WITHDRAWABLE', name: 'Non Withdrawable' },
  { id: 'STANDARD', name: 'Standard' }
]

export const recurringTypes = [
  { id: 'Daily', name: 'Daily' },
  { id: 'Weekly', name: 'Weekly' },
  { id: 'Monthly', name: 'Monthly' },
  { id: 'No', name: 'No' }
]
