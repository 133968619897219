import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, styled } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { faUpload } from '@fortawesome/pro-light-svg-icons'

const FileUploadButton = ({ onChange = () => {}, required = false, title, selected }) => {
  const [selectedFile, setSelectedFile] = useState(null)

  useEffect(() => {
    if (selected) {
      setSelectedFile(selected)
    }
  }, [selected])

  return (
    <Button
      component="label"
      role={undefined}
      style={{ height: 56 }}
      variant="contained"
      tabIndex={-1}
      color="primary"
      startIcon={<FontAwesomeIcon icon={faUpload} />}
    >
      {required ? `${title ?? 'Upload File'} (Required)` : `${title ?? 'Upload File'}`}
      <VisuallyHiddenInput
        type="file"
        required={required}
        value={selectedFile ?? ''}
        onChange={e => {
          onChange(e.target.files[0])
        }}
        multiple
      />
    </Button>
  )
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 3,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
})

export default FileUploadButton
