export const actionButtonItems = [
  { name: 'View', id: 1 },
  { name: 'Remove', id: 2 }
  //   { name: 'Delete', id: 3 }
]
export const absenteesColumns = [
  { value: 'name', label: 'Name' },
  { value: 'surname', label: 'Surname' },
  { value: 'cell_number', label: 'Cell Number' },
  { value: 'group', label: 'Group' },
  { value: 'interaction', label: 'Interaction' },
  { value: 'date', date: true, label: 'Date' },
  { value: 'oi_type', label: 'OI Type' }
]

export const dateValues = ['Day', 'Month', 'Week', 'Year']
