export const content = [
  {
    name: 'ABC Gym',
    date: '29 August 2023',
    number_of_registers_taken: 13,
    download: {}
  },
  {
    name: 'ABC Gym',
    date: '29 August 2023',
    number_of_registers_taken: 13,
    download: {}
  }
]

export const validationData = [
  {
    name: 'program_name',
    required: true
  },
  {
    name: 'program_description',
    required: true
  },
  {
    name: 'facilitator_id',
    required: true
  },
  {
    name: 'facilitator_ids',
    required: true,
    isArray: true
  },
  {
    name: 'program_color',
    required: true
  },
  {
    name: 'start_date',
    required: true
  },
  {
    name: 'end_date',
    required: true
  }
]

export const moduleValidationData = [
  {
    name: 'module_name',
    required: true
  },
  {
    name: 'optimization_type',
    required: true
  },
  {
    name: 'start_date',
    required: true
  },
  {
    name: 'end_date',
    required: true
  }
]

export const colorPickRemoveBtn = {
  background: 'white',
  width: '30px',
  height: '30px',
  color: '#000',
  textAlign: 'center',
  borderRadius: '3px',
  position: 'relative',
  left: '-5',
  paddingTop: '3px',
  cursor: 'pointer'
}
