import { Paper } from '@material-ui/core'
import React from 'react'
import { useEffect, useState } from 'react'
import CountrySelect from '../UIComponents/components/autoselect/countrySelectAutocomplete'
import TextFieldComponent from '../UIComponents/components/textfield/textfiled'
import { addressValidationData, validateForm } from './form/components/constants'

const AddressInformation = ({ onChange = () => {}, formData = {}, onValidate = e => {} }) => {
  const [formValues, setFormValues] = useState(formData)

  const handleChange = e => {
    setFormValues({ ...formValues, ...e })
  }

  useEffect(() => {
    onChange(formValues)
    const valid = validateForm(addressValidationData, formValues?.address)
    onValidate(valid)
  }, [formValues])

  return (
    <div className="flex flex-col gap-4">
      <Paper className="p-4 flex flex-col gap-4">
        <div className="font-sans font-extrabold text-2xl">Address Information</div>
        <div className="grid grid-cols-2 gap-4">
          <TextFieldComponent
            label="Address Line 1"
            value={formValues?.address?.address_line_1}
            onChange={e => {
              handleChange({ address: { ...formValues?.address, address_line_1: e } })
            }}
            size="medium"
            required={true}
          />
          <TextFieldComponent
            label="Address Line 2"
            value={formValues?.address?.address_line_2}
            onChange={e => {
              handleChange({ address: { ...formValues?.address, address_line_2: e } })
            }}
            size="medium"
            required={true}
          />
          <TextFieldComponent
            label="City"
            value={formValues?.address?.city}
            onChange={e => {
              handleChange({ address: { ...formValues?.address, city: e } })
            }}
            size="medium"
            required={true}
          />

          <TextFieldComponent
            label="Province"
            value={formValues?.address?.province}
            onChange={e => {
              handleChange({ address: { ...formValues?.address, province: e } })
            }}
            size="medium"
            required={true}
          />
          <CountrySelect
            onChange={e => handleChange({ address: { ...formValues?.address, country: e } })}
          />

          <TextFieldComponent
            label="Postal Code"
            value={formValues?.address?.postal_code}
            onChange={e => {
              handleChange({ address: { ...formValues?.address, postal_code: e } })
            }}
            size="medium"
            required={true}
          />
        </div>
      </Paper>
    </div>
  )
}

export default AddressInformation
