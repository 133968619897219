export const initialGroupValues = {
  name: '',
  description: '',
  location: '',
  facilitator_ids: [],
  learner_ids: [],
  allocation_code: '',
  graduation_date: ''
}
export const groupValidationConfig = {}
export const groupColumns = [
  { value: 'name', label: 'Group Name' },
  { value: 'allocation_code', label: 'Allocation Code' },
  { value: 'facilitators', label: 'Facilitators', subKey: 'full_name' }
]
