import * as React from 'react'
import dayjs from 'dayjs'
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'

export default function DatePickerComponent({
  onChange = e => {},
  value,
  title,
  required,
  disablePast = false
}) {
  const [selectedValue, setSelectedValue] = React.useState(new Date())

  React.useEffect(() => {
    setSelectedValue(value)
  }, [value])
  return (
    <div onClick={e => e.stopPropagation()}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DesktopDatePicker']}>
          <DemoItem>
            <DesktopDatePicker
              disablePast={disablePast}
              className="w-full"
              // sx={{ padding: 0, width: '100%' }}
              defaultValue={dayjs(value)}
              label={`${title} ${required ? '*' : ''}`}
              onChange={e => {
                const date = e?.toDate()
                setSelectedValue(new Date(date))
                onChange(date)
              }}
            />
          </DemoItem>
        </DemoContainer>
      </LocalizationProvider>
    </div>
  )
}
