import React, { useEffect, useRef, useState } from 'react'

import { faPen, faTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const PopupControl = props => {
  const { title, close, isOpen, showEdit, showDelete, onEdit, onDelete } = props
  const { showClose, onClose } = close
  const [visible, setVisible] = useState(isOpen)
  const popupRef = useRef()

  useEffect(() => {
    setVisible(isOpen)
  }, [isOpen])


  useEffect(() => {
    const _handleClickOutside = event => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setVisible(false)
      }
    }

    document.addEventListener('mousedown', _handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', _handleClickOutside)
    }
  }, [])

  return visible ? (
    <div className="controls-popup fixed top-0 bottom-0 left-0 right-0 z-40 flex p-4 overflow-y-auto">
      {/* <div className="flex flex-col bg-popup rounded-lg shadow-lg m-auto"> */}
      <div className="flex flex-col bg-popup rounded-md shadow-lg m-auto">
        <div className="divide-y divide-white">
          <div className="flex justify-between w-full item-center">
            {title && <div className="text-md font-bold text-white p-4">{title.toUpperCase()}</div>}
           
                {showEdit || showEdit || showClose?
          <div className='flex flex-row gap-2 pr-4'>
          {showEdit && (
             <div
             className="cursor-pointer place-self-center"
             onClick={onEdit}
           >
               <div className="text-green text-l">Edit</div>
           </div>
           )}

           {showDelete && (
             <div
             className="cursor-pointer place-self-center"
             onClick={onDelete}
           >
               <div className="text-red-400 text-l">Delete</div>
           </div>
           )}
           {showClose && (
              <div
                className="cursor-pointer place-self-center"
                onClick={() => {
                  setVisible(false)
                  if (onClose) {
                    onClose(false)
                  }
                }}
              >
               <div className="text-white text-l">Close</div>
              </div>
            )}
          </div>: null } 
            
          
          </div>
          <div className="px-8 pb-8 pt-4">{props.children}</div>
        </div>
      </div>
    </div>
  ) : (
    <div className="hidden"></div>
  )
}

export default PopupControl