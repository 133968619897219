import { Paper } from '@material-ui/core'
import React, { useEffect } from 'react'

const TitleCardControl = props => {
  const {
    children,
    title,
    css = {},
  } = props
  //Did mount...
  useEffect(() => {}, [])

  //Will unmount...
  useEffect(() => {
    return () => {
      console.log('Cleaning up...')
    }
  }, [])

  return (
    <Paper style={{
      height: '100%',
      padding: '20px',
      width: '100%',
      ...css
    }}
     >
      {title && <div className="text-lg font-sans font-extrabold">{title}</div>}
      <div className="flex flex-col flex-auto">{children}</div>
    </Paper>
  )
}

export default TitleCardControl
